import { Link } from "react-router-dom";
import blueGreenPlus from "../../../images/greenPlus.png";

export default function AddMediaLink() {
  return (
    <>
      <Link  className="user-dashboard-add-media-link" to="/upload" style={{ textDecoration: "none", color: "black" }}>
        <img className="add-media-link-icon" src={blueGreenPlus} />
      </Link>
    </>
  );
}
