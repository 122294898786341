import errorIcon from "../../../images/errorIcon2.png";

export default function RenderError({ error, closeError }) {
  return (
    <>
      <div className="mobile-error-container">
        <div className="mobile-error-left-strip"></div>
        <img className="mobile-error-icon" src={errorIcon} />
        <div className="mobile-error-message">{error}</div>
        <div className="mobile-error-close-button-container">
          <div className="mobile-error-close-button" onClick={closeError}>
            Close
          </div>
        </div>
      </div>
    </>
  );
}
