import ModalTemplate from "../../Universal/ModalTemplate";

export default function ConfirmModal({
  setShowConfirmModal,
  processFiles,
  memberCode,
}) {
  const handleConfirmClick = () => {
    console.log("confirm clicked in modal");
    processFiles(true);
  };
  
  return (
    <ModalTemplate closeModal={() => setShowConfirmModal(false)} fixed={true}>
      <div className="overwrite-upload-code-modal-title">
        Upload to {memberCode}?
      </div>
      <div className="delete-upload-code-modal-caption">
        <span className="delete-upload-modal-code">{memberCode}</span> is
        already in use. Do you wish to overwrite the media saved to this code?
        This cannot be undone.
      </div>
      <div className="overwrite-upload-code-modal-footer">
        <button
          className="desktop-overwrite-upload-code-modal-button"
          onClick={() => setShowConfirmModal(false)}
          style={{ backgroundColor: "#999" }}
        >
          Cancel
        </button>
        <button
          onClick={handleConfirmClick}
          className="desktop-overwrite-upload-code-modal-button"
        >
          Continue
        </button>
      </div>
    </ModalTemplate>
  );
}
