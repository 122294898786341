import { useState } from "react";
import UserMessage from "./UserMessage";
import CompleteButton from "./CompleteButton";
import fontLogo from "../../../../images/fontLogo.png";
import copyClipboard from "../../../../images/copyClipboard.png";

export default function CodeDisplay({ code, handleCompleteClick }) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (text) => {
    const dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.value = `tkr.com/${text}`;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    setCopied(true);
  };

  return (
    <div
      className="desktop-upload-files-box-container"
      style={{ marginTop: "50px" }}
    >
      <div className="desktop-upload-code-caption">
        Your{" "}
        <img className="desktop-upload-font-logo" src={fontLogo} alt="tkr" />{" "}
        share code:
      </div>
      <div className="desktop-upload-code-display">{code}</div>
      <div className="desktop-upload-code-caption">Access your media at:</div>

      <div className="desktop-upload-display-access-container">
        <div className="desktop-upload-code-access-caption">
          tkr.com/
          <span className="desktop-upload-access-caption-code">{code}</span>
        </div>
        <img
          className="display-code-copy-image"
          src={copyClipboard}
          onClick={() => copyToClipboard(code)}
          alt="copy to clipboard"
        />
      </div>
      {copied && (
        <div className="desktop-upload-copied-message">
          Copied to clipboard!
        </div>
      )}
      <UserMessage code={code} />
      <CompleteButton resetCode={handleCompleteClick} />
    </div>
  );
}
