import { useContext } from "react";
import { UserContext } from "../../../Store";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";

export default function UserAccountHeader() {
  const [, setUser] = useContext(UserContext);

  const history = useHistory();

  const handleLogOut = async () => {
    const response = await axios.get("/api/users/logout");
    if (response.data === "logged out") {
      setUser();
      history.push("/users");
    }
  };

  return (
    <div className="user-account-header-top-container">
      <div className="user-dashboard-welcome-header">
        <div className="user-dashboard-name-heading">Account Details</div>
        <div className="user-dashboard-logout-button">
          <span onClick={handleLogOut}>Log out</span>
        </div>
      </div>
      <Link
        to="/users"
        style={{ textDecoration: "none", color: "#00157f" }}
      >
        <span>Go back</span>
      </Link>
    </div>
  );
}
