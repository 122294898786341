export default function ResetSuccess({ email, setSuccess }) {
  return (
    <div className="desktop-reset-password-page-container">
      <div className="reset-password-page-container">
        <div className="reset-password-page-heading">Check your email</div>
        <div className="reset-password-page-caption">
          If there is an account associated with the address '{email}' you'll
          get an email with instructions to create a new password.
        </div>
        <div className="reset-password-page-caption">
          If you don't see the email, please check your spam folder. If you
          still can't find it, double check your email address and{" "}
          <span
            className="reset-success-try-again-link"
            onClick={() => setSuccess(false)}
          >
            try again.
          </span>
        </div>
      </div>
    </div>
  );
}
