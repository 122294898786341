import React, { useState, useEffect, useContext } from "react";
import { RenderMediaContext } from "../../../Store";
import { useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../Navbar/Navbar";
import SingleMedia from "./SingleMedia";
import DoubleMedia from "./DoubleMedia";
import MultipleMedia from "./MultipleMedia";
import FullScreenMedia from "./FullScreenMedia";
import arraySort from "array-sort";
import AuthenticationOverlay from "./AuthenticationOverlay";

export default function RenderMediaPage() {
  const { id } = useParams();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [renderMedia, setRenderMedia] = useContext(RenderMediaContext);
  const [fullScreen, setFullScreen] = useState(false);
  const [index, setIndex] = useState(0);
  const [authenticated, setAuthenticated] = useState(false);
  const [password, setPassword] = useState();
  const [authError, setAuthError] = useState();

  useEffect(() => {
    if (renderMedia && renderMedia.code === id.toLowerCase()) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!renderMedia || renderMedia.code !== id) {
      setIsLoading(true);
      getMedia();
      setError();
    }
    if (renderMedia.auth) {
      setAuthenticated(true);
    }
  }, [id]);

  const getMedia = async () => {
    const lowerId = id.toLowerCase();
    try {
      const response = await axios.get(`/api/codes/?code=${lowerId}`);
      if (!response.data.success && response.data.msg === "no files found") {
        setError(
          <div>
            <div className="media-error-caption">
              Sorry, nothing found at{" "}
              <span className="media-error-code">{id}</span>
            </div>
            <div className="media-error-caption">
              Please check and try again
            </div>
          </div>
        );
        setIsLoading(false);
      } else if (response.data.success && !response.data.media.length) {
        setError(
          <div>
            <div className="media-error-caption">
              Sorry, <span className="media-error-code">{id}</span> has expired
            </div>
          </div>
        );
        setIsLoading(false);
      } else if (
        response.data.success &&
        response.data.msg === "password required"
      ) {
        const mediaArray = response.data.media;
        mediaArray.forEach((item) => {
          const removedExtension = item.key.split(".").slice(0, 1);
          item.itemIndex = Number(removedExtension[0].split("-").slice(1));
        });
        arraySort(mediaArray, "itemIndex");
        setRenderMedia({ code: id, auth: false, media: mediaArray });
        setIsLoading(false);
        setAuthenticated(false);
      } else {
        const mediaArray = response.data.media;
        mediaArray.forEach((item) => {
          const removedExtension = item.key.split(".").slice(0, 1);
          item.itemIndex = Number(removedExtension[0].split("-").slice(1));
        });
        arraySort(mediaArray, "itemIndex");
        setRenderMedia({ code: id, auth: true, media: mediaArray });
        setIsLoading(false);
        setAuthenticated(true);
      }
    } catch (err) {
      console.log(err.message);
      setError(
        <div>
          <div className="media-error-caption">
            Sorry, there was an error...
          </div>
          <div className="media-error-caption">Please refresh or try again</div>
        </div>
      );
      setIsLoading(false);
    }
  };

  const passwordAuthenticatedMedia = async () => {
    const lowerId = id.toLowerCase();
    if (!password) {
      setAuthError("Please enter a password");
    } else {
      setIsLoading(true);
      try {
        const response = await axios.post(`/api/codes/password-required`, {
          code: lowerId.trim(),
          password: password.trim(),
        });
        if (!response.data.success && response.data.msg === "no files found") {
          setError(
            <div>
              <div className="media-error-caption">
                Sorry, nothing found at{" "}
                <span className="media-error-code">{id}</span>
              </div>
              <div className="media-error-caption">
                Please check and try again
              </div>
            </div>
          );
          setIsLoading(false);
        } else if (
          !response.data.success &&
          response.data.msg === "server error"
        ) {
          setError(
            <div>
              <div className="media-error-caption">
                Sorry, there was an error...
              </div>
              <div className="media-error-caption">
                Please refresh or try again
              </div>
            </div>
          );
          setIsLoading(false);
        } else if (
          !response.data.success &&
          response.data.msg === "password incorrect"
        ) {
          setAuthError("Password incorrect");
          setIsLoading(false);
        } else {
          const mediaArray = response.data.media;
          mediaArray.forEach((item) => {
            const removedExtension = item.key.split(".").slice(0, 1);
            item.itemIndex = Number(removedExtension[0].split("-").slice(1));
          });
          arraySort(mediaArray, "itemIndex");
          setRenderMedia({ code: id, auth: false, media: mediaArray });
          setIsLoading(false);
          setAuthenticated(true);
        }
      } catch (err) {
        console.log(err.message);
        setError(
          <div>
            <div className="media-error-caption">
              Sorry, there was an error...
            </div>
            <div className="media-error-caption">
              Please refresh or try again
            </div>
          </div>
        );
        setIsLoading(false);
      }
    }
  };

  const handleImageClick = (itemIndex) => {
    setFullScreen(true);
    setIndex(itemIndex);
  };

  const nextSlide = () => {
    setIndex(index === renderMedia.media.length - 1 ? 0 : index + 1);
  };

  const prevSlide = () => {
    setIndex(index === 0 ? renderMedia.media.length - 1 : index - 1);
  };

  const getAuthenticationOverlay = () => {
    if (!authenticated) {
      return (
        <AuthenticationOverlay
          password={password}
          setPassword={setPassword}
          submit={passwordAuthenticatedMedia}
          error={authError}
        />
      );
    }
    return null;
  };

  if (isLoading) {
    return (
      <>
        <Navbar />
        <div className="mobile-render-media-loading-container">
          <div className="loading-component"></div>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Navbar />
        <div className="mobile-media-error-page-container">{error}</div>
      </>
    );
  }

  if (fullScreen) {
    return (
      <FullScreenMedia
        media={renderMedia.media}
        exitFullScreen={() => setFullScreen(false)}
        index={index}
        nextSlide={nextSlide}
        prevSlide={prevSlide}
        authenticated={authenticated}
      />
    );
  }

  if (renderMedia.media && renderMedia.media.length === 1) {
    return (
      <>
        <Navbar />
        {getAuthenticationOverlay()}
        <SingleMedia
          handleClick={handleImageClick}
          authenticated={authenticated}
        />
      </>
    );
  }

  if (renderMedia.media && renderMedia.media.length === 2) {
    return (
      <>
        <Navbar />
        {getAuthenticationOverlay()}
        <DoubleMedia
          media={renderMedia.media}
          handleClick={handleImageClick}
          authenticated={authenticated}
        />
      </>
    );
  }

  return (
    <>
      <Navbar />
      {getAuthenticationOverlay()}
      <MultipleMedia
        media={renderMedia.media}
        handleClick={handleImageClick}
        authenticated={authenticated}
      />
    </>
  );
}
