import { useState, useEffect, useContext } from "react";
import { RenderMediaContext } from "../../../../Store";
import FullScreenVideo from "./FullScreenVideo";
import BounceLoader from "react-spinners/BounceLoader";
import axios from "axios";

export default function FullScreenMedia({ index, autoPlay, nextSlide }) {
  const [renderMedia] = useContext(RenderMediaContext);
  const [timeOutIds] = useState([]);
  const [showMedia, setShowMedia] = useState(false);
  const [update, setUpdate] = useState(true);
  const [displayIndex, setDisplayIndex] = useState(index);

  const item = renderMedia.media[displayIndex];

  useEffect(() => {
    getItemUpdate();
  }, []);

  useEffect(() => {
    const updateInterval = setInterval(getItemUpdate, 3000);
    return () => clearInterval(updateInterval);
  });

  const getItemUpdate = async () => {
    if (!item.uploaded) {
      const response = await axios.get(
        `/api/codes/single-item/?code=${renderMedia.code}&fileName=${item.key}`
      );
      if (response.data.media.uploaded) {
        renderMedia.media[index] = response.data.media;
        setUpdate(!update);
      }
    }
  };

  useEffect(() => {
    setShowMedia(false);
    setTimeout(() => {
      setShowMedia(true);
      setDisplayIndex(index);
    }, [300]);
  }, [index]);

  useEffect(() => {
    if (!autoPlay && timeOutIds.length) {
      timeOutIds.forEach((timeOutId) => {
        clearTimeout(timeOutId);
      });
    }
  }, [autoPlay]);

  const handleImageLoad = () => {
    if (autoPlay && renderMedia.media[index].mimetype === "image") {
      const fsImageLoadTimeout = setTimeout(handleFsImageLoad, 5000);
      timeOutIds.push(fsImageLoadTimeout);
    }
  };

  const handleFsImageLoad = () => {
    nextSlide();
  };

  const DisplayMedia = () => {
    if (item.mimetype === "image") {
      return (
        <div>
          <img
            className="desktop-full-screen-image"
            src={item.url}
            alt=""
            onLoad={handleImageLoad}
          />
        </div>
      );
    } else if (item.mimetype === "video") {
      return (
        <FullScreenVideo
          item={item}
          autoPlay={autoPlay}
          nextSlide={nextSlide}
        />
      );
    }
  };

  if (!item.uploaded) {
    return (
      <div className="desktop-full-screen-loading-container">
        <div className="desktop-grid-view-loading-caption">
          This {item.mimetype === "image" ? "image" : "video "} is
          processing and will be available soon...
        </div>
        <BounceLoader color="#00deb7" loading size={30} />
      </div>
    );
  }

  return (
    <div className="full-screen-media-items-container">
      <div
        className={
          showMedia
            ? "full-screen-media-container"
            : "full-screen-media-container full-screen-media-container-hidden"
        }
      >
        <DisplayMedia />
      </div>
    </div>
  );
}
