import MediaThumbnail from "./MediaThumbnail";
import removeIcon from "../../../../images/removeIcon.png";

export default function FileEntry({ file, fileIndex, removeFile }) {
  const fileSize = (file.size / 1000000).toFixed(2);

  const handleRemoveFile = () => {
    removeFile(fileIndex);
  };

  return (
    <>
      <div className="file-entry-container">
        <MediaThumbnail file={file} key={file.name}/>
        <div className="file-entry-details">
          <div className="file-entry-name">{file.name}</div>
          <div className="file-entry-size">{fileSize} MB</div>
        </div>
        <img
          className="file-entry-remove-file-icon"
          src={removeIcon}
          alt="media-thumbnail"
          onClick={handleRemoveFile}
        />
      </div>
    </>
  );
}
