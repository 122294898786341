import generatingIcon from "../../../../images/blueSpannerThin.png";

export default function GeneratingCode() {
  return (
    <div className="desktop-upload-processing-container">
      <img
        className="desktop-upload-processing-icon"
        src={generatingIcon}
        alt="generating code"
      />
      <div className="desktop-upload-processing-caption">
        Generating code...
      </div>
    </div>
  );
}
