import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function CookiesAndDataPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="desktop-info-page-background">
      <div className="desktop-info-page-container">
      <div className="desktop-about-us-heading">Your Data</div>
        <div className="desktop-about-us-paragraph">
          At tkr we believe your data belongs to you and nobody else. We only
          store data to help our registered users login and keep track of their
          activity. If you do not have an account we do not collect any data at
          all. We do not use third party cookies.
        </div>
        <div className="desktop-about-us-heading">Cookies</div>
        <div className="desktop-about-us-paragraph">
          Cookies are small data files that allow a website or a mobile app to
          collect and store data on your desktop computer, laptop or mobile
          device. At tkr, we only use a single essential cookie (connect.sid) to
          help our registered users stay logged in. The lifecycle of the cookie
          is 24 hours or 30 days for persisted logins. You can find out more
          about what cookies are at:{" "}
          <a href="http://www.allaboutcookies.org/" target="_blank">
            allaboutcookies.org/
          </a>
          .
        </div>
        <div className="desktop-about-us-heading">Privacy Policy</div>
        <div className="desktop-about-us-paragraph">
          Want more detail? Find our privacy policy in full{" "}
          <Link to="/privacyPolicy">here</Link>.
        </div>
      </div>
    </div>
  );
}
