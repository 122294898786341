import { useEffect } from "react";
import UserAccountHeader from "./UserAccountHeader";
import UserAccountForm from "./UserAccountForm";

export default function UserAccount() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="user-area-container">
      <UserAccountHeader />
      <UserAccountForm />
    </div>
  );
}
