import { useContext } from "react";
import { Link } from "react-router-dom";
import { DesktopLoginModalContext } from "../../../Store";
import FooterCopyright from "./FooterCopyright";

export default function AppFooterNav() {
  const [, setShowLoginModal] = useContext(DesktopLoginModalContext);
  return (
    <>
      <div className="desktop-footer-nav-container">
        <div className="desktop-footer-nav-element">
          <Link
            to="/aboutUs"
            style={{ textDecoration: "none", color: "#f5f5f5" }}
          >
            about us
          </Link>
        </div>
        <div className="desktop-footer-nav-element">
          <Link
            to="/membership"
            style={{ textDecoration: "none", color: "#f5f5f5" }}
          >
            membership
          </Link>
        </div>
        <div
          className="desktop-footer-nav-element"
          style={{ color: "#f5f5f5", cursor: "pointer" }}
          onClick={() => setShowLoginModal(true)}
        >
          login/register
        </div>
        <div className="desktop-footer-nav-element">
          <Link
            to="/cookiesAndData"
            style={{ textDecoration: "none", color: "#f5f5f5" }}
          >
            cookies and data
          </Link>
        </div>
        <div className="desktop-footer-nav-element">
          <Link
            to="/termsOfUse"
            style={{ textDecoration: "none", color: "#f5f5f5" }}
          >
            terms of use
          </Link>
        </div>
        <div className="desktop-footer-nav-element">
          <Link
            to="/contact"
            style={{ textDecoration: "none", color: "#f5f5f5" }}
          >
            contact
          </Link>
        </div>
        <div className="desktop-footer-nav-element">
          <FooterCopyright />
        </div>
      </div>
    </>
  );
}
