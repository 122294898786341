import { useEffect, useContext } from "react";
import { RenderMediaContext } from "../../../../Store";
import GridViewImage from "./GridViewImage";
import GridViewVideo from "./GridViewVideo";

export default function GridView({
  setFullScreenIndex,
  setShowFullScreen,
  setAutoPlay,
  authenticated,
}) {
  const [renderMedia] = useContext(RenderMediaContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getColumns = () => {
    if (renderMedia.media.length <= 4) {
      return "desktop-grid-view-two-columns";
    } else if (renderMedia.media.length <= 11) {
      return "desktop-grid-view-three-columns";
    } else {
      return "desktop-grid-view-four-columns";
    }
  };

  const handleImageClick = (itemIndex) => {
    setFullScreenIndex(itemIndex);
    setShowFullScreen(true);
    setAutoPlay(false);
  };

  const getMedia = (item, itemIndex) => {
    if (authenticated) {
      if (item.mimetype === "image") {
        return (
          <GridViewImage
            key={itemIndex}
            handleImageClick={handleImageClick}
            index={itemIndex}
            authenticated={authenticated}
          />
        );
      } else if (item.mimetype === "video") {
        return (
          <GridViewVideo
            index={itemIndex}
            key={itemIndex}
            authenticated={authenticated}
          />
        );
      }
    } else {
      return (
        <GridViewImage
          key={itemIndex}
          handleImageClick={handleImageClick}
          index={itemIndex}
          authenticated={authenticated}
        />
      );
    }
  };

  if (renderMedia.media.length === 2) {
    return (
      <div className="desktop-render-media-grid-view-container">
        <div className="desktop-media-length-two-container">
          <div className="desktop-media-length-two-item">
            {getMedia(renderMedia.media[0], 0)}
          </div>
          <div className="desktop-media-length-two-divider"></div>
          <div className="desktop-media-length-two-item">
            {getMedia(renderMedia.media[1], 1)}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="desktop-render-media-grid-view-container">
      <div className={getColumns()}>
        {renderMedia.media.map((item, itemIndex) => getMedia(item, itemIndex))}
      </div>
    </div>
  );
}
