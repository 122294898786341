import React, { useState } from "react";
export const UserContext = React.createContext();
export const LoadingUserContext = React.createContext();
export const RenderMediaContext = React.createContext();
export const DesktopLoginModalContext = React.createContext();
export const DesktopDashboardContext = React.createContext();
export const DesktopFromRegisterContext = React.createContext();
export const DesktopRegisterCodeContext = React.createContext();

const Store = ({ children }) => {
  const [user, setUser] = useState();
  const [loadingUser, setLoadingUser] = useState(true);
  const [renderMedia, setRenderMedia] = useState({ code: null, media: [] });
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [fromRegister, setFromRegister] = useState(true);
  const [registerCode, setRegisterCode] = useState();

  return (
    <LoadingUserContext.Provider value={[loadingUser, setLoadingUser]}>
      <UserContext.Provider value={[user, setUser]}>
        <RenderMediaContext.Provider value={[renderMedia, setRenderMedia]}>
          <DesktopLoginModalContext.Provider value={[showLoginModal, setShowLoginModal]}>
            <DesktopDashboardContext.Provider value={[showDashboard, setShowDashboard]}>
              <DesktopFromRegisterContext.Provider value={[fromRegister, setFromRegister]}>
                <DesktopRegisterCodeContext.Provider value={[registerCode, setRegisterCode]}>
                  {children}
                </DesktopRegisterCodeContext.Provider>
              </DesktopFromRegisterContext.Provider>
            </DesktopDashboardContext.Provider>
          </DesktopLoginModalContext.Provider>
        </RenderMediaContext.Provider>
      </UserContext.Provider>
    </LoadingUserContext.Provider>
  );
};

export default Store;
