import { Modal, Box } from "@mui/material";
import logo from "../../../images/logo.png";

export default function UpgradeUserModal({ show, setShow, user, upgrade }) {
  const style = {
    position: "absolute",
    top: "35%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "260px",
    width: "90%",
    maxWidth: "600px",
    bgcolor: "background.paper",
    border: "1px solid lightgrey",
    borderRadius: "4px",
    boxShadow: 24,
    p: 2,
  };

  return (
    <>
      <Modal
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-header-container">
            <img
              className="overwrite-modal-header-logo"
              src={logo}
              alt="logo"
            />
          </div>
          <div className="overwrite-upload-code-modal-title">
            Upgrade {user.email}?
          </div>
          <div className="delete-upload-code-modal-caption">
            Do you wish to upgrade{" "}
            <span className="delete-upload-modal-code">{user.email}</span> to
            membership?
          </div>

          <div className="overwrite-upload-code-modal-footer">
            <button
              className="overwrite-upload-code-modal-button"
              onClick={() => setShow(false)}
              style={{ backgroundColor: "#999" }}
            >
              Cancel
            </button>
            <button
              onClick={upgrade}
              className="overwrite-upload-code-modal-button"
              style={{ backgroundColor: "blue" }}
            >
              Upgrade
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
