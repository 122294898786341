import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { UserContext } from "../../../../Store";
import Validate from "../../../../utils/validation";
import RenderError from "../../ErrorMessages/RenderError";
import { Link, useHistory } from "react-router-dom";

export default function LoginForm({ setLoading, error, setError }) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [persistLogin, setPersistLogin] = useState(false);
  const [, setUser] = useContext(UserContext);

  const history = useHistory();

  useEffect(() => {
    const enterListener = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        submitValidation();
      }
    };
    document.addEventListener("keyup", enterListener);
    return () => {
      document.removeEventListener("keyup", enterListener);
    };
  });

  const submitValidation = () => {
    if (!email) {
      setError("Please enter an email address");
    } else if (!Validate.loginEmail(email.trim())) {
      setError("Please enter a valid email address");
    } else if (!password) {
      setError("Please enter your password");
    } else {
      setLoading(true);
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post("/api/users/login", {
        email: email.trim(),
        password: password,
        persistLogin: persistLogin,
      });
      if (
        !response.data.success &&
        response.data.msg === "password incorrect"
      ) {
        setError(`Username or password not found. Please try again`);
        setLoading(false);
        setPassword();
      } else if (
        !response.data.success &&
        response.data.msg === "server error"
      ) {
        setError("Sorry, there was an error... Please try again");
        setLoading(false);
        setPassword();
      } else if (
        !response.data.success &&
        response.data.msg === "authentication error"
      ) {
        await axios.get("/api/users/logout");
        setError("Sorry, there was an error... Please try again");
        setLoading(false);
        setPassword();
      } else {
        setUser(response.data.user);
        setLoading(false);
        history.push("/users");
      }
    } catch (err) {
      console.log(err.message);
      setError("Sorry, there was an error... Please try again");
      setLoading(false);
      setPassword();
    }
  };

  const handleCloseError = () => {
    setError();
  };

  return (
    <>
      <div className="login-page-login-form">
        <div className="login-page-heading">Email address:</div>
        <input
          className="login-page-text-input"
          type="text"
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
        />
        <div className="login-page-heading">Password:</div>
        <input
          className="login-page-text-input login-page-password-input"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="login-page-forgotten-password">
          <Link to="/users/resetPassword" style={{ textDecoration: "none" }}>
            Forgotten your password?
          </Link>
        </div>
        <div className="login-page-checkbox-container">
          <input
            className="login-page-persist-checkbox"
            type="checkbox"
            id="persist-login"
            onChange={() => setPersistLogin(!persistLogin)}
          />
          <label htmlFor="persist-login">Keep me logged in</label>
        </div>

        {error && <RenderError error={error} closeError={handleCloseError} />}
        <button
          className="login-page-submit-button"
          onClick={submitValidation}
          type="submit"
          id="submitButton"
        >
          Sign in
        </button>
      </div>
    </>
  );
}
