import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../Store";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";

export default function AuthenticationOverlay({
  password,
  setPassword,
  submit,
  error,
}) {
  const [showPassword, setShowPassword] = useState();

  useEffect(() => {
    const enterListener = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        submit();
      }
    };
    document.addEventListener("keyup", enterListener);
    return () => {
      document.removeEventListener("keyup", enterListener);
    };
  });

  return (
    <>
      <div className="authentication-overlay-container">
        <div className="authentication-overlay-password-container">
          <div className="authentication-overlap-caption">
            Please enter the password
          </div>
          <div className="authentication-overlay-textfield-container">
            <TextField
              variant="outlined"
              fullWidth={true}
              label="Password"
              onChange={(e) => setPassword(e.target.value)}
              color="secondary"
              type={showPassword ? "text" : "password"}
              value={password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {showPassword ? (
                      <VisibilityOff
                        onClick={() => setShowPassword(false)}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <Visibility
                        onClick={() => setShowPassword(true)}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {error && <div className="authentication-overlay-error">{error}</div>}
          <button
            className="authentication-overlay-submit-button"
            onClick={submit}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}
