import { Modal, Box } from "@mui/material";
import logo from "../../../images/logo.png";

export default function RemoveMemberCode({
  show,
  setShow,
  codeIndex,
  user,
  remove,
}) {

  const style = {
    position: "absolute",
    top: "35%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "260px",
    width: "90%",
    maxWidth: "600px",
    bgcolor: "background.paper",
    border: "1px solid lightgrey",
    borderRadius: "4px",
    boxShadow: 24,
    p: 2,
  };

  return (
    <>
      <Modal
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-header-container">
            <img
              className="overwrite-modal-header-logo"
              src={logo}
              alt="logo"
            />
          </div>
          <div className="overwrite-upload-code-modal-title">
            Delete {user.memberCodes[codeIndex].code}?
          </div>
          <div className="delete-upload-code-modal-caption">
            This will remove{" "}
            <span className="delete-upload-modal-code">
              {user.memberCodes[codeIndex].code}
            </span>{" "}
            from {user.email}'s member codes. Any attached media will be
            deleted. Are you sure you wish to delete?
          </div>
          <div style={{ paddingTop: 20 }}>
            Code status: {user.memberCodes[codeIndex].status}
          </div>
          <div className="overwrite-upload-code-modal-footer">
            <button
              className="overwrite-upload-code-modal-button"
              onClick={() => setShow(false)}
              style={{ backgroundColor: "#999" }}
            >
              Cancel
            </button>
            <button
              onClick={remove}
              className="overwrite-upload-code-modal-button"
              style={{ backgroundColor: "red" }}
            >
              Delete
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
