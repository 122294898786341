import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import blueUploadArrowThin from "../../../../images/blueUploadArrowThin.png";

export default function FileDropzone({ setFiles }) {
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="desktop-files-upload-drop-zone">
          <img
            src={blueUploadArrowThin}
            className="desktop-files-green-plus"
            alt=""
          />
          <div className="desktop-files-input-caption">
            Drag and drop files zone
          </div>
        </div>
      </div>
    </>
  );
}
