import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../../../images/logo.png";
import { BsGrid3X3, BsPlay } from "react-icons/bs";
import { BiCarousel } from "react-icons/bi";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { UserContext } from "../../../Store";
import LoginModal from "../LoginModal/LoginModal";
import UserDashboard from "../UserDashboard/UserDashboard";
import betaLogo from "../../../images/mobileBeta2.png";

export default function MediaNavbar({
  setView,
  mediaLength,
  setShowFullScreen,
  setAutoPlay,
  setIndex,
  authenticated,
}) {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [fromRegister, setFromRegister] = useState(true);
  const [showDashboard, setShowDashboard] = useState(false);
  const [user] = useContext(UserContext);

  const handleFullScreenClick = () => {
    setShowFullScreen(true);
    setAutoPlay(true);
    setIndex(0);
  };

  const handleSignUpClick = () => {
    setShowLoginModal(true);
    setFromRegister(true);
  };

  const handleLogInClick = () => {
    setShowLoginModal(true);
    setFromRegister(false);
  };

  const handleDashboardClick = () => {
    setShowDashboard(!showDashboard);
  };

  const handleGridViewClick = () => {
    setView("grid");
  };

  const getNavbarButtons = () => {
    if (!user) {
      return (
        <>
          <button
            className="desktop-navbar-login-button"
            onClick={handleLogInClick}
          >
            Log in
          </button>
          <button
            className="desktop-navbar-signup-button"
            onClick={handleSignUpClick}
          >
            Sign up
          </button>
        </>
      );
    } else {
      return (
        <>
          <button
            className="desktop-navbar-dashboard-button"
            onClick={handleDashboardClick}
          >
            My Dashboard
          </button>
        </>
      );
    }
  };

  return (
    <>
      {user && showDashboard && (
        <UserDashboard setShowDashboard={setShowDashboard} />
      )}
      {showLoginModal && (
        <LoginModal
          setShowModal={setShowLoginModal}
          fromRegister={fromRegister}
        />
      )}
      <div className="desktop-navbar-container">
        <div className="desktop-navbar-logo-container">
          <Link to="/">
            <img className="desktop-navbar-logo" src={logo} alt="tkr logo" />
          </Link>
        </div>
        {mediaLength > 1 && (
          <div className="desktop-navbar-views-container">
            <Tooltip title="grid view">
              <IconButton onClick={handleGridViewClick}>
                <BsGrid3X3 className="desktop-navbar-view-icon" />
              </IconButton>
            </Tooltip>
            <Tooltip title="slides view">
              <IconButton onClick={() => setView("thumb")}>
                <BiCarousel className="desktop-navbar-view-icon" />
              </IconButton>
            </Tooltip>
            {authenticated && (
              <>
                <Tooltip title="play slideshow">
                  <IconButton onClick={handleFullScreenClick}>
                    <BsPlay className="desktop-navbar-view-icon" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
        )}
        <div className="desktop-navbar-button-container">
          <img
            className="desktop-navbar-beta-logo"
            src={betaLogo}
            alt="tkr logo"
          />
          {getNavbarButtons()}
        </div>
      </div>
    </>
  );
}
