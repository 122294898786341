import { useEffect, useState, useContext } from "react";
import { UserContext, LoadingUserContext } from "../../../Store";
import { Link, useHistory } from "react-router-dom";
import LoginForm from "./LoginForm/LoginForm";

export default function LoginPage() {
  const [user] = useContext(UserContext);
  const [loadingUser] = useContext(LoadingUserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!loadingUser && user) {
      history.push("/users");
    }
  });

  const LoginFormHeader = () => {
    return (
      <>
        <div className="login-page-subtitle">Sign in</div>
        <div className="login-page-info">
          Need an account?{" "}
          <Link to="/users/register" style={{ textDecoration: "none" }}>
            <span className="login-page-register-change">Sign up here</span>
          </Link>
        </div>
      </>
    );
  };

  if (loading) {
    return (
      <div className="new-password-page-loading-container">
        <div className="loading-component"></div>
      </div>
    );
  }

  return (
    <>
      <div className="user-area-top-container">
        <div className="user-area-container">
          <LoginFormHeader />
          <LoginForm
            setLoading={setLoading}
            error={error}
            setError={setError}
          />
        </div>
      </div>
    </>
  );
}
