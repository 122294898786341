const Validate = {};

Validate.passwordLength = (password) => {
  if (password && password.length >= 8 && password.length < 100) {
    return true;
  } else {
    return false;
  }
};

Validate.passwordUppercase = (password) => {
  if (password && checkUppercase(password)) {
    return true;
  } else {
    return false;
  }
  function checkUppercase(string) {
    const regex = new RegExp("^(?=.*[A-Z]).+$");
    if (regex.test(string)) {
      return true;
    }
  }
};

Validate.passwordNumber = (password) => {
  if (password && checkNumber(password)) {
    return true;
  } else {
    return false;
  }
  function checkNumber(string) {
    const regex = new RegExp("^(?=.*\\d).+$");
    if (regex.test(string)) {
      return true;
    }
  }
};

Validate.loginEmail = (email) => {
  if (!email) {
    return false;
  }
  var validEmailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (email.match(validEmailRegex)) {
    return true;
  } else {
    return false;
  }
};

Validate.mediaFileUpload = (files) => {
  let valid;
  const allowedExtensions =
    /(\.jpg|\.jpeg|\.png|\.gif|\.bmp|\.mpeg|\.mp4|\.webm|\.mpg|\.mpe|\.mpv|\.ogg|\.m4p|\.m4v|\.avi|\.wmv|\.jfif|\.mov)$/i;
  files.forEach((file) => {
    if (!allowedExtensions.exec(file.name)) {
      valid = "invalid file type";
    }
  });
  return valid;
};

Validate.uploadSize = (files) => {
  const sizeArray = files.map((file) => file.size);
  const bytesTotal = sizeArray.reduce((a, b) => a + b, 0);
  if (bytesTotal > 1073741824) {
    return false;
  }
  return true;
};

Validate.duplicateFiles = (files, newFiles) => {
  let returnValue = true;
  const fileNames = files.map((file) => file.name);
  newFiles.forEach((file) => {
    if (fileNames.includes(file.name)) {
      returnValue = false;
    }
  });
  return returnValue;
};

module.exports = Validate;
