import { useState, useEffect, useContext } from "react";
import { RenderMediaContext } from "../../../../Store";
import axios from "axios";
import { BoxLoading } from "react-loadingg";

export default function MainCarouselVideo({ item, authenticated }) {
  const [itemCopy, setItemCopy] = useState(item);
  const [renderMedia] = useContext(RenderMediaContext);
  const [videoLoading, setVideoLoading] = useState(true);

  useEffect(() => {
    const video = document.getElementById(`video${itemCopy.url}`);
    if (video) {
      video.onloadeddata = (event) => {
        setVideoLoading(false);
      };
    }
  });

  useEffect(() => {
    const updateInterval = setInterval(async () => {
      if (!itemCopy.uploaded) {
        const response = await axios.get(
          `/api/codes/single-item/?code=${renderMedia.code}&fileName=${item.key}&authenticated=${authenticated}`
        );
        if (response.data.media.uploaded) {
          setItemCopy(response.data.media);
        }
      }
    }, 5000);
    return () => clearInterval(updateInterval);
  });

  const getVideoClassName = () => {
    if (!videoLoading) {
      return "desktop-single-media-video";
    }
    return "desktop-single-media-video desktop-single-media-hidden";
  };

  if (!itemCopy.uploaded) {
    return (
      <div className="main-media-carousel-video-loading-container">
        <BoxLoading color="#00deb7" />
        <div className="main-media-carousel-video-loading-caption">
        This video is processing and will be available soon...
        </div>
      </div>
    );
  }

  return (
    <>
      {videoLoading && (
        <div className="main-media-carousel-video-loading-container">
          <BoxLoading color="#00deb7" />
          <div className="main-media-carousel-video-loading-caption">
            Video loading...
          </div>
        </div>
      )}
      <video
        controls
        className={getVideoClassName()}
        src={item.url}
        id={`video${itemCopy.url}`}
        poster={item.posterUrl}
      >
        <source src={item.url} type="video/mp4" />
        <source src={item.url} type="video/ogg" />
        <source src={item.url} type="video/webm" />
        <source src={item.url} type="video/mov" />
        Your browser does not support the video tag.
      </video>
    </>
  );
}
