import logo from "../../../images/logo.png";
import removeIcon from "../../../images/removeIcon.png";
import { Link, useHistory } from "react-router-dom";
import mobileBeta from "../../../images/mobileBeta2.png";

export default function LoginNavbar() {
  const history = useHistory();

  const handleCloseClick = () => {
    history.push("/");
  };

  return (
    <>
      <div className="navbar-container">
        <div style={{ width: "42px" }}>
          <img className="navbar-beta-logo" src={mobileBeta} alt="tkr logo" />
        </div>
        <Link to="/">
          <img className="navbar-logo" src={logo} alt="tkr-login" />
        </Link>
        <img
          className="navbar-login-cancel-button"
          src={removeIcon}
          alt="back"
          onClick={handleCloseClick}
        />
      </div>
    </>
  );
}
