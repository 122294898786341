export default function AddAndClearButtons(props) {
  const { addMoreFiles, clearAllFiles } = props;
  return (
    <>
      <div className="add-clear-buttons-container">
        <label
          htmlFor="add-more-files"
          className="files-display-edit-button add-more-files-button"
        >
          Add More Files
        </label>
        <input
          id="add-more-files"
          onChange={addMoreFiles}
          type="file"
          accept="image/*, video/*"
          multiple
        />
        <label
          className="files-display-edit-button clear-all-button"
          onClick={clearAllFiles}
        >
          Clear All
        </label>
      </div>
    </>
  );
}
