import { useState, useEffect, useContext } from "react";
import { RenderMediaContext } from "../../../../Store";
import axios from "axios";
import { BoxLoading } from "react-loadingg";

export default function MainCarouselVideo({ index, authenticated }) {
  const [showBlack, setShowBlack] = useState(true);
  const [update, setUpdate] = useState(true);
  const [renderMedia] = useContext(RenderMediaContext);

  const item = renderMedia.media[index];

  useEffect(() => {
    setTimeout(() => {
      setShowBlack(false);
    }, 300);
  }, [item]);

  useEffect(() => {
    getItemUpdate();
    const updateInterval = setInterval(getItemUpdate, 5000);
    return () => clearInterval(updateInterval);
  });

  const getItemUpdate = async () => {
    if (!item.uploaded) {
      const response = await axios.get(
        `/api/codes/single-item/?code=${renderMedia.code}&fileName=${item.key}&authenticated=${authenticated}`
      );
      if (response.data.media.uploaded) {
        renderMedia.media[index] = response.data.media;
        setUpdate(!update);
      }
    }
  }; 

  const getVideoClassName = () => {
    if (!showBlack) {
      return "main-media-carousel-video";
    }
    return "main-media-carousel-video main-media-carousel-hidden";
  };

  if (!item.uploaded) {
    return (
      <div className="main-media-carousel-video-loading-container">
        <BoxLoading color="#00deb7" />
        <div className="main-media-carousel-video-loading-caption">
          This video is processing and will be available soon...
        </div>
      </div>
    );
  }

  return (
    <>
      <video
        controls
        className={getVideoClassName()}
        src={item.url}
        id={`carouselVideo${item.url}`}
        poster={item.posterUrl}
      >
        <source src={item.url} type="video/mp4" />
        <source src={item.url} type="video/ogg" />
        <source src={item.url} type="video/webm" />
        <source src={item.url} type="video/mov" />
        Your browser does not support the video tag.
      </video>
    </>
  );
}
