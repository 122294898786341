import { useState, useEffect, useContext } from "react";
import { RenderMediaContext } from "../../../../Store";
import BounceLoader from "react-spinners/BounceLoader";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

export default function GridViewVideo({ index, authenticated }) {
  const [videoLoading, setVideoLoading] = useState(true);
  const [renderMedia] = useContext(RenderMediaContext);
  const [update, setUpdate] = useState(true);

  const item = renderMedia.media[index];

  useEffect(() => {
    const video = document.getElementById(`gridVideo${item.url}`);
    if (video) {
      video.addEventListener("loadeddata", handleVideoLoad);
    }
    return () => {
      if (video) {
        video.removeEventListener("loadeddata", handleVideoLoad);
      }
    };
  });

  const handleVideoLoad = () => {
    setVideoLoading(false);
  };

  useEffect(() => {
    getItemUpdate();
    const updateInterval = setInterval(getItemUpdate, 5000);
    return () => clearInterval(updateInterval);
  });

  const getItemUpdate = async () => {
    if (!item.uploaded) {
      const response = await axios.get(
        `/api/codes/single-item/?code=${renderMedia.code}&fileName=${item.key}&authenticated=${authenticated}`
      );
      if (response.data.media.uploaded) {
        renderMedia.media[index] = response.data.media;
        setUpdate(!update);
      }
    }
  };

  const getVideoClassName = () => {
    if (!videoLoading) {
      return "desktop-grid-view-video";
    }
    return "desktop-grid-view-video main-media-carousel-hidden";
  };

  if (!item.uploaded) {
    return (
      <div className="desktop-grid-image-loading-container">
        <div className="desktop-grid-view-loading-caption">
          This video is processing and will be available soon...
        </div>
        <BounceLoader color="#00deb7" loading size={30} />
      </div>
    );
  }

  return (
    <>
      {videoLoading && (
        <div className="desktop-grid-image-loading-container">
          <div className="desktop-grid-view-loading-caption">
            Video loading...
          </div>
          <ClipLoader color="#00deb7" loading size={30} />
        </div>
      )}
      <video
        controls
        className={getVideoClassName()}
        src={item.url}
        id={`gridVideo${item.url}`}
        poster={item.posterUrl}
      >
        <source src={item.url} type="video/mp4" />
        <source src={item.url} type="video/ogg" />
        <source src={item.url} type="video/webm" />
        <source src={item.url} type="video/mov" />
        Your browser does not support the video tag.
      </video>
    </>
  );
}
