export default function WWDComponent({ img, caption, last }) {
  return (
    <>
      <div className="wwd-component-container">
        <img className="wwd-component-icon" src={img} alt="what we do icon" />
        <div className="wwd-component-caption">
          {caption}
          {last && (
            <>
              <span className="wwd-component-last">*your-code*</span>
              <div className="wwd-component-replace-code">(Use your own code!)</div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
