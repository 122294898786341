import { useContext } from "react";
import {
  LoadingUserContext,
  UserContext,
  DesktopLoginModalContext,
  DesktopDashboardContext,
  DesktopFromRegisterContext,
} from "../../../Store";

export default function NavbarButtons() {
  const [loadingUser] = useContext(LoadingUserContext);
  const [user] = useContext(UserContext);
  const [, setShowLoginModal] = useContext(DesktopLoginModalContext);
  const [showDashboard, setShowDashboard] = useContext(DesktopDashboardContext);
  const [, setFromRegister] = useContext(DesktopFromRegisterContext);

  const handleSignUpClick = () => {
    setShowLoginModal(true);
    setFromRegister(true);
  };

  const handleLogInClick = () => {
    setShowLoginModal(true);
    setFromRegister(false);
  };

  const handleDashboardClick = () => {
    setShowDashboard(!showDashboard);
  };

  if (loadingUser) {
    return <div className="small-loading-component"></div>;
  }

  if (!user) {
    return (
      <>
        <button
          className="desktop-navbar-login-button"
          onClick={handleLogInClick}
        >
          Log in
        </button>
        <button
          className="desktop-navbar-signup-button"
          onClick={handleSignUpClick}
        >
          Sign up
        </button>
      </>
    );
  }

  return (
    <>
      <button
        className="desktop-navbar-dashboard-button"
        onClick={handleDashboardClick}
      >
        My Dashboard
      </button>
    </>
  );
}
