import generatingIcon from "../../../../images/blueSpannerThin.png";

export default function GeneratingCode() {
  return (
    <>
      <img
          className="files-uploading-icon"
          src={generatingIcon}
          alt="packaging-files"
        />
        <div className="files-uploading-caption">Generating code...</div>
    </>
  );
}
