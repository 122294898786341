import React, { useState, useEffect, useContext } from "react";
import { RenderMediaContext, DesktopDashboardContext } from "../../../Store";
import { useParams } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import axios from "axios";
import SingleMedia from "./SingleView/SingleMedia";
import MediaError from "./MediaError";
import arraySort from "array-sort";
import AppFooter from "../AppFooter/AppFooter";
import MediaNavbar from "../Navbar/MediaNavbar";
import Navbar from "../Navbar/Navbar";
import ThumbnailView from "./ThumbView/ThumbnailView";
import GridView from "./GridView/GridView";
import FullScreenView from "./FullScreenView/FullScreenView";
import AuthenticationOverlay from "./AuthenticationOverlay";

export default function RenderMediaPage() {
  const { id } = useParams();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState("thumb");
  const [fullScreenIndex, setFullScreenIndex] = useState(0);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [autoPlay, setAutoPlay] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [password, setPassword] = useState();
  const [authError, setAuthError] = useState();
  const [renderMedia, setRenderMedia] = useContext(RenderMediaContext);
  const [, setShowDashboard] = useContext(DesktopDashboardContext);
  const transition = useTransition(showFullScreen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  useEffect(() => {
    setShowDashboard(false);
    if (renderMedia && renderMedia.code === id.toLowerCase()) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!renderMedia || renderMedia.code !== id) {
      setIsLoading(true);
      setPassword();
      setAuthError();
      getMedia();
      setError();
    }
    if (renderMedia.auth) {
      setAuthenticated(true);
    }
  }, [id]);

  const getMedia = async () => {
    const lowerId = id.toLowerCase();
    try {
      const response = await axios.get(`/api/codes/?code=${lowerId}`);
      if (!response.data.success && response.data.msg === "no files found") {
        setError(
          <div>
            <div className="media-error-caption">
              Sorry, nothing found at{" "}
              <span className="media-error-code">{id}</span>
            </div>
            <div className="media-error-caption">
              Please check and try again
            </div>
          </div>
        );
        setIsLoading(false);
      } else if (response.data.success && !response.data.media.length) {
        setError(
          <div>
            <div className="media-error-caption">
              Sorry, {" "} 
              <span className="media-error-code">{id}</span> has expired
            </div>
          </div>
        );
        setIsLoading(false);
      } else if (
        response.data.success &&
        response.data.msg === "password required"
      ) {
        const mediaArray = response.data.media;
        mediaArray.forEach((item) => {
          const removedExtension = item.key.split(".").slice(0, 1);
          item.itemIndex = Number(removedExtension[0].split("-").slice(1));
        });
        arraySort(mediaArray, "itemIndex");
        setRenderMedia({ code: id, auth: false, media: mediaArray });
        setAuthenticated(false);
        setIsLoading(false);
      } else {
        const mediaArray = response.data.media;
        mediaArray.forEach((item) => {
          const removedExtension = item.key.split(".").slice(0, 1);
          item.itemIndex = Number(removedExtension[0].split("-").slice(1));
        });
        arraySort(mediaArray, "itemIndex");
        setRenderMedia({ code: id, auth: true, media: mediaArray });
        setIsLoading(false);
        setAuthenticated(true);
      }
    } catch (err) {
      console.log(err.message);
      setError(
        <div>
          <div className="media-error-caption">
            Sorry, there was an error...
          </div>
          <div className="media-error-caption">Please refresh or try again</div>
        </div>
      );
      setIsLoading(false);
    }
  };

  const passwordAuthenticatedMedia = async () => {
    const lowerId = id.toLowerCase();
    if (!password) {
      setAuthError("Please enter a password");
    } else {
      setIsLoading(true);
      try {
        const response = await axios.post(`/api/codes/password-required`, {
          code: lowerId.trim(),
          password: password.trim(),
        });
        if (!response.data.success && response.data.msg === "no files found") {
          setError(
            <div>
              <div className="media-error-caption">
                Sorry, nothing found at{" "}
                <span className="media-error-code">{id}</span>
              </div>
              <div className="media-error-caption">
                Please check and try again
              </div>
            </div>
          );
          setIsLoading(false);
        } else if (
          !response.data.success &&
          response.data.msg === "server error"
        ) {
          setError(
            <div>
              <div className="media-error-caption">
                Sorry, there was an error...
              </div>
              <div className="media-error-caption">
                Please refresh or try again
              </div>
            </div>
          );
          setIsLoading(false);
        } else if (
          !response.data.success &&
          response.data.msg === "password incorrect"
        ) {
          setAuthError("Password incorrect");
          setIsLoading(false);
        } else {
          const mediaArray = response.data.media;
          mediaArray.forEach((item) => {
            const removedExtension = item.key.split(".").slice(0, 1);
            item.itemIndex = Number(removedExtension[0].split("-").slice(1));
          });
          arraySort(mediaArray, "itemIndex");
          setRenderMedia({ code: id, auth: false, media: mediaArray });
          setIsLoading(false);
          setAuthenticated(true);
        }
      } catch (err) {
        console.log(err.message);
        setError(
          <div>
            <div className="media-error-caption">
              Sorry, there was an error...
            </div>
            <div className="media-error-caption">
              Please refresh or try again
            </div>
          </div>
        );
        setIsLoading(false);
      }
    }
  };

  const fsNextSlide = () => {
    setFullScreenIndex(
      fullScreenIndex === renderMedia.media.length - 1 ? 0 : fullScreenIndex + 1
    );
  };

  const fsPrevSlide = () => {
    setFullScreenIndex(
      fullScreenIndex === 0 ? renderMedia.media.length - 1 : fullScreenIndex - 1
    );
  };

  const getAuthenticationOverlay = () => {
    if (!authenticated) {
      return (
        <AuthenticationOverlay
          password={password}
          setPassword={setPassword}
          submit={passwordAuthenticatedMedia}
          error={authError}
        />
      );
    }
    return null;
  };

  if (isLoading) {
    return (
      <>
        <MediaNavbar setView={setView} mediaLength={renderMedia.media.length} />
        <div className="render-media-loading-container">
          <div className="new-password-page-loading-container">
            <div className="loading-component"></div>
          </div>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Navbar />
        <MediaError error={error} />
      </>
    );
  }

  if (renderMedia.media && renderMedia.media.length === 1) {
    return (
      <>
        {getAuthenticationOverlay()}
        <SingleMedia
          media={renderMedia.media[0]}
          authenticated={authenticated}
        />
      </>
    );
  }

  return (
    <div className="desktop-render-media-page-top-container">
      {!showFullScreen && (
        <MediaNavbar
          setView={setView}
          mediaLength={renderMedia.media.length}
          setShowFullScreen={setShowFullScreen}
          setAutoPlay={setAutoPlay}
          setIndex={setFullScreenIndex}
          authenticated={authenticated}
        />
      )}
      {view === "thumb" && (
        <>
          {getAuthenticationOverlay()}
          <div className="desktop-render-media-page-thumb-view-container">
            <ThumbnailView
              setFullScreenIndex={setFullScreenIndex}
              setShowFullScreen={setShowFullScreen}
              setAutoPlay={setAutoPlay}
              authenticated={authenticated}
            />
          </div>
        </>
      )}
      {view === "grid" && (
        <>
          {getAuthenticationOverlay()}
          <GridView
            setFullScreenIndex={setFullScreenIndex}
            setShowFullScreen={setShowFullScreen}
            setAutoPlay={setAutoPlay}
            authenticated={authenticated}
          />
        </>
      )}
      {!showFullScreen && <AppFooter />}
      {transition((style, item) =>
        item ? (
          <animated.div style={style}>
            <FullScreenView
              setShow={setShowFullScreen}
              autoPlay={autoPlay}
              setAutoPlay={setAutoPlay}
              index={fullScreenIndex}
              nextSlide={fsNextSlide}
              prevSlide={fsPrevSlide}
            />
          </animated.div>
        ) : (
          ""
        )
      )}
    </div>
  );
}
