import { useState, useContext } from "react";
import logo from "../../../images/logo.png";
import { MdClose } from "react-icons/md";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import { DesktopRegisterCodeContext } from "../../../Store";

export default function LoginModal({ setShowModal, fromRegister }) {
  const [register, setRegister] = useState(fromRegister);
  const [, setRegisterCode] = useContext(DesktopRegisterCodeContext);

  const handleModalClose = () => {
    setShowModal(false);
    setRegisterCode(false);
  }

  return (
    <>
      <div className="desktop-login-modal-container">
        <div className="desktop-login-modal-box">
          <div className="desktop-login-modal-logo-container">
            <div className="desktop-login-modal-logo-placeholder"></div>
            <img className="desktop-login-modal-logo" src={logo} alt="" />
            <MdClose
              className="desktop-login-close-icon"
              onClick={handleModalClose}
            />
          </div>
          <div className="desktop-login-modal-content-container">
            {register && <RegisterForm setRegister={setRegister} />}
            {!register && (
              <LoginForm
                setRegister={setRegister}
                setShowModal={setShowModal}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
