import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../Store";
import imageCompression from "browser-image-compression";
import AddFilesButton from "./AddFilesButton/AddFilesButton";
import Validate from "../../../utils/validation";
import FilesDisplay from "./FilesDisplay/FilesDisplay";
import UploadOptions from "./UploadOptions/UploadOptions";
import axios from "axios";
import FilesUploading from "./FilesUploading/FilesUploading";
import RenderError from "../ErrorMessages/RenderError";
import MemberCodesUpload from "./MemberCodesUpload/MemberCodesUpload";
import { useParams, useHistory } from "react-router-dom";
import arraySort from "array-sort";

export default function UploadPage() {
  const [user, setUser] = useContext(UserContext);
  const [files, setFiles] = useState([]);
  const [code, setCode] = useState();
  const [error, setError] = useState();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingStage, setLoadingStage] = useState();
  const [memberCode, setMemberCode] = useState();
  const [showMovError, setShowMovError] = useState(false);

  const { userAreaCode } = useParams();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    getUserData();
  }, []);

  useEffect(() => {
    if (code) {
      history.push(`/upload/displayCode/${code}`);
    }
  }, [code, history]);

  useEffect(() => {
    if (userAreaCode && user) {
      checkCodeParamIsUsers();
    }
  }, [user]);

  useEffect(() => {
    if (files.length > 0) {
      let movError = false;
      files.forEach((file) => {
        const fileExtension = file.name.split(".").pop();
        if (fileExtension === "mov") {
          movError = true;
        }
      });
      if (movError) {
        setShowMovError(true);
      } else {
        setShowMovError(false);
      }
    }
  }, [files]);

  const checkCodeParamIsUsers = () => {
    const memberCodesCodes = user.memberCodes.map((code) => code.code);
    if (memberCodesCodes.includes(userAreaCode)) {
      setMemberCode(userAreaCode);
    }
  };

  const getUserData = async () => {
    try {
      const response = await axios.get(`/api/users/get-user`);
      if (response) {
        setUser(response.data.user);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const submit = async (expiryDate, accessPassword) => {
    files.forEach((file, fileIndex) => {
      file.fileIndex = fileIndex;
    });
    const validFilesArray = Validate.mediaFileUpload(files);
    if (validFilesArray === "invalid file type") {
      setError(<div>Sorry... Only image and video files can be uploaded.</div>);
    } else {
      const sizeArray = files.map((file) => file.size);
      const bytesTotal = sizeArray.reduce((a, b) => a + b, 0);
      if (bytesTotal > 1000000000) {
        setError(
          <div>A little too much... Free up some space (1 GB limit)</div>
        );
      } else {
        setError();
        setLoadingStage("compressing");
        setIsLoading(true);
        try {
          const imageFiles = files.filter((file) => {
            const mimetype = file.type.split("/");
            if (mimetype[0] === "image") {
              return true;
            } else {
              return false;
            }
          });
          const compressedFiles = await compressFiles(imageFiles);
          const otherFiles = files.filter((file) => {
            const mimetype = file.type.split("/");
            if (mimetype[0] !== "image") {
              return true;
            } else {
              return false;
            }
          });
          const concatFiles = compressedFiles.concat(otherFiles);
          concatFiles.forEach((file) => {
            const originalFile = files.find(
              (fileObj) => fileObj.name === file.name
            );
            file.fileIndex = originalFile.fileIndex;
          });
          arraySort(concatFiles, "fileIndex");
          await postFiles(concatFiles, expiryDate, accessPassword);
        } catch (err) {
          console.log(err.message);
        }
      }
    }
  };

  const compressFiles = async (filesArray) => {
    const compressedFiles = [];
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 2048,
      useWebWorker: true,
      // optional progress function if required see docs
    };
    try {
      await Promise.all(
        filesArray.map(async (file) => {
          const compressedFile = await imageCompression(file, options);
          compressedFiles.push(compressedFile);
        })
      );
    } catch (err) {
      console.log(err.message);
    }
    return compressedFiles;
  };

  const postFiles = async (filesArray, expiryDate, accessPassword) => {
    setLoadingStage("posting");
    const fileNames = filesArray.map((file) => file.name);
    const formData = new FormData();
    filesArray.forEach((file) => {
      formData.append(file.name, file);
    });
    formData.append("fileNames", JSON.stringify(fileNames));
    if (expiryDate) {
      formData.append("expires", expiryDate);
    }
    if (memberCode) {
      formData.append("memberCode", memberCode.toLowerCase());
    }
    if (accessPassword) {
      formData.append("password", accessPassword);
    }
    try {
      const response = await axios.post("/api/codes", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: function (progressEvent) {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          setUploadPercentage(percent);
          if (percent === 100) {
            setLoadingStage("generating");
          }
        },
      });
      setCode(response.data.code);
      setFiles([]);
      setIsLoading(false);
      setLoadingStage("complete");
    } catch (err) {
      console.log(err.message);
    }
  };

  const selectFiles = (event) => {
    const filesArray = Object.values(event.target.files);
    const validFilesArray = Validate.mediaFileUpload(filesArray);
    if (validFilesArray === "invalid file type") {
      setError(<div>Sorry... Only image and video files can be uploaded.</div>);
    } else {
      setFiles(filesArray);
      const sizeArray = filesArray.map((file) => file.size);
      const bytesTotal = sizeArray.reduce((a, b) => a + b, 0);
      if (bytesTotal > 1000000000) {
        setError(
          <div>A little too much... Free up some space (1 GB limit)</div>
        );
      } else {
        setError();
      }
    }
  };

  const addMoreFiles = (event) => {
    const filesArray = Object.values(event.target.files);
    const combinedFiles = files.concat(filesArray);
    const validFilesArray = Validate.mediaFileUpload(filesArray);
    const validFilesSize = Validate.uploadSize(combinedFiles);
    const validDuplicates = Validate.duplicateFiles(files, filesArray);
    if (!validDuplicates) {
      setError(
        <div>
          Sorry... Files cannot have same name. The files have not been added.
        </div>
      );
    } else if (validFilesArray === "invalid file type") {
      setError(<div>Sorry... Only image and video files can be uploaded.</div>);
    } else if (!validFilesSize) {
      setError(<div>A little too much... Free up some space (1 GB limit)</div>);
      setFiles(combinedFiles);
    } else {
      setFiles(combinedFiles);
      setError();
    }
  };

  const clearAllFiles = () => {
    setFiles([]);
    setError();
  };

  const removeFile = (fileIndex) => {
    const newFiles = [...files];
    newFiles.splice(fileIndex, 1);
    setFiles(newFiles);
    const sizeArray = newFiles.map((file) => file.size);
    const bytesTotal = sizeArray.reduce((a, b) => a + b, 0);
    if (bytesTotal <= 1000000000) {
      setError();
    }
  };

  const closeError = () => {
    setError();
  };

  return (
    <>
      {isLoading && (
        <FilesUploading
          stage={loadingStage}
          uploadPercentage={uploadPercentage}
        />
      )}
      {!code && !isLoading && (
        <>
          <div className="upload-page-container">
            {!files.length && (
              <>
                <AddFilesButton selectFiles={selectFiles} />
                {error && (
                  <div className="upload-page-error-container">
                    <RenderError error={error} closeError={closeError} />
                  </div>
                )}
              </>
            )}
            {files.length > 0 && (
              <>
                {error && (
                  <>
                    <div className="upload-page-list-error-container">
                      <RenderError error={error} closeError={closeError} />
                    </div>
                  </>
                )}
                <FilesDisplay
                  files={files}
                  clearAllFiles={clearAllFiles}
                  addMoreFiles={addMoreFiles}
                  removeFile={removeFile}
                />
                {showMovError && (
                  <div className="mobile-upload-page-mov-error">
                    .mov files occasionally do not format correctly. Consider
                    converting to mp4 before uploading.
                  </div>
                )}
                {user && user.account !== "free" && (
                  <MemberCodesUpload
                    memberCode={memberCode}
                    setMemberCode={setMemberCode}
                  />
                )}
                {/* <PasswordBox /> */}
                <UploadOptions
                  submit={submit}
                  error={error}
                  closeError={closeError}
                  memberCode={memberCode}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}
