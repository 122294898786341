import { Link } from "react-router-dom";

export default function AppFooterNav() {
  return (
    <>
      <div className="footer-nav-container">
        <div className="footer-nav-element">
          <Link
            to="/aboutUs"
            style={{ textDecoration: "none", color: "#f5f5f5" }}
          >
            about us
          </Link>
        </div>
        <div className="footer-nav-element">
          <Link
            to="/membership"
            style={{ textDecoration: "none", color: "#f5f5f5" }}
          >
            membership
          </Link>
        </div>
        <div className="footer-nav-element">
          <Link
            to="/users"
            style={{ textDecoration: "none", color: "#f5f5f5" }}
          >
            login/register
          </Link>
        </div>
        <div className="footer-nav-element">
          <Link
            to="/cookiesAndData"
            style={{ textDecoration: "none", color: "#f5f5f5" }}
          >
            cookies and data
          </Link>
        </div>
        <div className="footer-nav-element">
          <Link
            to="/termsOfUse"
            style={{ textDecoration: "none", color: "#f5f5f5" }}
          >
            terms of use
          </Link>
        </div>
        <div className="footer-nav-element">
          <Link
            to="/contact"
            style={{ textDecoration: "none", color: "#f5f5f5" }}
          >
            contact
          </Link>
        </div>
      </div>
    </>
  );
}
