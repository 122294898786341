import { useState, useContext } from "react";
import logo from "../../../images/logo.png";
import axios from "axios";
import { UserContext, RenderMediaContext } from "../../../Store";
import { MdClose } from "react-icons/md";

export default function DeleteCodeModal({ code, setShowModal }) {
  const [, setUser] = useContext(UserContext);
  const [, setRenderMedia] = useContext(RenderMediaContext);
  const [loading, setLoading] = useState(false);

  const deleteCode = async () => {
    setLoading(true);
    try {
      await axios.delete(`/api/codes/${code._id}`);
      const response = await axios.get(`/api/users/get-user`);
      if (response) {
        setUser(response.data.user);
      }
    } catch (err) {
      console.log(err.message);
    }
    setShowModal(false);
    setLoading(false);
    setRenderMedia({ code: null, media: [] });
  };

  return (
    <div className="delete-code-modal-container">
      <div className="delete-code-modal-box">
        <div className="desktop-login-modal-logo-container">
          <div className="desktop-login-modal-logo-placeholder"></div>
          <img className="desktop-login-modal-logo" src={logo} alt="" />
          <MdClose
            className="desktop-login-close-icon"
            onClick={() => setShowModal(false)}
          />
        </div>
        {loading && (
          <div className="dashboard-delete-code-loading-container">
            <div className="loading-component"></div>
            <div className="desktop-login-modal-loading-caption">
              Deleting {code.code}...
            </div>
          </div>
        )}
        {!loading && (
          <>
            <div className="dashboard-delete-code-modal-title">
              Delete {code.code}?
            </div>
            <div className="dashboard-delete-code-modal-caption">
              This will remove all media from code{" "}
              <span className="delete-upload-modal-code">{code.code}</span>. Are
              you sure you wish to delete?
            </div>
            <div className="desktop-delete-code-modal-footer">
              <button
                className="desktop-delete-code-modal-button"
                onClick={() => setShowModal(false)}
                style={{ backgroundColor: "#999" }}
              >
                Cancel
              </button>
              <button
                onClick={deleteCode}
                className="desktop-delete-code-modal-button"
                style={{ backgroundColor: "red" }}
              >
                Delete
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
