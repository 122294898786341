import greenTick from "../../../../images/greenTick1.png";
import redCross from "../../../../images/redCross1.png";

export default function UploadDetails({ files }) {
  const sizeArray = files.map((file) => file.size);
  const bytesTotal = sizeArray.reduce((a, b) => a + b, 0);
  const totalMegabytes = (bytesTotal / 1000000).toFixed(1);
  return (
    <>
      <div className="upload-details-container">
        <img
          className="upload-details-icon"
          src={bytesTotal < 1000000000 ? greenTick : redCross}
          alt="validation icon"
        />
        <div className="upload-media-details-label">
          {totalMegabytes} MB used in {files.length} files
        </div>
      </div>
    </>
  );
}
