import { useEffect } from "react";

export default function PrivacyPolicyPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="desktop-info-page-background">
      <div className="desktop-info-page-container">
        <div className="desktop-about-us-heading">Privacy Policy</div>
        <div className="desktop-about-us-paragraph">
          1. Introduction <br />
          <br />
          1.1 We are committed to safeguarding the privacy of our website
          visitors, service users, individual customers and customer personnel.
          <br />
          1.2 This policy applies where we are acting as a data controller with
          respect to the personal data of such persons; in other words, where we
          determine the purposes and means of the processing of that personal
          data. <br />
          1.3 In this policy, "we", "us" and "our" refer to tkr.com. <br />
          <br />
          2. Credit <br />
          <br />
          2.1 This document was created using a template from Docular
          (https://seqlegal.com/free-legal-documents/privacy-policy). <br />
          <br />
          3. The personal data that we collect <br />
          <br />
          3.1 In this Section 3 we have set out the general categories of
          personal data that we process. <br />
          3.2 We may process data enabling us to get in touch with you ("contact
          data"). The contact data may include your name, email address. The
          source of the contact data is you. <br />
          3.3 We may process ("account data"). The account data may include your
          account identifier, name, email address, tkr.com activity, account
          creation and modification dates and account type. The primary source
          of the account data is you, although some elements of the account data
          may be generated by our website. <br />
          3.4 We may process information contained in or relating to any
          communication that you send to us or that we send to you
          ("communication data"). The communication data may include the
          communication content and metadata associated with the communication.
          Our website will generate the metadata associated with communications
          made using the website contact forms. <br />
          <br />
          4. Purposes of processing and legal bases <br />
          <br />
          4.1 In this Section 4, we have set out the purposes for which we may
          process personal data and the legal bases of the processing. <br />
          4.2 Operations - We may process your personal data for the purposes of
          operating our website, the processing and fulfilment of providing our
          services. The legal basis for this processing is our legitimate
          interests, namely the proper administration of our website, services
          and business. <br />
          4.3 Publications - We may process account data for the purposes of
          publishing such data on our website and elsewhere through our services
          in accordance with your express instructions. The legal basis for this
          processing is our legitimate interests, namely the publication of
          content in the ordinary course of our operations.
          <br />
          4.4 Relationships and communications - We may process contact data,
          account data, transaction data and/or communication data for the
          purposes of managing our relationships, communicating with you
          (excluding communicating for the purposes of direct marketing) by
          email, providing support services and complaint handling. The legal
          basis for this processing is our legitimate interests, namely
          communications with our website visitors, service users, individual
          customers and customer personnel, the maintenance of relationships,
          and the proper administration of our website, services and business.
          <br />
          4.5 Research and analysis - We may process usage data for the purposes
          of researching and analysing the use of our website and services, as
          well as researching and analysing other interactions with our
          business. The legal basis for this processing is our legitimate
          interests, namely monitoring, supporting, improving and securing our
          website, services and business generally. <br />
          4.6 Record keeping - We may process your personal data for the
          purposes of creating and maintaining our databases, back-up copies of
          our databases and our business records generally. The legal basis for
          this processing is our legitimate interests, namely ensuring that we
          have access to all the information we need to properly and efficiently
          run our business in accordance with this policy. <br />
          4.7 Security - We may process your personal data for the purposes of
          security and the prevention of fraud and other criminal activity. The
          legal basis of this processing is our legitimate interests, namely the
          protection of our website, services and business, and the protection
          of others. <br />
          4.8 Insurance and risk management - We may process your personal data
          where necessary for the purposes of obtaining or maintaining insurance
          coverage, managing risks and/or obtaining professional advice. The
          legal basis for this processing is our legitimate interests, namely
          the proper protection of our business against risks. <br />
          4.9 Legal claims - We may process your personal data where necessary
          for the establishment, exercise or defence of legal claims, whether in
          court proceedings or in an administrative or out-of-court procedure.
          The legal basis for this processing is our legitimate interests,
          namely the protection and assertion of our legal rights, your legal
          rights and the legal rights of others. <br />
          4.10 Legal compliance and vital interests - We may also process your
          personal data where such processing is necessary for compliance with a
          legal obligation to which we are subject or in order to protect your
          vital interests or the vital interests of another natural person.{" "}
          <br />
          <br />
          5. Providing your personal data to others <br />
          <br />
          5.1 We may disclose your personal data to our insurers and/or
          professional advisers insofar as reasonably necessary for the purposes
          of obtaining or maintaining insurance coverage, managing risks,
          obtaining professional advice. <br />
          5.2 Your personal data held in our website database will be stored on
          the servers of our hosting services providers identified at
          www.heroku.com. <br />
          5.3 In addition to the specific disclosures of personal data set out
          in this Section 5, we may disclose your personal data where such
          disclosure is necessary for compliance with a legal obligation to
          which we are subject, or in order to protect your vital interests or
          the vital interests of another natural person. We may also disclose
          your personal data where such disclosure is necessary for the
          establishment, exercise, or defence of legal claims, whether in court
          proceedings or in an administrative or out-of-court procedure. <br />
          <br />
          6. International transfers of your personal data <br />
          <br />
          6.1 In this Section 6, we provide information about the circumstances
          in which your personal data may be transferred to a third country
          under UK and/or EU data protection law. <br />
          6.2 We may transfer your personal data from the European Economic Area
          (EEA) to the UK and process that personal data in the UK for the
          purposes set out in this policy, during any period with respect to
          which the UK is not treated as a third country under EU data
          protection law or benefits from an adequacy decision under EU data
          protection law; and we may transfer your personal data from the UK to
          the EEA and process that personal data in the EEA for the purposes set
          out in this policy, during any period with respect to which EEA states
          are not treated as third countries under UK data protection law or
          benefit from adequacy regulations under UK data protection law. <br />
          6.3 The hosting facilities for our website are situated in the UK and
          the USA. The competent data protection authorities have made an
          adequacy determination with respect to the data protection laws of
          each of these countries. Transfers to each of these countries will be
          protected by appropriate safeguards, namely the use of standard data
          protection clauses adopted or approved by the competent data
          protection authorities. <br />
          6.4 You acknowledge that personal data that you submit for publication
          through our website or services may be available, via the internet,
          around the world. We cannot prevent the use (or misuse) of such
          personal data by others. <br />
          <br />
          7. Retaining and deleting personal data <br />
          <br />
          7.1 This Section 7 sets out our data retention policies and
          procedures, which are designed to help ensure that we comply with our
          legal obligations in relation to the retention and deletion of
          personal data. <br />
          7.2 Personal data that we process for any purpose or purposes shall
          not be kept for longer than is necessary for that purpose or those
          purposes. <br />
          7.3 We will retain your personal data as follows: <br />
          (a) contact data will be retained for a minimum period of 0 following
          the date of the most recent contact between you and us, and for a
          maximum period of 99 years following that date; <br />
          (b) account data will be not be retained following the date of closure
          of the relevant account; <br />
          (c) usage data will be retained for 6 months following the date of
          collection; 7.4 Notwithstanding the other provisions of this Section
          7, we may retain your personal data where such retention is necessary
          for compliance with a legal obligation to which we are subject, or in
          order to protect your vital interests or the vital interests of
          another natural person. <br />
          <br />
          8. Your rights <br />
          <br />
          8.1 In this Section 8, we have listed the rights that you have under
          data protection law. <br />
          8.2 Your principal rights under data protection law are: <br />
          (a) the right to access - you can ask for copies of your personal
          data; <br />
          (b) the right to rectification - you can ask us to rectify inaccurate
          personal data and to complete incomplete personal data; <br />
          (c) the right to erasure - you can ask us to erase your personal data;{" "}
          <br />
          (d) the right to restrict processing - you can ask us to restrict the
          processing of your personal data; <br />
          (e) the right to object to processing - you can object to the
          processing of your personal data; <br />
          (f) the right to data portability - you can ask that we transfer your
          personal data to another organisation or to you; <br />
          (g) the right to complain to a supervisory authority - you can
          complain about our processing of your personal data; and <br />
          (h) the right to withdraw consent - to the extent that the legal basis
          of our processing of your personal data is consent, you can withdraw
          that consent. 8.3 These rights are subject to certain limitations and
          exceptions. You can learn more about the rights of data subjects by
          visiting https://edpb.europa.eu/ and https://ico.org.uk/.
          <br />
          8.4 You may exercise any of your rights in relation to your personal
          data by written notice to us, using the email enquiries@tkr.com.{" "}
          <br />
          <br />
          9. About cookies <br />
          <br />
          9.1 A cookie is a file containing an identifier (a string of letters
          and numbers) that is sent by a web server to a web browser and is
          stored by the browser. The identifier is then sent back to the server
          each time the browser requests a page from the server.
          <br />
          9.2 Cookies may be either "persistent" cookies or "session" cookies: a
          persistent cookie will be stored by a web browser and will remain
          valid until its set expiry date, unless deleted by the user before the
          expiry date; a session cookie, on the other hand, will expire at the
          end of the user session, when the web browser is closed. <br />
          9.3 Cookies may not contain any information that personally identifies
          a user, but personal data that we store about you may be linked to the
          information stored in and obtained from cookies. <br />
          <br />
          10. Cookies that we use <br />
          10.1 We use cookies for the following purposes: (a) authentication and
          status - we use a cookie [to identify you when you visit our website
          and as you navigate our website, and to help us determine if you are
          logged into our website (cookies used for this purpose are:
          connect.sid); <br />
          <br />
          11. Managing cookies <br />
          <br />
          11.1 Most browsers allow you to refuse to accept cookies and to delete
          cookies. The methods for doing so vary from browser to browser, and
          from version to version. You can however obtain up-to-date information
          about blocking and deleting cookies via these links: <br />
          (a) https://support.google.com/chrome (Chrome); <br />
          (b) https://support.mozilla.org/ (Firefox); <br />
          (c) https://help.opera.com/ (Opera); <br />
          (d) https://support.microsoft.com/ (Internet Explorer); <br />
          (e) https://support.apple.com/ (Safari); and <br />
          (f) https://support.microsoft.com/ (Edge). <br />
          11.2 Blocking all cookies will have a negative impact upon the
          usability of many websites. <br />
          11.3 If you block cookies, you will not be able to use all the
          features on our website. <br />
          <br />
          12. Amendments <br />
          <br />
          12.1 We may update this policy from time to time by publishing a new
          version on our website. <br />
          12.2 You should check this page occasionally to ensure you are happy
          with any changes to this policy. <br />
          <br />
          13. Our details <br />
          <br />
          13.1 This website is owned and operated by tkr.com. <br />
          13.2 We are registered in [England and Wales] under registration
          number [number], and our registered office is at [address]. <br />
          13.3 Our principal place of business is at [address]. <br />
          13.4 You can contact us: <br />
          (a) [by post, to [the postal address given above]]; <br />
          (b) [using our website contact form]; <br />
          (c) [by telephone, on [the contact number published on our website]];
          or <br />
          (d) [by email, using [the email address published on our website]].
          [additional list items] <br />
          <br />
          14. Representatives <br />
          <br />
          14.1 Our representative within the EU with respect to our obligations
          under data protection law is Gerard Egan and you can contact our
          representative by email gerard@tkr.com. <br />
          14.2 Our representative within the UK with respect to our obligations
          under data protection law is Gerard Egan and you can contact our
          representative by gerard@tkr.com.
          <br />
          <br />
          15. Data protection officer <br />
          <br />
          15.1 Our data protection officer's contact details are: Gerard Egan
          gerard@tkr.com.
        </div>
      </div>
    </div>
  );
}
