import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { UserContext } from "../../../Store";
import Validate from "../../../utils/validation";
import { Link } from "react-router-dom";
import LoginError from "./LoginError";

export default function LoginForm({ setShowModal, setRegister }) {
  const [error, setError] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [persistLogin, setPersistLogin] = useState(false);
  const [, setUser] = useContext(UserContext);
  const [loading, setLoading] = useState();

  useEffect(() => {
    const enterListener = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        submitValidation();
      }
    };
    document.addEventListener("keyup", enterListener);
    return () => {
      document.removeEventListener("keyup", enterListener);
    };
  });

  const submitValidation = () => {
    if (!email) {
      setError("Please enter an email address");
    } else if (!Validate.loginEmail(email.trim())) {
      setError("Please enter a valid email address");
    } else if (!password) {
      setError("Please enter your password");
    } else {
      setLoading(true);
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post("/api/users/login", {
        email: email.trim(),
        password: password,
        persistLogin: persistLogin,
      });
      if (
        !response.data.success &&
        response.data.msg === "password incorrect"
      ) {
        setError(`Username or password not found. Please try again`);
        setLoading(false);
        setPassword();
        setEmail();
        setPersistLogin(false);
      } else if (
        !response.data.success &&
        response.data.msg === "server error"
      ) {
        setError("Sorry, there was an error... Please try again");
        setLoading(false);
        setPassword();
        setEmail();
        setPersistLogin(false);
      } else if (
        !response.data.success &&
        response.data.msg === "authentication error"
      ) {
        await axios.get("/api/users/logout");
        setError("Sorry, there was an error... Please try again");
        setLoading(false);
        setPassword();
      } else {
        setUser(response.data.user);
        setLoading(false);
        setShowModal(false);
      }
    } catch (err) {
      console.log(err.message);
      setError("Sorry, there was an error... Please try again");
      setLoading(false);
      setPassword();
    }
  };

  const LoginHeader = () => {
    return (
      <div className="desktop-login-modal-header-container">
        <div className="desktop-login-modal-heading">Sign in</div>
        <div className="desktop-login-modal-sub-heading">
          Need an account?{" "}
          <span
            className="desktop-login-modal-register-switch"
            onClick={() => setRegister(true)}
          >
            Sign up here
          </span>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="desktop-login-modal-loading-container">
        <div className="loading-component"></div>
        <div className="desktop-login-modal-loading-caption">Loading...</div>
      </div>
    );
  }

  return (
    <>
      <LoginHeader />
      <div className="desktop-login-modal-label">Email address:</div>
      <input
        className="desktop-login-modal-text-input"
        type="text"
        onChange={(e) => setEmail(e.target.value.toLowerCase())}
      />
      <div className="desktop-login-modal-label">Password:</div>
      <input
        className="desktop-login-modal-text-input login-page-password-input"
        type="password"
        onChange={(e) => setPassword(e.target.value)}
      />
      <div className="login-page-forgotten-password">
        <Link
          to="/users/resetPassword"
          style={{ textDecoration: "none" }}
          onClick={() => setShowModal(false)}
        >
          Forgotten your password?
        </Link>
      </div>
      <div className="login-page-checkbox-container">
        <input
          className="login-page-persist-checkbox"
          type="checkbox"
          id="persist-login"
          onChange={() => setPersistLogin(!persistLogin)}
        />
        <label htmlFor="persist-login">Keep me logged in</label>
      </div>
      {error && <LoginError error={error} closeError={() => setError()} />}
      <button
        className="desktop-login-modal-submit-button"
        onClick={submitValidation}
        type="submit"
        id="submitButton"
      >
        Sign in
      </button>
    </>
  );
}
