import AppFooterNav from './AppFooterNav';
import FooterCopyright from './FooterCopyright';

export default function AppFooter() {
  return (
    <>
      <div className="desktop-fixed-footer-container">
        <AppFooterNav />
        {/* <FooterCopyright /> */}
      </div>
    </>
  );
}
