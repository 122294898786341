import greenTick from "../../../../images/greenTick1.png";
import redCross from "../../../../images/redCross1.png";
import Validate from "../../../../utils/validation";
import ErrorMessage from "../ErrorMessage";

export default function FileListHeader({ files, setFiles, error, setError }) {
  const sizeArray = files.map((file) => file.size);
  const bytesTotal = sizeArray.reduce((a, b) => a + b, 0);
  const totalMegabytes = (bytesTotal / 1000000).toFixed(1);

  const addMoreFiles = (event) => {
    const filesArray = Object.values(event.target.files);
    const combinedFiles = files.concat(filesArray);
    const validFilesArray = Validate.mediaFileUpload(filesArray);
    const validFilesSize = Validate.uploadSize(combinedFiles);
    const validDuplicates = Validate.duplicateFiles(files, filesArray);
    if (!validDuplicates) {
      setError(
        "Sorry... Files cannot have same name. The files have not been added."
      );
    } else if (validFilesArray === "invalid file type") {
      setError("Sorry... Only image and video files can be uploaded.");
    } else if (!validFilesSize) {
      setError("A little too much... Free up some space (1 GB limit)");
      setFiles(combinedFiles);
    } else {
      setFiles(combinedFiles);
      setError();
    }
  };

  const clearAllFiles = () => {
    setFiles([]);
    setError();
  };

  return (
    <>
      <div className="desktop-upload-files-list-header">
        <img
          className="upload-details-icon"
          src={bytesTotal < 1073741824 ? greenTick : redCross}
          alt="validation icon"
        />
        <div className="upload-media-details-label">
          {totalMegabytes} MB used in {files.length} files
        </div>
      </div>
      <div className="desktop-upload-files-button-container">
        <label
          htmlFor="add-more-files"
          className="desktop-upload-add-more-files-button"
        >
          Add More Files
        </label>
        <input
          id="add-more-files"
          onChange={addMoreFiles}
          type="file"
          accept="image/*, video/*"
          multiple
        />
        <label
          className="desktop-upload-clear-all-files-button"
          onClick={clearAllFiles}
        >
          Clear All
        </label>
      </div>
      {error && <ErrorMessage error={error} setError={setError} />}
    </>
  );
}
