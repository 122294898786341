import { useContext } from "react";
import { LoadingUserContext, UserContext } from "../../../Store";
import { Link } from "react-router-dom";
import logo from "../../../images/logo.png";
import mobileBeta from "../../../images/mobileBeta2.png";
import { useHistory } from "react-router-dom";
import { IconContext } from "react-icons";
import { AiOutlineUserAdd } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";

export default function Navbar() {
  const [loadingUser] = useContext(LoadingUserContext);
  const [user] = useContext(UserContext);

  const history = useHistory();

  const iconOnClick = () => {
    if (user) {
      history.push("/users");
    } else {
      history.push("/users/login");
    }
  };

  const LoginSection = () => {
    if (loadingUser) {
      return <div className="small-loading-component"></div>;
    }
    if (!loadingUser && user) {
      return (
        <IconContext.Provider value={{ color: "#00157f", size: "2em" }}>
          <AiOutlineUser onClick={iconOnClick} />
        </IconContext.Provider>
      );
    }
    return (
      <IconContext.Provider value={{ color: "#00157f", size: "2em" }}>
        <AiOutlineUserAdd onClick={iconOnClick} />
      </IconContext.Provider>
    );
  };

  return (
    <>
      <div className="navbar-container">
        <div style={{ width: "70px" }}>
          <img className="navbar-beta-logo" src={mobileBeta} alt="tkr logo" />
        </div>
        <Link to="/">
          <img className="navbar-logo" src={logo} alt="tkr logo" />
        </Link>
        <div className="mobile-navbar-login-section">
          <LoginSection />
        </div>
      </div>
    </>
  );
}
