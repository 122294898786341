import AppFooterNav from './AppFooterNav';

export default function AppFooter() {
  return (
    <>
      <div className="desktop-footer-container">
        <AppFooterNav />
      </div>
    </>
  );
}
