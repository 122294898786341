import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../Store";
import FilesList from "./FilesList/FilesList";
import UploadOptions from "./UploadOptions/UploadOptions";
import AddFiles from "./AddFiles/AddFiles";
import ErrorMessage from "./ErrorMessage";
import Validate from "../../../utils/validation";
import ConfirmModal from "./ConfirmModal";
import CompressingFiles from "./LoadingStage/CompressingFiles";
import PostingFiles from "./LoadingStage/PostingFiles";
import GeneratingCode from "./LoadingStage/GeneratingCode";
import CodeDisplay from "./CodeDisplay/CodeDisplay";
import arraySort from "array-sort";
import imageCompression from "browser-image-compression";
import axios from "axios";

export default function UploadFilesBox({ setShowGetMedia }) {
  const [user] = useContext(UserContext);

  const [files, setFiles] = useState([]);
  const [error, setError] = useState();
  const [memberCodeCopy, setMemberCodeCopy] = useState("");
  const [expiryCopy, setExpiryCopy] = useState();
  const [passwordCopy, setPasswordCopy] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingStage, setLoadingStage] = useState();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [code, setCode] = useState();
  const [showMovError, setShowMovError] = useState(false);

  // Remove get box if files added

  useEffect(() => {
    if (files.length > 0) {
      setShowGetMedia(false);
      let movError = false;
      files.forEach((file) => {
        const fileExtension = file.name.split(".").pop();
        if (fileExtension === "mov") {
          movError = true;
        }
      });
      if (movError) {
        setShowMovError(true);
      } else {
        setShowMovError(false);
      }
    } else {
      setShowGetMedia(true);
    }
  }, [files]);

  const submit = async (
    memberCode,
    expiryOption,
    usePassword,
    password,
    acceptTCs
  ) => {
    const validFilesSize = Validate.uploadSize(files);
    const validFilesArray = Validate.mediaFileUpload(files);
    if (validFilesArray === "invalid file type") {
      setError("Sorry... Only image and video files can be uploaded");
    } else if (!validFilesSize) {
      setError("A little too much... Free up some space (1 GB limit)");
    } else if (!expiryOption && !memberCode) {
      setError("Please choose an expiry option");
    } else if (usePassword && !password) {
      setError(`Please enter an access password or select 'no'`);
    } else if (!acceptTCs) {
      setError("Please accept the terms and conditions");
    } else {
      setError();
      checkMemberCodeStatus(memberCode, expiryOption, usePassword, password);
    }
  };

  const checkMemberCodeStatus = (
    memberCode,
    expiryOption,
    usePassword,
    password
  ) => {
    if (memberCode) {
      const foundMemberCode = user.memberCodes.find(
        (code) => code.code === memberCode
      );
      if (foundMemberCode.status === "active") {
        setMemberCodeCopy(memberCode);
        setExpiryCopy(expiryOption);
        if (usePassword) {
          setPasswordCopy(password);
        }
        setShowConfirmModal(true);
      } else {
        processFiles(false, memberCode, expiryOption, password);
      }
    } else {
      processFiles(false, memberCode, expiryOption, password);
    }
  };

  const processFiles = async (
    fromConfirm,
    memberCode,
    expiryOption,
    password
  ) => {
    setShowConfirmModal(false);
    files.forEach((file, fileIndex) => {
      file.fileIndex = fileIndex;
    });
    setError();
    setLoadingStage("compressing");
    setIsLoading(true);
    try {
      const imageFiles = files.filter((file) => {
        const mimetype = file.type.split("/");
        if (mimetype[0] === "image") {
          return true;
        } else {
          return false;
        }
      });
      const compressedFiles = await compressFiles(imageFiles);
      const otherFiles = files.filter((file) => {
        const mimetype = file.type.split("/");
        if (mimetype[0] !== "image") {
          return true;
        } else {
          return false;
        }
      });
      const concatFiles = compressedFiles.concat(otherFiles);
      concatFiles.forEach((file) => {
        const originalFile = files.find(
          (fileObj) => fileObj.name === file.name
        );
        file.fileIndex = originalFile.fileIndex;
      });
      arraySort(concatFiles, "fileIndex");
      const postObject = {
        files: concatFiles,
      };
      if (fromConfirm) {
        postObject.memberCode = memberCodeCopy;
        postObject.expiry = expiryCopy;
        postObject.password = passwordCopy;
      } else {
        postObject.memberCode = memberCode;
        postObject.expiry = expiryOption;
        postObject.password = password;
      }
      await postFiles(postObject);
    } catch (err) {
      console.log(err.message);
    }
  };

  const compressFiles = async (filesArray) => {
    const compressedFiles = [];
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 2048,
      useWebWorker: true,
      // optional progress function if required see docs
    };
    try {
      await Promise.all(
        filesArray.map(async (file) => {
          const compressedFile = await imageCompression(file, options);
          compressedFiles.push(compressedFile);
        })
      );
    } catch (err) {
      console.log(err.message);
    }
    return compressedFiles;
  };

  const postFiles = async (postObject) => {
    const { files, memberCode, expiry, password } = postObject;
    setLoadingStage("posting");
    const fileNames = files.map((file) => file.name);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append(file.name, file);
    });
    formData.append("fileNames", JSON.stringify(fileNames));
    if (expiry) {
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + expiry);
      formData.append("expires", expiryDate);
    }
    if (memberCode) {
      formData.append("memberCode", memberCode.toLowerCase());
    }
    if (password) {
      formData.append("password", password);
    }
    try {
      const response = await axios.post("/api/codes", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: function (progressEvent) {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          setUploadPercentage(percent);
          if (percent === 100) {
            setLoadingStage("generating");
          }
        },
      });
      setCode(response.data.code);
      setLoadingStage("complete");
      setError();
      setUploadPercentage(0);
      setFiles([]);
      setExpiryCopy();
      setMemberCodeCopy();
      setPasswordCopy();
      setIsLoading(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleCompleteClick = () => {
    setCode();
    setLoadingStage();
    setError();
    setUploadPercentage(0);
    setFiles([]);
    setExpiryCopy();
    setMemberCodeCopy();
    setPasswordCopy();
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div
        className="desktop-upload-files-box-container"
        style={{ marginTop: "50px" }}
      >
        {loadingStage === "compressing" && <CompressingFiles />}
        {loadingStage === "posting" && (
          <PostingFiles uploadPercentage={uploadPercentage} />
        )}
        {loadingStage === "generating" && <GeneratingCode />}
      </div>
    );
  }

  if (code) {
    return (
      <CodeDisplay code={code} handleCompleteClick={handleCompleteClick} />
    );
  }

  return (
    <>
      {showConfirmModal && (
        <ConfirmModal
          setShowConfirmModal={setShowConfirmModal}
          processFiles={processFiles}
          memberCode={memberCodeCopy}
        />
      )}
      <div
        className="desktop-upload-files-box-container"
        style={{ marginTop: "50px" }}
      >
        {files.length === 0 && (
          <AddFiles setFiles={setFiles} setError={setError} />
        )}
        {files.length > 0 && (
          <>
            <FilesList
              files={files}
              setFiles={setFiles}
              error={error}
              setError={setError}
            />
            {showMovError && (
              <div className="desktop-upload-files-box-mov-error">
                .mov files occasionally do not format correctly. Consider
                converting to mp4 before uploading.
              </div>
            )}
            <UploadOptions submit={submit} error={error} setError={setError} />
          </>
        )}
        {error && <ErrorMessage error={error} setError={setError} />}
      </div>
    </>
  );
}
