import { useEffect, useState, useContext } from "react";
import fontLogo from "../../../images/fontLogo.png";
import copyClipboard from "../../../images/copyClipboard.png";
import { UserContext } from "../../../Store";
import UserMessage from "./UserMessage";
import { useParams } from "react-router-dom";

export default function DisplayCodePage() {
  const [user] = useContext(UserContext);
  const [copied, setCopied] = useState(false);

  const { code } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function copyToClipboard(text) {
    var dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.value = `tkr.com/${text}`;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    setCopied(true);
  }

  return (
    <>
      {!user && <UserMessage code={code} />}
      <div className="display-code-container">
        <div className="display-code-subtitle">
          Your{" "}
          <img className="display-code-font-logo" src={fontLogo} alt="tkr" />
          <span style={{ whiteSpace: "nowrap" }}>share code:</span>
        </div>
        <div className="display-code">{code}</div>
        <div className="display-code-access-subtitle">
          Access your media at:
        </div>
        <div className="display-code-code-copy-container">
          <div className="display-code-access-url">
            tkr.com/<span className="display-code-url-code">{code}</span>
          </div>
          <img
            className="display-code-copy-image"
            src={copyClipboard}
            onClick={() => copyToClipboard(code)}
            alt="copy to clipboard"
          />
        </div>
        {copied && (
          <div className="display-code-copied-message">
            Copied to clipboard!
          </div>
        )}
      </div>
      {user && <UserMessage code={code} />}
    </>
  );
}
