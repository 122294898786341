import { useState } from "react";
import displayCodeStar from "../../../../images/displayCodeStar.png";
import deleteBin from "../../../../images/deleteBin.png";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import DeleteCodeModal from "../DeleteCodeModal";

export default function MyUploadDisplay({ code, setShowDashboard }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const date = new Date();
  const dateString = date.toISOString();

  const getExpiresText = () => {
    if (code.expires === "") {
      return "Unavailable";
    } else if (code.expires < dateString) {
      return (
        <>
          Expired <Moment fromNow>{code.expires}</Moment>
        </>
      );
    } else {
      return (
        <>
          Expires <Moment fromNow>{code.expires}</Moment>
        </>
      );
    }
  };

  return (
    <>
      {showDeleteModal && (
        <DeleteCodeModal code={code} setShowModal={setShowDeleteModal} />
      )}
      <div className="my-upload-display-container">
        <div className="my-upload-header-container">
          <img
            className="my-upload-display-star"
            src={displayCodeStar}
            alt="entry"
          />
          {code.status === "live" && (
            <Link
              to={`/${code.code}`}
              className="my-upload-code"
              style={{ textDecoration: "none", color: "black" }}
              onClick={() => setShowDashboard(false)}
            >
              {code.code}
            </Link>
          )}
          {code.status !== "live" && (
            <div className="my-upload-code">{code.code}</div>
          )}
          <img
            className="my-upload-delete-icon"
            src={deleteBin}
            alt="delete"
            onClick={() => setShowDeleteModal(true)}
          />
        </div>
        <div className="my-upload-details-container">
          <div className="my-upload-detail">
            {code.status === "live" ? "Online • " : "Offline • "}
            {getExpiresText()} • Created <Moment fromNow>{code.created}</Moment>{" "}
            • Viewed {code.viewCount} {code.viewCount !== 1 ? "times" : "time"}{" "}
            {code.lastViewed ? (
              <>
                • Last viewed <Moment fromNow>{code.lastViewed}</Moment>
              </>
            ) : (
              ""
            )}{" "}
            • {code.fileKeys.length} {code.fileKeys.length > 1 ? "files" : "file"}
          </div>
        </div>
      </div>
    </>
  );
}
