import { useState, useEffect, useContext } from "react";
import { UserContext, DesktopLoginModalContext } from "../../../../Store";
import ModalTemplate from "../../../Universal/ModalTemplate";
import ShowTerms from "../../LoginModal/ShowTerms";

export default function AcceptTCs({
  acceptTCs,
  setAcceptTCs,
  handleClick,
  showTermsModal,
  setShowTermsModal,
}) {
  const TermsModal = () => {
    return (
      <ModalTemplate closeModal={() => setShowTermsModal(false)} fixed={false}>
        <ShowTerms
          setShowTerms={setShowTermsModal}
          setAcceptTCs={setAcceptTCs}
          acceptTCs={acceptTCs}
        />
      </ModalTemplate>
    );
  };

  return (
    <>
      {showTermsModal && <TermsModal />}
      <div className="desktop-upload-terms-and-conditions-container">
        <input
          type="checkbox"
          checked={acceptTCs}
          id="ts-and-cs"
          onChange={(e) => handleClick(e)}
          className="upload-desktop-terms-checkbox"
        />
        <label htmlFor="ts-and-cs">I accept the&nbsp;</label>
        <span
          style={{ color: "#00157f" }}
          onClick={() => setShowTermsModal(true)}
        >
          Terms and Conditions
        </span>
      </div>
    </>
  );
}
