import React, { useState, useEffect, useContext } from "react";
import { RenderMediaContext } from "../../../Store";
import { IconContext } from "react-icons";
import { TiArrowLeftThick, TiArrowRightThick } from "react-icons/ti";
import { IoIosClose } from "react-icons/io";

export default function FullScreenControls({
  index,
  prevSlide,
  nextSlide,
  exitFullScreen,
}) {

  const [showControls, setShowControls] = useState(true);
  const [renderMedia] = useContext(RenderMediaContext);

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  });

  useEffect(() => {
    const controlsInterval = setInterval(() => {
      if (showControls) {
        setShowControls(false);
      }
    }, 3000);
    return () => {
      clearInterval(controlsInterval);
    };
  });

  const handleMouseMove = () => {
    setShowControls(true);
  }

  if (showControls) {
    return (
      <div className="mobile-full-screen-controls-container">
        <div className="mobile-full-screen-slide-counter">
          {index + 1}/{renderMedia.media.length}
        </div>
        <IconContext.Provider value={{ color: "#fff", size: "1.8em" }}>
          <TiArrowLeftThick onClick={prevSlide} />
          <TiArrowRightThick onClick={nextSlide} />
        </IconContext.Provider>
        <IconContext.Provider value={{ color: "#fff", size: "2.2em" }}>
          <IoIosClose onClick={exitFullScreen} />
        </IconContext.Provider>
      </div>
    );
  }
  return null;
}
