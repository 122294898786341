import { useState } from "react";
import logo from "../../../../images/logo.png";
import { MdClose } from "react-icons/md";
import UserAccountForm from "./UserAccountForm";
import DeleteAccount from "./DeleteAccount";

export default function AccountDetails({ setShowModal }) {
  const [showFooter, setShowFooter] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const Header = () => {
    return (
      <div className="desktop-dashboard-section-container">
        <div className="desktop-dashboard-section-heading-container">
          <div className="desktop-dashboard-section-heading">
            Account details:
          </div>
        </div>
      </div>
    );
  };

  const ButtonFooter = () => {
    return (
      <div className="desktop-account-details-delete-button-container">
        <button
          className="desktop-account-details-close-button"
          onClick={() => setShowModal(false)}
        >
          Close
        </button>
        <button
          className="desktop-account-details-delete-button"
          onClick={() => setShowDelete(true)}
        >
          Delete my account
        </button>
      </div>
    );
  };

  if (showDelete) {
    return (
      <div className="desktop-account-details-modal-container">
        <div className="desktop-account-details-modal-box">
          <div className="desktop-login-modal-logo-container">
            <div className="desktop-login-modal-logo-placeholder"></div>
            <img className="desktop-login-modal-logo" src={logo} alt="" />
            <MdClose
              className="desktop-login-close-icon"
              onClick={() => setShowModal(false)}
            />
          </div>
          <DeleteAccount setShowDelete={setShowDelete} />
        </div>
      </div>
    );
  }

  return (
    <div className="desktop-account-details-modal-container">
      <div className="desktop-account-details-modal-box">
        <div className="desktop-login-modal-logo-container">
          <div className="desktop-login-modal-logo-placeholder"></div>
          <img className="desktop-login-modal-logo" src={logo} alt="" />
          <MdClose
            className="desktop-login-close-icon"
            onClick={() => setShowModal(false)}
          />
        </div>
        <Header />
        <UserAccountForm setShowFooter={setShowFooter} />
        {showFooter && <ButtonFooter />}
      </div>
    </div>
  );
}
