import Validate from "../../../../utils/validation";
import removeIcon from "../../../../images/removeIcon.png";
import MediaThumbnail from "../../../Mobile/UploadPage/FilesDisplay/MediaThumbnail";

export default function FileEntry({ file, fileIndex, files, setFiles, error, setError }) {
  const removeFile = () => {
    const newFiles = [...files];
    newFiles.splice(fileIndex, 1);
    setFiles(newFiles);
    const validFilesSize = Validate.uploadSize(newFiles);
    if (validFilesSize && error === "A little too much... Free up some space (1 GB limit)") {
      setError();
    }
  };

  const fileSize = (file.size / 1000000).toFixed(2);
    return (
      <>
        <div className="desktop-upload-file-entry">
          <MediaThumbnail file={file} key={file.name} />
          <div className="desktop-upload-file-details-container">
            <div className="desktop-upload-file-name">{file.name}</div>
            <div className="desktop-upload-file-size">{fileSize} MB</div>
          </div>
          <img
            className="desktop-upload-remove-file-icon"
            src={removeIcon}
            alt="remove file"
            onClick={removeFile}
          />
        </div>
      </>
    );
}