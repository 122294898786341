import videoIcon from '../../../../images/videoIcon.png';

export default function MediaThumbnail({ file }) {
  const fileType = file.type.split("/");
  const fileUrl = URL.createObjectURL(file);

  const getThumbnail = () => {
    switch (fileType[0]) {
      case "image":
        return (
          <img
            className="thumbnail-render"
            src={fileUrl}
            alt="media-thumbnail"
          ></img>
        );
      case "video":
        return (
          <img
            className="thumbnail-render"
            src={videoIcon}
            alt="media-thumbnail"
          ></img>
        );
      // case "application":
      //   if (fileType[1] === "pdf") {
      //     return (
      //       <img
      //         className="align-center app-icon"
      //         src={pdfIcon}
      //         alt="media-thumbnail"
      //       ></img>
      //     );
      //   }
      //   return null;
      default:
        return null;
    }
  }

  return (
    <>
      <div className="thumbnail-container">{getThumbnail()}</div>
    </>
  );
}
