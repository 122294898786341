import OutsideClickHandler from "react-outside-click-handler";
import logo from "../../images/logo.png";
import { MdClose } from "react-icons/md";

export default function ModalTemplate({ children, closeModal, fixed }) {
  if (!fixed) {
    return (
      <div className="modal-template-container">
        <OutsideClickHandler onOutsideClick={closeModal}>
          <div className="modal-template-box">
            <div className="modal-template-logo-container">
              <div className="modal-template-logo-placeholder"></div>
              <img className="modal-template-logo" src={logo} alt="" />
              <MdClose
                className="modal-template-close-icon"
                onClick={closeModal}
              />
            </div>
            <div className="modal-template-content">{children}</div>
          </div>
        </OutsideClickHandler>
      </div>
    );
  }

  return (
    <div className="modal-template-container">
      <div className="modal-template-box">
        <div className="modal-template-logo-container">
          <div className="modal-template-logo-placeholder"></div>
          <img className="modal-template-logo" src={logo} alt="" />
          <MdClose className="modal-template-close-icon" onClick={closeModal} />
        </div>
        <div className="modal-template-content">{children}</div>
      </div>
    </div>
  );
}
