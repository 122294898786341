import errorIcon from "../../../images/errorIcon2.png";

export default function UserAccountConfirmation({ property }) {
  return (
    <>
      <div className="account-success-message-mini-container">
        <div className="account-success-message-left-strip"></div>
        <img className="mobile-error-icon" src={errorIcon} alt=""/>
        <div className="mobile-error-mini-message">
          You have successfully changed your {property}.
        </div>
      </div>
    </>
  );
}
