import { useContext } from "react";
import axios from "axios";
import { UserContext } from "../../../Store";
import { Link } from "react-router-dom";
import AddMediaLink from "./AddMediaLink";

export default function UserAreaHeader() {
  const [user, setUser] = useContext(UserContext);

  const handleLogOut = async () => {
    const response = await axios.get("/api/users/logout");
    if (response.data === "logged out") {
      setUser();
    }
  };

  return (
    <>
      <div className="user-dashboard-welcome-header">
        <div className="user-dashboard-name-heading">Hi {user.name}!</div>
        <AddMediaLink />
        <div className="user-dashboard-logout-button">
          <span onClick={handleLogOut}>Log out</span>
        </div>
      </div>
      <div className="user-dashboard-account-details-link">
        <Link to="/users/account" style={{ textDecoration: "none", color: "#00157f" }}>
          <span>Account details</span>
        </Link>
      </div>
    </>
  );
}
