import { useEffect, useState } from "react";
import UploadFilesBox from "../UploadFiles/UploadFilesBox";
import FixedAppFooter from "../AppFooter/FixedAppFooter";
import GetMediaBox from "../GetMediaBox/GetMediaBox";
import DesktopHeading from "../DesktopHeading/DesktopHeading";

export default function Landing() {
  const [showGetMedia, setShowGetMedia] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="desktop-landing-container">
        <div className="desktop-landing-upload-area">
          <UploadFilesBox setShowGetMedia={setShowGetMedia} />
          {showGetMedia && <GetMediaBox />}
        </div>
        <div className="desktop-heading">
          <DesktopHeading />
        </div>
      </div>
      <FixedAppFooter />
    </>
  );
}
