import logo2 from '../../../images/logo2.png';
import AppFooterNav from './AppFooterNav';
import FooterCopyright from './FooterCopyright';

export default function AppFooter() {
  return (
    <>
      <div className="app-footer-container">
        <img className="app-footer-logo" src={logo2} alt=""/>
        <AppFooterNav />
        <FooterCopyright />
      </div>
    </>
  );
}
