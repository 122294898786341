import { useContext, useState } from "react";
import displayCodeStar from "../../../images/displayCodeStar.png";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import deleteBin from "../../../images/deleteBin.png";
import axios from "axios";
import { UserContext } from "../../../Store";
import { Modal, Box } from "@mui/material";
import logo from "../../../images/logo.png";

export default function MyViewedDisplay({ code }) {
  const [, setUser] = useContext(UserContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingCode, setDeletingCode] = useState(false);

  const style = {
    position: "absolute",
    top: "35%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "260px",
    width: "90%",
    maxWidth: "600px",
    bgcolor: "background.paper",
    border: "1px solid lightgrey",
    borderRadius: "4px",
    boxShadow: 24,
    p: 2,
  };

  const date = new Date();
  const dateString = date.toISOString();

  const deleteCode = async () => {
    try {
      setDeletingCode(true);
      await axios.delete(`/api/users/viewedCode/${code.code}`);
      const response = await axios.get(`/api/users/get-user`);
      if (response) {
        setUser(response.data.user);
      }
    } catch (err) {
      console.log(err.message);
    }
    setDeletingCode(false);
  };

  const getExpiresText = () => {
    if (code.expires < dateString && code.status === "expired") {
      return (
        <>
          Expired <Moment fromNow>{code.expires}</Moment>
        </>
      );
    } else if (code.expires === "" && code.status === "dormant") {
      return "Unavailable";
    } else if (code.expires === "" && code.status === "live") {
      return "Unlimited";
    } else {
      return (
        <>
          Expires <Moment fromNow>{code.expires}</Moment>
        </>
      );
    }
  };

  const getLinkUrl = () => {
    if (code.expires === "" || code.expires < dateString) {
      return "#";
    } else {
      return `/${code.code}`;
    }
  };

  const renderConfirmDeleteModal = () => {
    return (
      <>
        <Modal
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="delete-modal-header-container">
              <img
                className="overwrite-modal-header-logo"
                src={logo}
                alt="logo"
              />
            </div>
            {deletingCode && (
              <>
                <div className="mobile-delete-code-loading-container">
                  <div className="loading-component"></div>
                  <div className="desktop-login-modal-loading-caption">
                    Deleting {code.code}...
                  </div>
                </div>
              </>
            )}
            {!deletingCode && (
              <>
                <div className="overwrite-upload-code-modal-title">
                  Delete {code.code}?
                </div>
                <div className="delete-upload-code-modal-caption">
                  This will permanently remove code{" "}
                  <span className="delete-upload-modal-code">{code.code}</span>{" "}
                  from your viewed codes record. Are you sure you wish to
                  delete?
                </div>
                <div className="overwrite-upload-code-modal-footer">
                  <button
                    className="overwrite-upload-code-modal-button"
                    onClick={() => setShowDeleteModal(false)}
                    style={{ backgroundColor: "#999" }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={deleteCode}
                    className="overwrite-upload-code-modal-button"
                    style={{ backgroundColor: "red" }}
                  >
                    Delete
                  </button>
                </div>
              </>
            )}
          </Box>
        </Modal>
      </>
    );
  };

  return (
    <>
      {renderConfirmDeleteModal()}
      <div className="my-upload-display-container">
        <div className="my-upload-header-container">
          <img
            className="my-upload-display-star"
            src={displayCodeStar}
            alt="entry"
          />
          <Link
            to={getLinkUrl()}
            className="my-upload-code"
            style={{ textDecoration: "none", color: "black" }}
          >
            {code.code}
          </Link>
          <img
            className="my-upload-delete-icon"
            src={deleteBin}
            alt="delete"
            onClick={() => setShowDeleteModal(true)}
          />
        </div>
        <div className="my-upload-details-container">
          <div className="my-upload-detail">
            {getExpiresText()} • Viewed {code.viewCount}{" "}
            {code.viewCount !== 1 ? "times" : "time"}{" "}
            {code.lastViewed ? (
              <>
                • Last viewed <Moment fromNow>{code.lastViewed}</Moment>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}
