import { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../Store";

export default function UserMessage({ code }) {
  const [user] = useContext(UserContext);
  return (
    <>
      {user && (
        <div className="display-code-user-message-container">
          Manage and track uploads on your{" "}
          <Link
            to="/users"
            style={{ textDecoration: "none", color: "#00157f" }}
          >
            user dashboard
          </Link>
        </div>
      )}
      {!user && (
        <div className="display-code-user-message-container">
          ✦ Need somewhere to store and track your code?{" "}
          <Link
            to={`/users/register/${code}`}
            style={{ textDecoration: "none", color: "#00157f" }}
          >
            Sign up here
          </Link>{" "}
          ✦
        </div>
      )}
    </>
  );
}
