import FullScreenControls from "./FullScreenControls";
import FullScreenMedia from "./FullScreenMedia";
import HiddenMedia from "../HiddenMedia";

export default function FullScreenView({
  setShow,
  autoPlay,
  setAutoPlay,
  index,
  nextSlide,
  prevSlide,
}) {

  return (
    <div
      className="desktop-render-media-full-screen-container"
      id="desktop-full-screen"
    >
      <HiddenMedia index={index} />
      <div>
        <FullScreenControls
          index={index}
          nextSlide={nextSlide}
          prevSlide={prevSlide}
          autoPlay={autoPlay}
          setAutoPlay={setAutoPlay}
          setShow={setShow}
        />
      </div>
      <FullScreenMedia
        index={index}
        autoPlay={autoPlay}
        nextSlide={nextSlide}
      />
    </div>
  );
}
