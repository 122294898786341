import { useEffect } from "react";
import GeneratingCode from "./GeneratingCode";
import PackagingFiles from "./PackagingFiles";
import UploadingFiles from "./UploadingFiles";

export default function FilesUploading({ stage, uploadPercentage }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="uploading-files-container">
        {stage === "compressing" && <PackagingFiles />}
        {stage === "posting" && (
          <UploadingFiles uploadPercentage={uploadPercentage} />
        )}
        {stage === "generating" && <GeneratingCode />}
      </div>
    </>
  );
}
