import { useState, useEffect, useContext } from "react";
import { RenderMediaContext } from "../../../../Store";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import MainCarouselImage from "./MainCarouselImage";
import MainCarouselVideo from "./MainCarouselVideo";
import UnauthenticatedImage from "../UnauthenticatedImage";

export default function MainCarousel({
  index,
  setIndex,
  handleImageClick,
  authenticated,
}) {
  const [renderMedia] = useContext(RenderMediaContext);
  const [showMedia, setShowMedia] = useState(false);
  const [displayIndex, setDisplayIndex] = useState(index);

  useEffect(() => {
    setShowMedia(false);
    setTimeout(() => {
      setShowMedia(true);
      setDisplayIndex(index);
    }, 200);
  }, [index]);

  if (!renderMedia.media || renderMedia.media.length <= 0) {
    return null;
  }

  const nextSlide = () => {
    setIndex(index === renderMedia.media.length - 1 ? 0 : index + 1);
  };

  const prevSlide = () => {
    setIndex(index === 0 ? renderMedia.media.length - 1 : index - 1);
  };

  const DisplayMedia = () => {
    const item = renderMedia.media[displayIndex];
    if (authenticated) {
      if (item.mimetype === "image") {
        return (
          <div className="main-media-carousel-slide">
            <MainCarouselImage
              index={displayIndex}
              handleImageClick={handleImageClick}
              authenticated={authenticated}
            />
          </div>
        );
      } else if (item.mimetype === "video") {
        return (
          <div className="main-media-carousel-slide">
            <MainCarouselVideo index={index} authenticated={authenticated} />
          </div>
        );
      }
    } else {
      return (
        <div className="main-media-carousel-slide">
          <UnauthenticatedImage
            index={displayIndex}
            handleImageClick={handleImageClick}
            authenticated={authenticated}
          />
        </div>
      );
    }
  };

  return (
    <div className="main-media-carousel-container">
      <div className="main-media-arrow-container">
        <FaArrowAltCircleLeft
          className="main-media-carousel-nav-icon-left"
          onClick={prevSlide}
        />
      </div>
      <div
        className={
          showMedia
            ? "main-media-carousel-slide-container"
            : "main-media-carousel-slide-container main-media-carousel-slide-container-hidden"
        }
      >
        <DisplayMedia />
      </div>
      <div className="main-media-arrow-container">
        <FaArrowAltCircleRight
          className="main-media-carousel-nav-icon-right"
          onClick={nextSlide}
        />
      </div>
    </div>
  );
}
