import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../../Store";

export default function MemberCodes({ memberCode, setMemberCode, setError }) {
  const [user] = useContext(UserContext);

  const handleMemberCodeSelect = (code) => {
    setMemberCode(code);
    setError();
  };

  const MemberCode = ({ code }) => {
    return (
      <div className="desktop-upload-code-entry">
        <div
          className={
            memberCode === code.code
              ? "desktop-upload-code-entry-checkbox desktop-upload-code-entry-checkbox-active"
              : "desktop-upload-code-entry-checkbox"
          }
          onClick={() => handleMemberCodeSelect(code.code)}
        ></div>
        <div className="desktop-upload-member-code-caption">{code.code}</div>
        {code.status === "dormant" ? (
          <div className="code-selection-status">Available</div>
        ) : (
          <div className="code-selection-status-active">In use</div>
        )}
      </div>
    );
  };

  if (user && user.account !== "free") {
    return (
      <div className="desktop-upload-member-codes-container">
        <div className="desktop-upload-subtitle">Code:</div>
        <div className="desktop-upload-codes-list">
          <div className="desktop-upload-code-entry">
            <div
              className={
                !memberCode
                  ? "desktop-upload-code-entry-checkbox desktop-upload-code-entry-checkbox-active"
                  : "desktop-upload-code-entry-checkbox"
              }
              onClick={() => setMemberCode()}
            ></div>
            <div className="desktop-upload-member-code-caption">
              5 random characters
            </div>
          </div>
          {user.memberCodes.map((code) => (
            <MemberCode code={code} key={code.code} />
          ))}
        </div>
      </div>
    );
  }
  return null;
}
