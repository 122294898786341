import { useContext } from "react";
import {
  UserContext,
  DesktopLoginModalContext,
  DesktopDashboardContext,
  DesktopFromRegisterContext,
} from "../Store";
import { useTransition, animated } from "react-spring";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Landing from "../Components/Desktop/Landing/Landing";
import Navbar from "../Components/Desktop/Navbar/Navbar";
import AboutUsPage from "../Components/Desktop/AboutUs/AboutUs";
import MembershipPage from "../Components/Desktop/Membership/Membership";
import AppFooter from "../Components/Desktop/AppFooter/AppFooter";
import CookiesAndDataPage from "../Components/Desktop/CookiesAndData/CookiesAndData";
import TermsOfUsePage from "../Components/Desktop/TermsOfUse/TermsOfUse";
import PrivacyPolicyPage from "../Components/Desktop/TermsOfUse/PrivacyPolicy";
import ContactUsPage from "../Components/Desktop/ContactUs/ContactUs";
import RenderMediaPage from "../Components/Desktop/RenderMediaPage/RenderMediaPage";
import ActivateAccount from "../Components/Desktop/ActivateAccount/ActivateAccount";
import ResetPasswordPage from "../Components/Desktop/RecoverPassword/ResetPasswordPage";
import NewPasswordPage from "../Components/Desktop/RecoverPassword/NewPasswordPage";
import ConfirmDeletedPage from "../Components/Desktop/UserDashboard/AccountDetails/ConfirmDeletedPage";
import LoginModal from "../Components/Desktop/LoginModal/LoginModal";
import UserDashboard from "../Components/Desktop/UserDashboard/UserDashboard";

export default function BrowserRouter() {
  const [user] = useContext(UserContext);
  const [showLoginModal, setShowLoginModal] = useContext(
    DesktopLoginModalContext
  );
  const [showDashboard, setShowDashboard] = useContext(DesktopDashboardContext);
  const [fromRegister] = useContext(DesktopFromRegisterContext);

  const transition = useTransition(showDashboard, {
    from: { y: -1300 },
    enter: { y: 0 },
    leave: { y: -1300 },
  });

  return (
    <>
      <Router>
        {showLoginModal && (
          <LoginModal
            setShowModal={setShowLoginModal}
            fromRegister={fromRegister}
          />
        )}
        {user && (
          <>
            {transition((style, item) =>
              item ? (
                <animated.div style={style}>
                  <UserDashboard setShowDashboard={setShowDashboard} />
                </animated.div>
              ) : (
                ""
              )
            )}
          </>
        )}
        <Switch>
          <Route exact path="/">
            <Navbar />
            <Landing />
          </Route>
          <Route path="/aboutUs">
            <Navbar />
            <AboutUsPage />
            <AppFooter />
          </Route>
          <Route path="/membership">
            <Navbar />
            <MembershipPage />
            <AppFooter />
          </Route>
          <Route path="/cookiesAndData">
            <Navbar />
            <CookiesAndDataPage />
            <AppFooter />
          </Route>
          <Route path="/termsOfUse">
            <Navbar />
            <TermsOfUsePage />
            <AppFooter />
          </Route>
          <Route path="/contact">
            <Navbar />
            <ContactUsPage />
            <AppFooter />
          </Route>
          <Route path="/privacyPolicy">
            <Navbar />
            <PrivacyPolicyPage />
            <AppFooter />
          </Route>
          <Route path="/users/resetPassword">
            <Navbar />
            <ResetPasswordPage />
          </Route>
          <Route path="/users/activateAccount/:token">
            <Navbar />
            <ActivateAccount />
          </Route>
          <Route path="/users/newPassword/:token">
            <Navbar />
            <NewPasswordPage />
          </Route>
          <Route path="/users/deletedAccount">
            <Navbar />
            <ConfirmDeletedPage />
          </Route>
          <Route path="/:id">
            <RenderMediaPage />
          </Route>
        </Switch>
      </Router>
    </>
  );
}
