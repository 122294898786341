import { useContext } from "react";
import { UserContext } from "../../../Store";
import MemberCodeDisplay from "./MemberCodeDisplay";
import DormantCodeDisplay from "./DormantCodeDisplay";
import EmptyCodeList from "../EmptyCodeList/EmptyCodeList";
import arraySort from "array-sort";

export default function MemberCodes({ activeCodes, setActiveCodes, sortBy }) {
  const [user] = useContext(UserContext);

  const getCodesArray = () => {
    if (activeCodes) {
      return user.memberCodes.filter((code) => code.status === "active");
    } else {
      return user.memberCodes.filter((code) => code.status === "dormant");
    }
  };

  const codesArray = getCodesArray();

  const getReverseValue = () => {
    if (sortBy === "code" || sortBy === "expires") {
      return false;
    } else return true;
  };

  arraySort(user.memberCodes, sortBy, {
    reverse: getReverseValue(),
  });

  arraySort(user.viewedCodes, sortBy, {
    reverse: getReverseValue(),
  });

  const renderActiveCodesDisplay = () => {
    if (!codesArray.length) {
      return (
        <EmptyCodeList message={`You are not using any of your member codes`} />
      );
    } else {
      return (
        <div>
          {codesArray.map((code) => (
            <MemberCodeDisplay code={code} key={code.code} />
          ))}
        </div>
      );
    }
  };

  const renderDormantCodesDisplay = () => {
    if (!codesArray.length) {
      return <EmptyCodeList message={`All of your member codes are active`} />;
    } else {
      return (
        <div>
          {codesArray.map((code) => (
            <DormantCodeDisplay code={code} key={code.code} />
          ))}
        </div>
      );
    }
  };

  return (
    <>
      <div className="user-dashboard-codes-choice-container">
        <div
          className={
            activeCodes
              ? "user-dashboard-codes-choice-button-active"
              : "user-dashboard-codes-choice-button"
          }
          onClick={() => setActiveCodes(true)}
        >
          Active
        </div>
        <div
          className={
            activeCodes
              ? "user-dashboard-codes-choice-button"
              : "user-dashboard-codes-choice-button-active"
          }
          onClick={() => setActiveCodes(false)}
        >
          Inactive
        </div>
      </div>
      <div className="user-dashboard-codes-render-container">
        {activeCodes && renderActiveCodesDisplay()}
        {!activeCodes && renderDormantCodesDisplay()}
      </div>
    </>
  );
}
