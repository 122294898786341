import ProgressBar from "react-customizable-progressbar";

export default function PostingFiles({ uploadPercentage }) {
  return (
    <div className="desktop-upload-processing-container">
      <ProgressBar
        progress={uploadPercentage}
        radius={60}
        strokeWidth={18}
        strokeColor="#00157f"
      >
        <div className="percentage-indicator">
          <div>{uploadPercentage}%</div>
        </div>
      </ProgressBar>
      <div className="desktop-upload-processing-caption">
        Uploading media... <br />
        <br />
        Please do not close this page
      </div>
    </div>
  );
}
