import { useState, useEffect } from "react";
import axios from "axios";

export default function AdminSearchBox({
  setFoundUser,
  setError,
  setLoading,
  setUserUpdated,
}) {
  const [searchEmail, setSearchEmail] = useState();

  useEffect(() => {
    setUserUpdated(false);
  }, []);

  const searchForUser = async () => {
    setFoundUser();
    setLoading(true);
    try {
      const response = await axios.get(`/api/users/${searchEmail}`);
      if (
        !response.data.success &&
        response.data.msg === "authentication error"
      ) {
        setError("Authentication error");
        setFoundUser();
        setLoading(false);
      } else if (
        !response.data.success &&
        response.data.msg === "no user found"
      ) {
        setError("No user found");
        setFoundUser();
        setLoading(false);
      } else if (response.data.success) {
        const user = response.data.user;
        if (response.data.memberCodes) {
          user.memberCodes = response.data.memberCodes;
        } else {
          user.memberCodes = [];
        }
        setFoundUser(user);
        setError();
        setLoading(false);
      } else {
        setError("An error occurred");
        setFoundUser();
        setLoading(false);
      }
    } catch (err) {
      console.log(err.message);
      setError("An error occurred, please try again");
      setFoundUser();
      setLoading(false);
    }
  };

  const clearSearch = () => {
    setSearchEmail("");
    setError();
  };

  return (
    <>
      <div className="admin-search-box-container" onSubmit={searchForUser}>
        <form>
          <input
            className="admin-search-box-input"
            type="text"
            placeholder="Search user by email"
            onChange={(e) => setSearchEmail(e.target.value.toLowerCase())}
            value={searchEmail}
          />
        </form>
        <div className="admin-search-box-button-container">
          <button
            className="admin-search-clear-search-button"
            onClick={clearSearch}
          >
            Clear
          </button>
          <button
            className="admin-search-edit-search-button"
            onClick={searchForUser}
            type="submit"
          >
            Search
          </button>
        </div>
      </div>
    </>
  );
}
