import compressingIcon from "../../../../images/blueCameraIconWWDThin.png";

export default function CompressingFiles() {
  return (
    <div className="desktop-upload-processing-container">
      <img
        className="desktop-upload-processing-icon"
        src={compressingIcon}
        alt="processing files"
      />
      <div className="desktop-upload-processing-caption">
        Packaging your media for speedy access...
        <br />
        <br />
        Please do not close this page
      </div>
    </div>
  );
}
