import { useContext } from "react";
import logo from "../../../images/logoDarkerBackground.png";
import desktopCarousel1 from "../../../images/desktopCarousel1.png";
import desktopCarousel2 from "../../../images/desktopCarousel2.png";
import desktopCarousel3 from "../../../images/desktopCarousel3.png";
import whatWeDoImage from "../../../images/whatWeDoDesktop.png";
import cameraIcon from "../../../images/blueCameraIconWWDThin.png";
import padlockIcon from "../../../images/bluePadlockThin.png";
import shareIcon from "../../../images/blueShareIconThin.png";
import spannerIcon from "../../../images/blueSpannerThin.png";
import uploadIcon from "../../../images/blueUploadArrowThin.png";
import WWDImage from "../../../images/WWDImage.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { DesktopDashboardContext } from "../../../Store";

export default function DesktopHeading() {
  const [showDashboard, setShowDashboard] = useContext(DesktopDashboardContext);
  const DesktopCarousel = () => {
    return (
      <Carousel
        autoPlay={true}
        interval={5000}
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
        width={350}
        infiniteLoop={true}
        style={{ zIndex: 1 }}
      >
        <div>
          <img src={desktopCarousel1} alt="" />
        </div>
        <div>
          <img src={desktopCarousel2} alt="" />
        </div>
        <div>
          <img src={desktopCarousel3} alt="" />
        </div>
        <div>
          <img src={desktopCarousel1} alt="" />
        </div>
        <div>
          <img src={desktopCarousel2} alt="" />
        </div>
        <div>
          <img src={desktopCarousel3} alt="" />
        </div>
        <div>
          <img src={desktopCarousel1} alt="" />
        </div>
        <div>
          <img src={desktopCarousel2} alt="" />
        </div>
        <div>
          <img src={desktopCarousel3} alt="" />
        </div>
        <div>
          <img src={desktopCarousel1} alt="" />
        </div>
        <div>
          <img src={desktopCarousel2} alt="" />
        </div>
        <div>
          <img src={desktopCarousel3} alt="" />
        </div>
        <div>
          <img src={desktopCarousel1} alt="" />
        </div>
        <div>
          <img src={desktopCarousel2} alt="" />
        </div>
        <div>
          <img src={desktopCarousel3} alt="" />
        </div>
        <div>
          <img src={desktopCarousel1} alt="" />
        </div>
        <div>
          <img src={desktopCarousel2} alt="" />
        </div>
        <div>
          <img src={desktopCarousel3} alt="" />
        </div>
        <div>
          <img src={desktopCarousel1} alt="" />
        </div>
        <div>
          <img src={desktopCarousel2} alt="" />
        </div>
        <div>
          <img src={desktopCarousel3} alt="" />
        </div>
        <div>
          <img src={desktopCarousel1} alt="" />
        </div>
      </Carousel>
    );
  };

  const WWDComponent = ({ img, caption }) => {
    return (
      <div className="desktop-wwd-component-container">
        <div className="desktop-wwd-icon-container">
          <img className="desktop-wwd-icon" src={img} alt="" />
        </div>
        {caption}
      </div>
    );
  };

  return (
    <div className="desktop-heading-container">
      <div className="desktop-carousel-container">
        <img className="desktop-heading-logo" src={logo} alt="" />
        {!showDashboard && <DesktopCarousel />}
        {showDashboard && (
          <div style={{ width: "350px" }}>
            <img
              src={desktopCarousel1}
              alt=""
              style={{ width: "350px", objectFit: "contain" }}
            />
          </div>
        )}
      </div>
      <div className="desktop-what-we-do-container">
        <img className="desktop-wwd-image" src={WWDImage} alt="" />
        {/* <img
          className="desktop-what-we-do-title"
          src={whatWeDoImage}
          alt="what we do"
        />
        <WWDComponent
          img={cameraIcon}
          caption={
            <div className="desktop-wwd-caption">
              Package your media for speedy access
            </div>
          }
        />
        <WWDComponent
          img={padlockIcon}
          caption={
            <div className="desktop-wwd-caption">
              Strip meta data to protect your identity
            </div>
          }
        />
        <WWDComponent
          img={uploadIcon}
          caption={
            <div className="desktop-wwd-caption">
              Upload your media to the cloud until expiry
            </div>
          }
        />
        <WWDComponent
          img={spannerIcon}
          caption={
            <div className="desktop-wwd-caption">
              Generate a unique access code
            </div>
          }
        />
        <WWDComponent
          img={shareIcon}
          caption={
            <div className="desktop-wwd-caption">
              Access your media at www.tkr.com/
              <span style={{ color: "#00deb7" }}>*your-code*</span>
            </div>
          }
        /> */}
      </div>
    </div>
  );
}
