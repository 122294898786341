import { useState, useEffect, useContext } from "react";
import { RenderMediaContext } from "../../../../Store";
import axios from "axios";
import { BoxLoading } from "react-loadingg";

export default function MainCarouselImage({ index, handleImageClick, authenticated }) {
  const [renderMedia] = useContext(RenderMediaContext);
  const [update, setUpdate] = useState(true);

  const item = renderMedia.media[index];

  useEffect(() => {
    getItemUpdate();
    const updateInterval = setInterval(getItemUpdate, 5000);
    return () => clearInterval(updateInterval);
  });

  const getItemUpdate = async () => {
    if (!item.uploaded) {
      const response = await axios.get(
        `/api/codes/single-item/?code=${renderMedia.code}&fileName=${item.key}&authenticated=${authenticated}`
      );
      if (response.data.media.uploaded) {
        renderMedia.media[index] = response.data.media;
        setUpdate(!update);
      }
    }
  };

  if (!item.uploaded) {
    return (
      <div className="main-media-carousel-video-loading-container">
        <BoxLoading color="#00deb7" />
        <div className="main-media-carousel-video-loading-caption">
          This image is processing and will be available soon...
        </div>
      </div>
    );
  }

  return (
    <img
      className="main-media-carousel-image"
      src={item.url}
      alt=""
      onClick={handleImageClick}
    />
  );
}
