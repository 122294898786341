import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../../Store";

export default function ExpiryOptions({
  expiryOption,
  setExpiryOption,
  error,
  setError,
  memberCode,
}) {
  const [user] = useContext(UserContext);

  const handleLimitedClick = (expiry) => {
    setExpiryOption(expiry);
    if (error === "Please choose an expiry option") {
      setError();
    }
  };

  const handleUnlimitedClick = () => {
    if (memberCode) {
      setExpiryOption(false);
    } else {
      setError("Please select a member code to use unlimited expiry");
    }
  };

  const getUnlimitedClassName = () => {
    if (!memberCode) {
      return "desktop-upload-expiry-button desktop-upload-expiry-button-disabled";
    }
    if (expiryOption === false) {
      return "desktop-upload-expiry-button desktop-upload-expiry-button-active";
    }
    return "desktop-upload-expiry-button";
  };

  return (
    <>
      <div className="desktop-upload-files-options">
        <div className="desktop-upload-subtitle">Expiry:</div>
        <div
          className={
            user && user.account !== "free"
              ? "desktop-upload-options-button-container-member"
              : "desktop-upload-options-button-container"
          }
        >
          <button
            className={
              expiryOption === 2
                ? "desktop-upload-expiry-button desktop-upload-expiry-button-active"
                : "desktop-upload-expiry-button"
            }
            onClick={() => handleLimitedClick(2)}
          >
            2 Days
          </button>
          <button
            className={
              expiryOption === 5
                ? "desktop-upload-expiry-button desktop-upload-expiry-button-active"
                : "desktop-upload-expiry-button"
            }
            onClick={() => handleLimitedClick(5)}
          >
            5 Days
          </button>
          {user && user.account !== "free" && (
            <button
              className={getUnlimitedClassName()}
              onClick={handleUnlimitedClick}
            >
              Unlimited
            </button>
          )}
        </div>
      </div>
    </>
  );
}
