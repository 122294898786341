import React from "react";
import ReactDOM from "react-dom";
import "./reset.css";
import "./index.css";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "video-react/dist/video-react.css"
import App from "./App";
import DeviceViews from './DeviceViews';
import Store from "./Store";
import { themeOptions } from "./utils/ThemeOptions";
import { ThemeProvider } from "@mui/material/styles";

ReactDOM.render(
  <React.StrictMode>
    <Store>
      <ThemeProvider theme={themeOptions}>
        <DeviceViews />
      </ThemeProvider>
      <App />
    </Store>
  </React.StrictMode>,
  document.getElementById("root")
);
