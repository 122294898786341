import { useEffect } from "react";

export default function MembershipPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="desktop-info-page-background">
      <div className="desktop-info-page-container">
        <div className="desktop-about-us-heading">Membership</div>
        <div className="desktop-about-us-paragraph">
          tkr members can reserve their own codes for personal use and can also
          upload media without an expiry date. If you wish to become a member
          please contact us.
        </div>
      </div>
    </div>
  );
}
