import MiniAppFooter from "../AppFooter/MiniAppFooter";
import RenderImage from "./RenderImage";
import RenderVideo from "./RenderVideo";

export default function DoubleMedia({ media, handleClick, authenticated }) {
  const renderMedia = (item, itemIndex) => {
    if (authenticated) {
      if (item.mimetype === "image") {
        return (
          <RenderImage
            key={itemIndex}
            handleClick={handleClick}
            index={itemIndex}
            authenticated={authenticated}
          />
        );
      } else if (item.mimetype === "video") {
        return (
          <RenderVideo
            index={itemIndex}
            key={itemIndex}
            authenticated={authenticated}
          />
        );
      }
    } else {
      <RenderImage
        key={itemIndex}
        handleClick={handleClick}
        index={itemIndex}
        authenticated={authenticated}
      />;
    }
  };

  return (
    <>
      <div className="mobile-render-double-container">
        <div className="mobile-render-double-item">
          {renderMedia(media[0], 0)}
        </div>
        <div className="mobile-render-double-divider"></div>
        <div className="mobile-render-double-item">
          {renderMedia(media[1], 1)}
        </div>
      </div>
      <MiniAppFooter />
    </>
  );
}
