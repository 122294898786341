import React, { useState, useContext } from "react";
import MainCarousel from "./MainCarousel";
import ThumbnailCarousel from "./ThumbnailCarousel";
import HiddenMedia from "../HiddenMedia";
import { RenderMediaContext } from "../../../../Store";

export default function ThumbnailView({
  setFullScreenIndex,
  setShowFullScreen,
  setAutoPlay,
  authenticated,
}) {
  const [renderMedia] = useContext(RenderMediaContext);
  const [mediaIndex, setMediaIndex] = useState(0);

  const handleImageClick = () => {
    setFullScreenIndex(mediaIndex);
    setShowFullScreen(true);
    setAutoPlay(false);
  };

  return (
    <>
      <div className="desktop-render-media-thumb-view-container">
        <HiddenMedia index={mediaIndex} />
        <MainCarousel
          index={mediaIndex}
          setIndex={setMediaIndex}
          handleImageClick={handleImageClick}
          authenticated={authenticated}
        />
        <ThumbnailCarousel
          media={renderMedia.media}
          setIndex={setMediaIndex}
          authenticated={authenticated}
        />
      </div>
    </>
  );
}
