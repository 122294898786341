import { Link } from "react-router-dom";

export default function NewPasswordSuccess() {
  return (
    <div className="desktop-reset-password-page-container">
      <div className="reset-password-page-container">
        <div className="reset-password-page-heading">
          You have successfully reset your password
        </div>
        <div className="reset-password-page-caption">
          Your password has been reset, please log in.
        </div>
        <div className="desktop-activate-account-button-container">
        <Link to="/">
          <button className="desktop-activate-account-home-button">
            Homepage
          </button>
        </Link>
      </div>
      </div>
    </div>
  );
}
