import { Modal, Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import logo from "../../../images/logo.png";

export default function AddMediaUserModal({ showModal, setShowModal }) {
  const history = useHistory();

  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "260px",
    width: "90%",
    maxHeight: "100%",
    maxWidth: "500px",
    bgcolor: "background.paper",
    border: "1px solid lightgrey",
    borderRadius: "4px",
    boxShadow: 24,
    p: 2,
    overflow: "auto",
  };

  const continueButton = () => {
    history.push("/upload");
  };

  const userButton = () => {
    history.push("/users");
  };

  return (
    <>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="upload-media-modal-box" sx={style}>
          <div className="delete-modal-header-container">
            <img
              className="upload-media-modal-header-logo"
              src={logo}
              alt="logo"
            />
          </div>
          <div className="upload-media-modal-title">Sign up or sign in?</div>
          <div className="upload-media-modal-caption">
            Would you like help remembering your upload details?
          </div>
          <div className="upload-media-modal-caption">
            Create an account or sign in to store and track your upload codes
            and codes you've viewed.
          </div>
          <div className="upload-media-modal-footer">
            <button
              className="upload-media-modal-continue-button"
              onClick={continueButton}
            >
              Continue as a guest
            </button>
            <button
              className="upload-media-modal-user-button"
              onClick={userButton}
            >
              Sign up / Sign in
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
