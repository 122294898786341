import displayCodeStar from "../../../../images/displayCodeStar.png";
import { MobileView } from "react-device-detect";
import AddMediaCodeLink from "./AddMediaCodeLink";

export default function DormantCodeDisplay({ code }) {
  return (
    <>
      <div className="member-code-display-container">
        <div className="member-code-display-header-container">
          <img
            className="my-upload-display-star"
            src={displayCodeStar}
            alt="entry"
          />
          <div className="my-upload-code"> {code.code}</div>
          <MobileView>
            <AddMediaCodeLink code={code} />
          </MobileView>
        </div>
      </div>
    </>
  );
}
