import { useState, useEffect, useContext, useLayoutEffect } from "react";
import { RenderMediaContext } from "../../../../Store";
import { useTransition, animated } from "react-spring";
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaPlay,
  FaPause,
  FaTimes,
} from "react-icons/fa";

export default function FullScreenControls({
  index,
  nextSlide,
  prevSlide,
  autoPlay,
  setAutoPlay,
  setShow,
}) {
  const [renderMedia] = useContext(RenderMediaContext);
  const [showControls, setShowControls] = useState(true);

  useEffect(() => {
    const controlsInterval = setInterval(() => {
      if (showControls) {
        setShowControls(false);
      }
    }, 3000);
    return () => {
      clearInterval(controlsInterval);
    };
  });

  useLayoutEffect(() => {
    const element = document.getElementById("desktop-full-screen");
    element.addEventListener("mousemove", handleMouseMove);
    return () => element.removeEventListener("mousemove", handleMouseMove);
  });

  const handleMouseMove = () => {
    setShowControls(true);
  };

  const controlsTransition = useTransition(showControls, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const handleNextSlide = () => {
    setAutoPlay(false);
    nextSlide();
  };

  const handlePrevSlide = () => {
    setAutoPlay(false);
    prevSlide();
  };

  const Controls = () => {
    return (
      <div className="desktop-full-screen-controls-container">
        <div className="desktop-full-screen-icons-container">
          {index + 1}/{renderMedia.media.length}
          <FaArrowAltCircleLeft
            className="desktop-full-screen-control-icon"
            onClick={handlePrevSlide}
          />
          {autoPlay ? (
            <FaPause
              className="desktop-full-screen-control-icon"
              onClick={() => setAutoPlay(!autoPlay)}
            />
          ) : (
            <FaPlay
              className="desktop-full-screen-control-icon"
              onClick={() => setAutoPlay(!autoPlay)}
            />
          )}
          <FaArrowAltCircleRight
            className="desktop-full-screen-control-icon"
            onClick={handleNextSlide}
          />
          <FaTimes
            className="desktop-full-screen-control-icon"
            onClick={() => setShow(false)}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      {controlsTransition((style, item) =>
        item ? (
          <animated.div style={style}>
            <Controls />
          </animated.div>
        ) : (
          ""
        )
      )}
    </div>
  );
}
