import SingleMediaImage from "./SingleMediaImage";
import SingleMediaVideo from "./SingleMediaVideo";
import Navbar from "../../Navbar/Navbar";
import AppFooter from "../../AppFooter/AppFooter";
import UnauthenticatedImage from "../UnauthenticatedImage";

export default function SingleMedia({ media, authenticated }) {
  const getMedia = () => {
    if (authenticated) {
      return (
        <>
          {media.mimetype === "image" && (
            <SingleMediaImage item={media} authenticated={authenticated} />
          )}
          {media.mimetype !== "image" && (
            <SingleMediaVideo item={media} authenticated={authenticated} />
          )}
        </>
      );
    } else {
      return <UnauthenticatedImage index={0} authenticated={authenticated} />;
    }
  };
  return (
    <>
      <Navbar />
      <div className="desktop-render-single-media-container">{getMedia()}</div>
      <AppFooter />
    </>
  );
}
