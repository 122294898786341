import { useContext, useState } from "react";
import { UserContext } from "../../../Store";
import { Modal, Box } from "@mui/material";
import logo from "../../../images/logo.png";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { useHistory } from "react-router-dom";
import RenderErrorSmall from "../ErrorMessages/RenderErrorSmall";

export default function BecomeMember({
  showBecomeMember,
  setShowBecomeMember,
}) {
  const [user] = useContext(UserContext);
  const [name, setName] = useState();
  const [memberCodes, setMemberCodes] = useState();
  const [reason, setReason] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "260px",
    width: "90%",
    maxHeight: "100%",
    maxWidth: "600px",
    bgcolor: "background.paper",
    border: "1px solid lightgrey",
    borderRadius: "4px",
    boxShadow: 24,
    p: 2,
    overflow: "auto",
  };

  const handleApply = async () => {
    setError();
    if (!name) {
      setError("Please enter your name");
    } else if (!reason) {
      setError("Please enter your reason for applying");
    } else if (!memberCodes) {
      setError("Please enter the member codes you would like");
    } else if (!password) {
      setError("Please enter your password to confirm");
    } else {
      setLoading(true);
      try {
        const response = await axios.post("/api/users/member-apply", {
          name: name,
          reason: reason,
          memberCodes: memberCodes,
          email: user.email,
          password: password,
          userId: user.id,
        });
        if (
          !response.data.success &&
          response.data.msg === "password incorrect"
        ) {
          setError("Password incorrect, please try again");
          setLoading(false);
        } else if (
          !response.data.success &&
          response.data.msg === "server error"
        ) {
          setError("Sorry, an error occurred. Please try again");
          setLoading(false);
        } else {
          setLoading(false);
          history.push("/users/memberApplication");
        }
      } catch (err) {
        console.log(err.message);
        setError("Sorry, an error occurred. Please try again");
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        open={showBecomeMember}
        onClose={() => setShowBecomeMember(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-header-container">
            <img
              className="overwrite-modal-header-logo"
              src={logo}
              alt="logo"
            />
          </div>
          <div
            className="overwrite-upload-code-modal-title"
            style={{ marginBottom: "10px" }}
          >
            Apply for membership?
          </div>
          <div className="delete-upload-code-modal-caption">
            <TextField
              variant="outlined"
              fullWidth={true}
              label="Your name"
              onChange={(e) => setName(e.target.value)}
              color="secondary"
              value={name}
              style={{ marginTop: "10px" }}
            />
          </div>
          <div className="delete-upload-code-modal-caption">
            <TextField
              variant="outlined"
              label="Reason for applying"
              fullWidth={true}
              onChange={(e) => setReason(e.target.value)}
              color="secondary"
              multiline
              value={reason}
              minRows={3}
              maxRows={3}
              style={{ marginTop: "10px" }}
            />
          </div>
          <div className="delete-upload-code-modal-caption">
            <TextField
              variant="outlined"
              label="What member codes would you like?"
              fullWidth={true}
              onChange={(e) => setMemberCodes(e.target.value)}
              color="secondary"
              value={memberCodes}
              style={{ marginTop: "10px" }}
            />
          </div>
          <div className="delete-upload-code-modal-caption">
            <TextField
              variant="outlined"
              fullWidth={true}
              label="Your password"
              onChange={(e) => setPassword(e.target.value)}
              color="secondary"
              type={showPassword ? "text" : "password"}
              value={password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {showPassword ? (
                      <VisibilityOff
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <Visibility
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              style={{ marginTop: "10px" }}
            />
          </div>
          {loading && (
            <div className="delete-account-loading-container">
              <div className="loading-component"></div>
            </div>
          )}
          {error && !loading && (
            <div className="user-account-delete-error-container">
              <RenderErrorSmall error={error} />
            </div>
          )}
          <div className="overwrite-upload-code-modal-footer">
            {!loading && (
              <>
                <button
                  className="user-account-delete-cancel-button"
                  onClick={() => setShowBecomeMember(false)}
                >
                  Cancel
                </button>
                <button
                  onClick={handleApply}
                  className="become-member-apply-button"
                >
                  Apply
                </button>
              </>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}
