import { useState, useContext, useEffect } from "react";
import { RenderMediaContext } from "../../../Store";
import axios from 'axios';

export default function FullScreenImage({ index, authenticated }) {
  const [renderMedia] = useContext(RenderMediaContext);
  const [update, setUpdate] = useState(true);

  const item = renderMedia.media[index];

  useEffect(() => {
    getItemUpdate();
    const updateInterval = setInterval(getItemUpdate, 5000);
    return () => clearInterval(updateInterval);
  });

  const getItemUpdate = async () => {
    if (!item.uploaded) {
      const response = await axios.get(
        `/api/codes/single-item/?code=${renderMedia.code}&fileName=${item.key}&authenticated=${authenticated}`
      );
      if (response.data.media.uploaded) {
        renderMedia.media[index] = response.data.media;
        setUpdate(!update);
      }
    }
  };

  return (
    <>
      <img
        className="mobile-carousel-image"
        src={item.url}
        alt=""
        loading="lazy"
      />
    </>
  );
}
