import { useState } from "react";
import MemberCodes from "./MemberCodes";
import MemberDashboardHeader from "./MemberDashboardHeader";

export default function MemberDashboard() {
  const [sortBy, setSortBy] = useState("code");
  const [activeCodes, setActiveCodes] = useState(true);

  return (
    <div className="member-dashboard-container">
      <MemberDashboardHeader setSortBy={setSortBy} />
      <MemberCodes
        activeCodes={activeCodes}
        setActiveCodes={setActiveCodes}
        sortBy={sortBy}
      />
    </div>
  );
}
