import { useEffect } from "react";

export default function MembershipPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="info-page-container">
        <div className="about-us-heading">Membership</div>
        <div className="about-us-paragraph">
          tkr members can reserve their own codes for personal use and can also
          upload media without an expiry date. If you wish to become a member
          please contact us.
        </div>
      </div>
    </>
  );
}
