import { useState } from "react";
import { Link, useHistory } from "react-router-dom";

export default function GetMediaBox() {
  const [code, setCode] = useState();

  const history = useHistory();

  const handleSubmit = () => {
    history.push(`/${code}`);
  };

  return (
    <>
      <div className="desktop-get-media-box-container">
        <div className="desktop-get-media-box">
          <div className="desktop-get-media-caption">Got a code?</div>
          <form className="get-media-form-container" onSubmit={handleSubmit}>
            <input
              className="get-media-input-box"
              type="text"
              placeholder="Enter your code"
              required
              onChange={(e) => setCode(e.target.value.toLowerCase())}
            ></input>
          </form>
          <Link to={`/${code}`}>
            <button
              className={
                code
                  ? "desktop-get-media-button desktop-get-media-button-active"
                  : "desktop-get-media-button"
              }
              type="submit"
              disabled={code ? false : true}
            >
              Get media
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
