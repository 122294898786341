import { useState, useContext } from "react";
import axios from "axios";
import PasswordValidationList from "../../Universal/LoginComponents/PasswordValidationList";
import Validate from "../../../utils/validation";
import LoginError from "./LoginError";
import ShowTerms from "./ShowTerms";
import fontLogo from "../../../images/fontLogo.png";
import { DesktopRegisterCodeContext } from "../../../Store";

export default function RegisterForm({ setRegister }) {
  const [error, setError] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [acceptTCs, setAcceptTCs] = useState(false);
  const [passwordActive, setPasswordActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const [registerCode, setRegisterCode] = useContext(DesktopRegisterCodeContext);

  const submitValidation = () => {
    if (!email) {
      setError("Please enter an email address");
    } else if (!Validate.loginEmail(email.trim())) {
      setError("Please enter a valid email address");
    } else if (!password) {
      setError("Please choose a password");
    } else if (
      !Validate.passwordLength(password) ||
      !Validate.passwordUppercase(password) ||
      !Validate.passwordNumber(password)
    ) {
      setError("Sorry... please choose a stronger password");
    } else if (!acceptTCs) {
      setError("Please accept the Terms and Conditions");
    } else {
      setLoading(true);
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post("/api/users/register", {
        name: name.trim(),
        email: email.trim(),
        password: password,
        code: registerCode,
      });
      if (!response.data.success && response.data.msg === "user exists") {
        setError(
          <div>
            You have already registered...{" "}
            <span
              className="register-from-error-sign-in"
              onClick={() => setRegister(false)}
            >
              sign in here
            </span>
          </div>
        );
        setLoading(false);
      } else if (!response.data.success && response.data.msg === "error") {
        setError("Sorry, there was an error... Please try again");
      } else {
        setSuccess(true);
        setRegisterCode()
      }
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setError("Sorry, there was an error... Please try again");
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <>
        <div className="desktop-login-modal-loading-container">
          <div className="loading-component"></div>
          <div className="desktop-login-modal-loading-caption">Loading...</div>
        </div>
      </>
    );
  }

  const RegisterHeader = () => {
    return (
      <div className="desktop-login-modal-header-container">
        <div className="desktop-login-modal-heading">Create an account</div>
        <div className="desktop-login-modal-sub-heading">
          Already have an account?{" "}
          <span
            className="desktop-login-modal-register-switch"
            onClick={() => setRegister(false)}
          >
            Sign in
          </span>
        </div>
      </div>
    );
  };

  if (success) {
    return (
      <>
        <div className="registration-page-success-container">
          <div className="login-page-subtitle-success">
            Almost done... <br />
            <br />
            We've sent a verification email to {email}. Please follow the
            instructions in the email to complete your registration. The
            activation link will expire in twenty minutes.
            <br />
            <br />
            Thank you,
          </div>
          <div className="login-page-signature-container">
            <img className="login-page-signature-logo" src={fontLogo} alt="" />
          </div>
        </div>
      </>
    );
  }

  if (showTerms) {
    return (
      <ShowTerms
        setShowTerms={setShowTerms}
        acceptTCs={acceptTCs}
        setAcceptTCs={setAcceptTCs}
      />
    );
  }

  return (
    <>
      <RegisterHeader />
      <div className="login-page-login-form">
        <div className="login-page-heading">
          Name: <span style={{ color: "#999" }}>(optional)</span>
        </div>
        <input
          className="login-page-text-input"
          type="text"
          onChange={(e) => setName(e.target.value)}
        />
        <div className="login-page-heading">Email Address:</div>
        <input
          className="login-page-text-input"
          type="text"
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
        />
        <div className="login-page-heading">Password:</div>
        <div className="registration-password-input-container">
          <input
            className="registration-page-password-input"
            type={showPassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            onClick={() => setPasswordActive(true)}
          />
          <input
            className="registration-show-password-button"
            type="text"
            value={showPassword ? "Hide" : "Show"}
            readOnly
            onClick={() => setShowPassword(!showPassword)}
          />
        </div>
        <PasswordValidationList password={password} active={passwordActive} />
        <div className="register-terms-and-conditions">
          <input
            type="checkbox"
            id="ts-and-cs"
            onChange={(e) => setAcceptTCs(e.target.checked)}
            className="register-page-persist-checkbox"
            checked={acceptTCs}
          />
          <label htmlFor="ts-and-cs">I accept the&nbsp;</label>
          <span
            onClick={() => setShowTerms(true)}
            style={{ color: "#00157f", cursor: "pointer" }}
          >
            Terms and Conditions
          </span>
        </div>
        {error && <LoginError error={error} closeError={() => setError()} />}
        <button className="login-page-submit-button" onClick={submitValidation}>
          Create an account
        </button>
      </div>
    </>
  );
}
