import { useState } from "react";
import TextField from "@mui/material/TextField";
import axios from "axios";
import RenderErrorSmall from "../ErrorMessages/RenderErrorSmall";
import Validate from "../../../utils/validation";
import ResetSuccess from './ResetSuccess';

export default function ResetPasswordPage({ expired }) {
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    if (!email) {
      setError("Please enter your email address");
    } else if (!Validate.loginEmail(email.trim())) {
      setError("Please enter a valid email address");
    } else {
      try {
        setLoading(true);
        const response = await axios.put("/api/users/forgot-password", {
          email: email.trim(),
        });
        if (!response.data.success && response.data.msg === "server error") {
          setError("Sorry, an error occurred. Please try again");
          setLoading(false);
        } else {
          setLoading(false);
          setSuccess(true);
          setError();
        }
      } catch (err) {
        console.log(err.message);
        setError("Sorry, an error occurred. Please try again");
        setLoading(false);
      }
    }
  };

  if (loading) {
    return (
      <div className="new-password-page-loading-container">
        <div className="loading-component"></div>
      </div>
    );
  }

  if (success) {
    return <ResetSuccess email={email} setSuccess={setSuccess}/>
  }

  return (
    <>
      <div className="reset-password-page-container">
        <div className="reset-password-page-heading">
          {expired
            ? "This link has expired."
            : "Forgot your password?"}
        </div>
        <div className="reset-password-page-caption">
          To reset your password, enter the email address used to create your
          account. We'll send you instructions on how to create a new password.
        </div>
        <div className="reset-password-page-textfield">
          <TextField
            variant="outlined"
            fullWidth={true}
            label="Email"
            onChange={(e) => setEmail(e.target.value)}
            color="secondary"
            value={email}
          />
        </div>
        {error && !loading && (
          <div className="reset-password-page-error-container">
            <RenderErrorSmall error={error} />
          </div>
        )}
        <button
          className="reset-password-page-request-button"
          onClick={handleSubmit}
        >
          Request new password
        </button>
      </div>
    </>
  );
}
