import { useEffect, useState, useContext } from "react";
import { UserContext, LoadingUserContext } from "../../../Store";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import PasswordValidationList from "../../Universal/LoginComponents/PasswordValidationList";
import Validate from "../../../utils/validation";
import RenderError from "../ErrorMessages/RenderError";
import logo from "../../../images/fontLogo.png";
import TermsAndConditionsModal from "./TermsAndConditionsModal";

export default function LoginPage() {
  const [user] = useContext(UserContext);
  const [loadingUser] = useContext(LoadingUserContext);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordActive, setPasswordActive] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [acceptTCs, setAcceptTCs] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  const { code } = useParams();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!loadingUser && user) {
      history.push("/users");
    }
  }, [user, loadingUser]);

  const submitValidation = () => {
    if (!email) {
      setError("Please enter an email address");
    } else if (!Validate.loginEmail(email.trim())) {
      setError("Please enter a valid email address");
    } else if (!password) {
      setError("Please choose a password");
    } else if (
      !Validate.passwordLength(password) ||
      !Validate.passwordUppercase(password) ||
      !Validate.passwordNumber(password)
    ) {
      setError("Sorry... please choose a stronger password");
    } else if (!acceptTCs) {
      setError("Please accept the Terms and Conditions");
    } else {
      setLoading(true);
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post("/api/users/register", {
        name: name.trim(),
        email: email.trim(),
        password: password,
        code: code,
      });
      if (!response.data.success && response.data.msg === "user exists") {
        setError(
          <div>
            You have already registered...{" "}
            <Link to="/users/login" style={{ textDecoration: "none" }}>
              <span className="login-page-register-change">Sign in here</span>
            </Link>
          </div>
        );
        resetState();
      } else if (!response.data.success) {
        setError("Sorry, there was an error... Please try again");
        resetState();
      } else {
        setSuccess(true);
      }
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setError("Sorry, there was an error... Please try again");
      setLoading(false);
      resetState();
    }
  };

  const resetState = () => {
    setName("");
    setEmail();
    setPassword();
    setPasswordActive(false);
    setShowPassword(false);
    setAcceptTCs(false);
  };

  const closeError = () => {
    setError();
  };

  const RegisterFormHeader = () => {
    return (
      <>
        <div className="login-page-subtitle">Create an account</div>
        <div className="login-page-info">
          Already have an account?{" "}
          <Link to="/users/login" style={{ textDecoration: "none" }}>
            <span className="login-page-register-change">Sign in</span>
          </Link>
        </div>
      </>
    );
  };

  const getRegisterForm = () => {
    return (
      <>
        <div className="login-page-login-form">
          <div className="login-page-heading">
            Name: <span style={{ color: "#999" }}>(optional)</span>
          </div>
          <input
            className="login-page-text-input"
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <div className="login-page-heading">Email Address:</div>
          <input
            className="login-page-text-input"
            type="text"
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
          />
          <div className="login-page-heading">Password:</div>
          <div className="registration-password-input-container">
            <input
              className="registration-page-password-input"
              type={showPassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              onClick={() => setPasswordActive(true)}
            />
            <input
              className="registration-show-password-button"
              type="text"
              value={showPassword ? "Hide" : "Show"}
              readOnly
              onClick={() => setShowPassword(!showPassword)}
            />
          </div>
          <PasswordValidationList password={password} active={passwordActive} />
          <div className="register-terms-and-conditions">
            <input
              type="checkbox"
              id="ts-and-cs"
              onChange={(e) => setAcceptTCs(e.target.checked)}
              className="register-page-persist-checkbox"
            />
            <label htmlFor="ts-and-cs">I accept the&nbsp;</label>
            <span
              onClick={() => setShowTermsModal(true)}
              style={{ color: "#00157f" }}
            >
              Terms and Conditions
            </span>
          </div>
          {error && <RenderError error={error} closeError={closeError} />}
          <button
            className="login-page-submit-button"
            onClick={submitValidation}
          >
            Create an account
          </button>
        </div>
      </>
    );
  };

  if (loading) {
    return (
      <div className="new-password-page-loading-container">
        <div className="loading-component"></div>
      </div>
    );
  }

  if (success) {
    return (
      <div className="user-area-top-container">
        <div className="user-area-container">
          <div className="registration-page-success-container">
            <div className="login-page-subtitle-success">
              Almost done... <br />
              <br />
              We've sent a verification email to {email}. Please follow the
              instructions in the email to complete your registration. The
              activation link will expire in twenty minutes.
              <br />
              <br />
              Thank you,
            </div>
            <div className="login-page-signature-container">
              <img className="login-page-signature-logo" src={logo} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="user-area-top-container">
        <div className="user-area-container">
          {showTermsModal && (
            <TermsAndConditionsModal setShowTermsModal={setShowTermsModal} />
          )}
          <RegisterFormHeader />
          {getRegisterForm()}
        </div>
      </div>
    </>
  );
}
