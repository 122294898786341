import logo from "../../../images/logo.png";
import { Link } from "react-router-dom";

export default function BlankNavbar() {
  return (
    <>
      <div className="navbar-container">
        <div style={{ width: "42px" }}></div>
        <Link to="/">
          <img className="navbar-logo" src={logo} alt="tkr-login" />
        </Link>
        <div style={{ width: "42px" }}></div>
      </div>
    </>
  );
}
