import { useState, useEffect, useContext } from "react";
import { RenderMediaContext } from "../../../Store";
import axios from "axios";
import BounceLoader from "react-spinners/BounceLoader";

export default function RenderImage({ index, handleClick, authenticated }) {
  const [renderMedia] = useContext(RenderMediaContext);
  const [update, setUpdate] = useState(true);

  const item = renderMedia.media[index];

  useEffect(() => {
    getItemUpdate();
    const updateInterval = setInterval(getItemUpdate, 5000);
    return () => clearInterval(updateInterval);
  });

  const getItemUpdate = async () => {
    if (!item.uploaded) {
      const response = await axios.get(
        `/api/codes/single-item/?code=${renderMedia.code}&fileName=${item.key}&authenticated=${authenticated}`
      );
      if (response.data.media.uploaded) {
        renderMedia.media[index] = response.data.media;
        setUpdate(!update);
      }
    }
  };

  if (!item.uploaded) {
    return (
      <div className="desktop-grid-image-loading-container">
        <div className="desktop-grid-view-loading-caption">
          This {item.mimetype === "image" ? "image" : "video "} is processing
          and will be available soon...
        </div>
        <BounceLoader color="#00deb7" loading size={30} />
      </div>
    );
  }

  return (
    <img
      className="desktop-grid-view-image"
      src={item.url}
      alt=""
      onClick={() => handleClick(index)}
      loading="lazy"
    />
  );
}
