import errorIcon from "../../../images/errorIcon2.png";
import { IoClose } from "react-icons/io5";
import { IconContext } from "react-icons";

export default function ErrorMessage({ error, setError }) {
  return (
    <div className="desktop-upload-error-message">
      <img
        className="desktop-upload-error-icon"
        src={errorIcon}
        alt="error message"
      />
      <div className="desktop-upload-error-caption">{error}</div>
      <IconContext.Provider
        value={{ color: "#999" }}
      >
        <div style={{ cursor: "pointer", padding: "2px"}}>
          <IoClose size={32} onClick={() => setError()}/>
        </div>
      </IconContext.Provider>
    </div>
  );
}
