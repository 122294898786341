import React, { useState, useEffect, useContext } from "react";
import { RenderMediaContext } from "../../../Store";
import FullScreenCarousel from "./FullScreenCarousel";
import FullScreenControls from './FullScreenControls';

export default function FullScreenMedia({ exitFullScreen, index, authenticated }) {
  const [renderMedia] = useContext(RenderMediaContext);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setActiveIndex(index);
  }, []);

  const prevSlide = () => {
    if (activeIndex !== 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const nextSlide = () => {
    if (activeIndex < renderMedia.media.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  return (
    <>
      <FullScreenControls
        index={activeIndex}
        prevSlide={prevSlide}
        nextSlide={nextSlide}
        exitFullScreen={exitFullScreen}
      />
      <div className="mobile-render-media-full-screen-container">
        <FullScreenCarousel
          index={activeIndex}
          prevSlide={prevSlide}
          nextSlide={nextSlide}
          authenticated={authenticated}
        />
      </div>
    </>
  );
}
