import { useContext } from "react";
import { UserContext } from "../../../../Store";
import arraySort from "array-sort";

export default function MemberCodesUpload({ memberCode, setMemberCode }) {
  const [user] = useContext(UserContext);

  arraySort(user.memberCodes, "status", {
    reverse: true,
  });

  const getUserCodeOptions = (code) => {
    return (
      <div key={code.code}>
        <div className="member-codes-upload-option-container">
          <div
            className={
              memberCode === code.code
                ? "upload-code-option upload-code-option-active"
                : "upload-code-option"
            }
            onClick={() => setMemberCode(code.code)}
          ></div>
          <div className="code-selection-code">{code.code}</div>
          {code.status === "dormant" ? (
            <>
              <div className="code-selection-status">Available</div>
            </>
          ) : (
            <>
              <div className="code-selection-status-active">In use</div>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="member-codes-upload-container">
        <div className="options-form-subtitle">Code:</div>
        <div className="member-codes-upload-select-container">
          <div className="member-codes-upload-option-container">
            <div
              className={
                !memberCode ? "upload-code-option upload-code-option-active" : "upload-code-option"
              }
              onClick={() => setMemberCode()}
            ></div>
            <div className="code-selection-code">5 random characters</div>
          </div>
          {user.memberCodes.map((code) => getUserCodeOptions(code))}
        </div>
      </div>
    </>
  );
}
