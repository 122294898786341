import { useState, useContext } from "react";
import { UserContext } from "../../../../Store";
import EmptyListMessage from "../EmptyListMessage";
import arraySort from "array-sort";
import MyUploadDisplay from "./MyUploadDisplay";
import MyViewedDisplay from "./MyViewedDisplay";

export default function MyCodes({ setShowDashboard }) {
  const [user] = useContext(UserContext);
  const [showUploads, setShowUploads] = useState(true);
  const [sortBy, setSortBy] = useState("created");

  const getReverseValue = () => {
    if (sortBy === "code" || sortBy === "expires") {
      return false;
    } else return true;
  };

  arraySort(user.codes, sortBy, {
    reverse: getReverseValue(),
  });

  arraySort(user.viewedCodes, sortBy, {
    reverse: getReverseValue(),
  });

  const UploadCodes = () => {
    if (!user.codes.length) {
      return <EmptyListMessage message={`You have no upload codes saved`} />;
    } else {
      return (
        <div>
          {user.codes.map((code) => (
            <MyUploadDisplay
              code={code}
              key={`uc${code.code}`}
              setShowDashboard={setShowDashboard}
            />
          ))}
        </div>
      );
    }
  };

  const ViewedCodes = () => {
    if (!user.viewedCodes.length) {
      return (
        <EmptyListMessage message={`You have no viewed code records saved`} />
      );
    } else {
      return (
        <div>
          {user.viewedCodes.map((code) => (
            <MyViewedDisplay
              code={code}
              key={`vc${code.code}`}
              setShowDashboard={setShowDashboard}
            />
          ))}
        </div>
      );
    }
  };

  const Header = () => {
    return (
      <div className="desktop-dashboard-section-container">
        <div className="desktop-dashboard-section-heading-container">
          <div className="desktop-dashboard-section-heading">My codes:</div>
          <div className="user-dashboard-heading-sort-container">
            <select
              className="user-dashboard-sort-by-input"
              onChange={(e) => setSortBy(e.target.value)}
              value={sortBy}
            >
              <option value="code">Sort by: Code</option>
              <option value="expires">Sort by: Expiry</option>
              {showUploads && <option value="created">Sort by: Created</option>}
              <option value="viewCount">Sort by: Views</option>
              <option value="lastViewed">Sort by: Last viewed</option>
            </select>
          </div>
        </div>
      </div>
    );
  };

  const SelectionOptions = () => {
    return (
      <>
        <div className="user-dashboard-codes-choice-container">
          <div
            className={
              showUploads
                ? "member-codes-select-button-active"
                : "member-codes-select-button"
            }
            onClick={() => setShowUploads(true)}
          >
            My uploads
          </div>
          <div
            className={
              showUploads
                ? "member-codes-select-button"
                : "member-codes-select-button-active"
            }
            onClick={() => setShowUploads(false)}
          >
            My views
          </div>
        </div>
      </>
    );
  };

  const CodesContent = () => {
    return (
      <div className="desktop-user-dashboard-codes-list-container">
        {showUploads && <UploadCodes />}
        {!showUploads && <ViewedCodes />}
      </div>
    );
  };

  return (
    <>
      <Header />
      <SelectionOptions />
      <CodesContent />
    </>
  );
}
