import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AdminPage from "../Components/Mobile/AdminPage/AdminPage";
import AppFooter from "../Components/Mobile/AppFooter/AppFooter";
import Landing from "../Components/Mobile/Landing/Landing";
import LoginPage from "../Components/Mobile/LoginPage/LoginPage";
import RegisterPage from "../Components/Mobile/LoginPage/RegisterPage";
import BlankNavbar from "../Components/Mobile/Navbar/BlankNavbar";
import Navbar from "../Components/Mobile/Navbar/Navbar";
import LoginNavbar from "../Components/Mobile/Navbar/LoginNavbar";
import RenderMediaPage from "../Components/Mobile/RenderMediaPage/RenderMediaPage";
import UploadPage from "../Components/Mobile/UploadPage/UploadPage";
import ConfirmApplicationPage from "../Components/Mobile/UserAccount/ConfirmApplicationPage";
import ConfirmContactPage from "../Components/Mobile/ContactUsModal/ConfirmContactPage";
import ConfirmDeletedPage from "../Components/Mobile/UserAccount/ConfirmDeletedPage";
import UserAccount from "../Components/Mobile/UserAccount/UserAccount";
import UserArea from "../Components/Mobile/UserArea/UserArea";
import ResetPasswordPage from "../Components/Mobile/RecoverPassword/ResetPasswordPage";
import NewPasswordPage from "../Components/Mobile/RecoverPassword/NewPasswordPage";
import DisplayCodePage from "../Components/Mobile/DisplayCode/DisplayCodePage";
import ActivateAccount from "../Components/Mobile/ActivateAccount/ActivateAccount";
import AboutUsPage from "../Components/Mobile/AboutUs/AboutUs";
import CookiesAndDataPage from "../Components/Mobile/CookiesAndData/CookiesAndData";
import MembershipPage from "../Components/Mobile/Membership/Membership";
import TermsOfUsePage from "../Components/Mobile/TermsOfUse/TermsOfUse";
import ContactUsPage from "../Components/Mobile/ContactUs/ContactUs";
import PrivacyPolicyPage from "../Components/Mobile/TermsOfUse/PrivacyPolicy";

export default function BrowserRouter() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Navbar />
          <Landing />
          <AppFooter />
          {/* <CookieNotice /> */}
        </Route>
        <Route exact path="/upload">
          <Navbar />
          <UploadPage />
        </Route>
        <Route exact path="/upload/displayCode/:code">
          <Navbar />
          <DisplayCodePage />
        </Route>
        <Route path="/upload/member/:userAreaCode">
          <Navbar />
          <UploadPage />
        </Route>
        <Route exact path="/users">
          <LoginNavbar />
          <UserArea />
        </Route>
        <Route exact path="/users/login">
          <LoginNavbar />
          <LoginPage />
        </Route>
        <Route exact path="/users/register">
          <LoginNavbar />
          <RegisterPage />
        </Route>
        <Route exact path="/users/register/:code">
          <LoginNavbar />
          <RegisterPage />
        </Route>
        <Route path="/users/account">
          <LoginNavbar />
          <UserAccount />
        </Route>
        <Route path="/users/deletedAccount">
          <LoginNavbar />
          <ConfirmDeletedPage />
        </Route>
        <Route path="/users/memberApplication">
          <LoginNavbar />
          <ConfirmApplicationPage />
        </Route>
        <Route path="/users/confirmEnquiry">
          <LoginNavbar />
          <ConfirmContactPage />
        </Route>
        <Route path="/users/resetPassword">
          <LoginNavbar />
          <ResetPasswordPage />
        </Route>
        <Route path="/users/activateAccount/:token">
          <LoginNavbar />
          <ActivateAccount />
        </Route>
        <Route path="/users/newPassword/:token">
          <BlankNavbar />
          <NewPasswordPage />
        </Route>
        <Route path="/users/register/:code">
          <LoginNavbar />
          <div className="user-area-container">
            <LoginPage />
          </div>
        </Route>
        {/* <Route exact path="/editUpload/:memberCode">
          <Navbar />
          <EditUpload />
        </Route> */}
        <Route path="/admin">
          <Navbar />
          <AdminPage />
        </Route>
        <Route path="/aboutUs">
          <Navbar />
          <AboutUsPage />
          <AppFooter />
        </Route>
        <Route path="/cookiesAndData">
          <Navbar />
          <CookiesAndDataPage />
          <AppFooter />
        </Route>
        <Route path="/membership">
          <Navbar />
          <MembershipPage />
          <AppFooter />
        </Route>
        <Route path="/termsOfUse">
          <Navbar />
          <TermsOfUsePage />
          <AppFooter />
        </Route>
        <Route path="/contact">
          <Navbar />
          <ContactUsPage />
          <AppFooter />
        </Route>
        <Route path="/privacyPolicy">
          <Navbar />
          <PrivacyPolicyPage />
          <AppFooter />
        </Route>
        <Route path="/:id">
          <RenderMediaPage />
        </Route>
      </Switch>
    </Router>
  );
}
