import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../../Store";
import ExpiryOptions from "./ExpiryOptions";
import AcceptTCs from "./AcceptTCs";
import MemberCodes from "./MemberCodes";
import PasswordOption from "./PasswordOption";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

export default function UploadOptions({ submit, error, setError }) {
  const [user] = useContext(UserContext);
  const [memberCode, setMemberCode] = useState();
  const [expiryOption, setExpiryOption] = useState(2);
  const [acceptTCs, setAcceptTCs] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [usePassword, setUsePassword] = useState(false);
  const [accessPassword, setAccessPassword] = useState();

  const handleTCsClick = (e) => {
    setAcceptTCs(e.target.checked);
    if (error === "Please accept the terms and conditions") {
      setError();
    }
  };

  const handleSubmitClick = () => {
    submit(memberCode, expiryOption, usePassword, accessPassword, acceptTCs);
  };

  return (
    <>
      {showConfirmModal && (
        <ConfirmDeleteModal
          setShowConfirmModal={setShowConfirmModal}
          memberCode={memberCode}
        />
      )}
      <MemberCodes
        memberCode={memberCode}
        setMemberCode={setMemberCode}
        setError={setError}
      />
      <ExpiryOptions
        expiryOption={expiryOption}
        setExpiryOption={setExpiryOption}
        error={error}
        setError={setError}
        memberCode={memberCode}
      />
      <PasswordOption
        usePassword={usePassword}
        setUsePassword={setUsePassword}
        password={accessPassword}
        setPassword={setAccessPassword}
      />
      <AcceptTCs
        acceptTCs={acceptTCs}
        setAcceptTCs={setAcceptTCs}
        handleClick={handleTCsClick}
        showTermsModal={showTermsModal}
        setShowTermsModal={setShowTermsModal}
      />
      <div className="desktop-upload-files-submit">
        <button
          className="desktop-upload-submit-button"
          onClick={handleSubmitClick}
        >
          Submit
        </button>
      </div>
    </>
  );
}
