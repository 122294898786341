import { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";

export default function FullScreenVideo({ item, autoPlay, nextSlide }) {
  const [videoLoading, setVideoLoading] = useState(true);
  const [showBlack, setShowBlack] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowBlack(false);
    }, 300);
    const video = document.getElementById(`fsVideo${item.url}`);
    if (video) {
      video.addEventListener("loadeddata", handleVideoLoad);
      video.addEventListener("ended", handleVideoEnd);
    }
    return () => {
      if (video) {
        video.removeEventListener("loadeddata", handleVideoLoad);
        video.removeEventListener("ended", handleVideoEnd);
      }
    };
  }, [item]);

  const handleVideoLoad = () => {
    setTimeout(() => {
      setVideoLoading(false);
    }, 300);
  };

  const handleVideoEnd = () => {
    if (autoPlay) {
      nextSlide();
    }
  };

  const getVideoClassName = () => {
    if (!videoLoading && !showBlack) {
      return "desktop-full-screen-video";
    }
    return "desktop-full-screen-video main-media-carousel-hidden";
  };

  return (
    <>
      {videoLoading && !showBlack && (
        <div className="desktop-full-screen-loading-container">
          <div className="desktop-grid-view-loading-caption">
            Video loading...
          </div>
          <ClipLoader color="#00deb7" loading size={30} />
        </div>
      )}
      <video
        controls
        className={getVideoClassName()}
        src={item.url}
        id={`fsVideo${item.url}`}
        autoPlay={autoPlay}
        poster={item.posterUrl}
      >
        <source src={item.url} type="video/mp4" />
        <source src={item.url} type="video/ogg" />
        <source src={item.url} type="video/webm" />
        <source src={item.url} type="video/mov" />
        Your browser does not support the video tag.
      </video>
    </>
  );
}
