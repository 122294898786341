import { useContext, useState } from "react";
import { UserContext } from "../../../../Store";
import greenPlus from "../../../../images/greenPlus.png";
import UserPromptModal from "./UserPromptModal";

export default function FilesInput({ selectFiles }) {
  const [showUserPrompt, setShowUserPrompt] = useState(false);
  const [user] = useContext(UserContext);

  const handleAddFilesClick = () => {
    if (!user) {
      setShowUserPrompt(true);
    }
  };

  return (
    <>
      {showUserPrompt && (
        <UserPromptModal
          setShowUserPrompt={setShowUserPrompt}
          selectFiles={selectFiles}
        />
      )}
      <div className="desktop-files-input-container">
        <label htmlFor="desktop-file-input">
          <div className="desktop-files-upload-input">
            <div className="desktop-files-add-icon-container">
              <img
                src={greenPlus}
                className="desktop-files-green-plus"
                alt="add files"
                onClick={handleAddFilesClick}
              />
            </div>
            <div className="desktop-files-input-caption">Upload your files</div>
          </div>
          {user && (
            <input
              id="desktop-file-input"
              onChange={selectFiles}
              type="file"
              accept="image/*, video/*"
              multiple
            />
          )}
        </label>
      </div>
    </>
  );
}
