import { useState, useEffect } from "react";
import videoThumb from "../../../../images/videoThumb.png";

export default function ThumbnailImage({
  item,
  index,
  setIndex,
  authenticated,
}) {
  const [error, setError] = useState(false);

  useEffect(() => {
    const updateInterval = setInterval(() => {
      if (error) {
        setError(false);
      }
    }, 30000);
    return () => clearInterval(updateInterval);
  });

  if (error) {
    return (
      <div className="thumbnail-carousel-error-placeholder">
        Available soon...
      </div>
    );
  }

  return (
    <>
      <img
        className="desktop-render-thumbnail-image"
        src={authenticated ? item.thumbUrl : item.url}
        alt=""
        onClick={() => setIndex(index)}
        onError={() => setError(true)}
      />
      {item.mimetype === "video" && (
        <div className="thumbnail-overlay">
          <img src={videoThumb} onClick={() => setIndex(index)} alt="" />
        </div>
      )}
    </>
  );
}
