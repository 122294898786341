import greenPlus from "../../../../images/greenPlus.png";

export default function AddFilesButton({ selectFiles }) {
  return (
    <div className="add-files-main-container">
      <label htmlFor="file-input">
        <div className="add-media-button-container">
          <img className="add-media-icon" src={greenPlus} alt="add files" />

          <div className="add-media-instruction">
            <div>Upload your media</div>
            <div className="add-media-upload-limit">Up to 1 GB</div>
          </div>
        </div>
      </label>
      <input
        id="file-input"
        onChange={selectFiles}
        type="file"
        accept="image/*, video/*"
        multiple
      />
    </div>
  );
}
