import { useState, useEffect, useContext } from "react";
import { RenderMediaContext } from "../../../Store";
import axios from "axios";
import MiniAppFooter from "../AppFooter/MiniAppFooter";
import BounceLoader from "react-spinners/BounceLoader";
import { Player, BigPlayButton, LoadingSpinner } from "video-react";

export default function SingleMedia({ handleClick, authenticated }) {
  const [renderMedia] = useContext(RenderMediaContext);
  const [update, setUpdate] = useState(true);

  const item = renderMedia.media[0];

  useEffect(() => {
    getItemUpdate();
    const updateInterval = setInterval(getItemUpdate, 5000);
    return () => clearInterval(updateInterval);
  });

  const getItemUpdate = async () => {
    if (!item.uploaded && authenticated) {
      const response = await axios.get(
        `/api/codes/single-item/?code=${renderMedia.code}&fileName=${item.key}`
      );
      if (response.data.media.uploaded) {
        renderMedia.media[0] = response.data.media;
        setUpdate(!update);
      }
    }
  };

  const getMedia = () => {
    if (authenticated) {
      if (item.mimetype === "image") {
        return (
          <img
            className="mobile-single-media-image"
            src={item.url}
            alt=""
            onClick={() => handleClick(0)}
            loading="lazy"
          />
        );
      }
      if (item.mimetype === "video") {
        return (
          <>
            <Player
              playsInline
              poster={item.posterUrl}
              src={item.url}
              id={`video${item.url}`}
              className="mobile-render-media-video"
            >
              <BigPlayButton position="center" />
              <LoadingSpinner />
            </Player>
          </>
        );
      }
    } else {
      return (
        <img
          className="mobile-single-media-image"
          src={item.url}
          alt=""
          onClick={() => handleClick(0)}
          loading="lazy"
        />
      );
    }
    return null;
  };

  if (!item.uploaded) {
    return (
      <div className="desktop-grid-image-loading-container">
        <div className="desktop-grid-view-loading-caption">
          This {item.mimetype === "image" ? "image" : "video "} is processing
          and will be available soon...
        </div>
        <BounceLoader color="#00deb7" loading size={30} />
      </div>
    );
  }

  return (
    <>
      <div className="mobile-render-single-media-container">{getMedia()}</div>
      <MiniAppFooter />
    </>
  );
}
