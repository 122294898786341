import { useContext, useEffect } from "react";
import { UserContext, LoadingUserContext } from "./Store";
import axios from "axios";

function App() {
  const [, setLoadingUser] = useContext(LoadingUserContext);
  const [, setUser] = useContext(UserContext);
  useEffect(() => {
    initialLogin();
  }, []); 

  const initialLogin = async () => {
    try {
      const response = await axios.get(`/api/users/get-user`);
      if (response) {
        setUser(response.data.user);
      }
      setLoadingUser(false);
    } catch (err) {
      console.log(err.message);
      setLoadingUser(false);
    }
  };

  return null;
}

export default App;
