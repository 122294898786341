import { useEffect } from "react";

export default function ContactUsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="info-page-container">
        <div className="about-us-heading">Contact Us</div>
        <div className="about-us-paragraph">
          For any enquiries, please email enquiries@tkr.com.
        </div>
      </div>
    </>
  );
}
