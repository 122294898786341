import { Link } from "react-router-dom";
import blueGreenPlus from "../../../../images/greenPlus.png";

export default function AddMediaCodeLink({ code }) {
  return (
    <>
      <Link
        className="user-dashboard-add-media-link"
        to={`/upload/member/${code.code}`}
        style={{ textDecoration: "none", color: "black" }}
      >
        <img className="add-media-code-link-icon" src={blueGreenPlus} />
      </Link>
    </>
  );
}
