import { useState, useContext } from "react";
import { UserContext } from "../../../../Store";
import EmptyListMessage from "../EmptyListMessage";
import MemberCodeDisplay from "./MemberCodeDisplay";
import DormantCodeDisplay from "./DormantCodeDisplay";
import arraySort from "array-sort";

export default function MemberCodes({ setShowDashboard }) {
  const [user] = useContext(UserContext);
  const [showActive, setShowActive] = useState(true);
  const [sortBy, setSortBy] = useState("created");

  const getReverseValue = () => {
    if (sortBy === "code" || sortBy === "expires") {
      return false;
    } else return true;
  };

  arraySort(user.memberCodes, sortBy, {
    reverse: getReverseValue(),
  });

  const getCodesArray = () => {
    if (showActive) {
      return user.memberCodes.filter((code) => code.status === "active");
    } else {
      return user.memberCodes.filter((code) => code.status === "dormant");
    }
  };

  const codesArray = getCodesArray();

  const ActiveCodes = () => {
    if (!codesArray.length) {
      return (
        <EmptyListMessage
          message={`You are not using any of your member codes`}
        />
      );
    } else {
      return (
        <div className="user-dashboard-member-codes-list-container">
          {codesArray.map((code) => (
            <MemberCodeDisplay
              code={code}
              key={code.code}
              setShowDashboard={setShowDashboard}
            />
          ))}
        </div>
      );
    }
  };

  const DormantCodes = () => {
    if (!codesArray.length) {
      return (
        <EmptyListMessage message={`You are using all of your member codes`} />
      );
    } else {
      return (
        <div className="user-dashboard-member-codes-list-container">
          {codesArray.map((code) => (
            <DormantCodeDisplay code={code} key={code.code} />
          ))}
        </div>
      );
    }
  };

  const Header = () => {
    return (
      <div className="desktop-dashboard-section-container">
        <div className="desktop-dashboard-section-heading-container">
          <div className="desktop-dashboard-section-heading">
            My member codes:
          </div>
          <div className="user-dashboard-heading-sort-container">
            <select
              className="user-dashboard-sort-by-input"
              onChange={(e) => setSortBy(e.target.value)}
              value={sortBy}
            >
              <option value="code">Sort by: Code</option>
              {showActive && <option value="expires">Sort by: Expiry</option>}
              {showActive && <option value="created">Sort by: Created</option>}
              {showActive && <option value="viewCount">Sort by: Views</option>}
              {showActive && (
                <option value="lastViewed">Sort by: Last viewed</option>
              )}
            </select>
          </div>
        </div>
      </div>
    );
  };

  const SelectionOptions = () => {
    return (
      <>
        <div className="user-dashboard-codes-choice-container">
          <div
            className={
              showActive
                ? "member-codes-select-button-active"
                : "member-codes-select-button"
            }
            onClick={() => setShowActive(true)}
          >
            Active
          </div>
          <div
            className={
              showActive
                ? "member-codes-select-button"
                : "member-codes-select-button-active"
            }
            onClick={() => setShowActive(false)}
          >
            Inactive
          </div>
        </div>
      </>
    );
  };

  const CodesContent = () => {
    return (
      <div className="desktop-user-dashboard-codes-list-container">
        {showActive && <ActiveCodes />}
        {!showActive && <DormantCodes />}
      </div>
    );
  };

  return (
    <>
      <Header />
      <SelectionOptions />
      <CodesContent />
    </>
  );
}
