import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../Store";
import { useParams } from "react-router-dom";
import LoginError from "../LoginModal/LoginError";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import ResetPasswordPage from "./ResetPasswordPage";
import NewPasswordSuccess from "./NewPasswordSuccess";
import PasswordValidationList from "../../Universal/LoginComponents/PasswordValidationList";
import Validate from "../../../utils/validation";

export default function NewPasswordPage() {
  const [loading, setLoading] = useState(true);
  const [invalid, setInvalid] = useState(false);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);

  const { token } = useParams();

  useEffect(() => {
    checkTokenValid();
  }, []);

  const checkTokenValid = async () => {
    try {
      const response = await axios.put("/api/users/check-jwt-valid", {
        token: token,
      });
      setLoading(false);
      if (!response.data.success && response.data.msg !== "jwt valid") {
        setInvalid(true);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleSubmit = async () => {
    if (!password) {
      setError("Please enter a new password");
    } else if (
      !Validate.passwordLength(password) ||
      !Validate.passwordUppercase(password) ||
      !Validate.passwordNumber(password)
    ) {
      setError("Sorry... please choose a stronger password");
    } else if (!confirmPassword) {
      setError("Please retype your password");
    } else if (password !== confirmPassword) {
      setError("Your passwords do not match, please try again");
    } else {
      setLoading(true);
      setError();
      try {
        const response = await axios.put("/api/users/new-password", {
          token: token,
          newPassword: password,
        });
        if (!response.data.success && response.data.msg === "server error") {
          setError("Sorry, an error occurred. Please try again");
          setLoading(false);
        } else if (
          !response.data.success &&
          response.data.msg !== "password updated"
        ) {
          setError("Sorry, an error occurred. Please try again");
          setLoading(false);
        } else {
          setLoading(false);
          setSuccess(true);
        }
      } catch (err) {
        console.log(err.message);
        setError("Sorry, an error occurred. Please try again");
        setLoading(false);
      }
    }
  };

  if (loading) {
    return (
      <div className="new-password-page-loading-container">
        <div className="loading-component"></div>
      </div>
    );
  }

  if (success) {
    return <NewPasswordSuccess />;
  }

  if (invalid) {
    return <ResetPasswordPage expired />;
  }

  return (
    <div className="desktop-reset-password-page-container">
      <div
        className="reset-password-page-caption"
        style={{ paddingBottom: "10px" }}
      >
        Please create a new password for your account:
      </div>
      <div className="delete-upload-code-modal-caption">
        <TextField
          variant="outlined"
          fullWidth={true}
          label="New password"
          onChange={(e) => setPassword(e.target.value)}
          color="secondary"
          type={showPassword ? "text" : "password"}
          value={password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {showPassword ? (
                  <VisibilityOff
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <Visibility onClick={() => setShowPassword(!showPassword)} />
                )}
              </InputAdornment>
            ),
          }}
          style={{ marginTop: "10px" }}
        />
      </div>
      <div className="new-password-password-validation-container">
        <PasswordValidationList password={password} active />
      </div>
      <div className="delete-upload-code-modal-caption">
        <TextField
          variant="outlined"
          fullWidth={true}
          label="Retype new password"
          onChange={(e) => setConfirmPassword(e.target.value)}
          color="secondary"
          type={showConfirmPassword ? "text" : "password"}
          value={confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {showConfirmPassword ? (
                  <VisibilityOff
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                ) : (
                  <Visibility
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                )}
              </InputAdornment>
            ),
          }}
          style={{ marginTop: "10px" }}
        />
      </div>
      {error && !loading && (
        <div className="reset-password-page-error-container">
          <LoginError error={error} closeError={() => setError()} />
        </div>
      )}
      <button
        className="reset-password-page-request-button"
        onClick={handleSubmit}
      >
        Update password
      </button>
    </div>
  );
}
