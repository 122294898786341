import { Link } from "react-router-dom";
import logo from "../../../images/logo.png";
import betaLogo from "../../../images/mobileBeta2.png";
import NavbarButtons from "./NavbarButtons";

export default function Navbar() {
  return (
    <div className="desktop-navbar-container">
      <div>
        <Link to="/">
          <img className="desktop-navbar-logo" src={logo} alt="tkr logo" />
        </Link>
      </div>
      <div className="desktop-navbar-button-container">
        <img
          className="desktop-navbar-beta-logo"
          src={betaLogo}
          alt="tkr logo"
        />
        <NavbarButtons />
      </div>
    </div>
  );
}
