import MiniAppFooter from "../AppFooter/MiniAppFooter";
import RenderImage from "./RenderImage";
import RenderVideo from "./RenderVideo";

export default function MultipleMedia({ media, handleClick, authenticated }) {
  const getColumns = () => {
    if (media.length <= 4) {
      return "mobile-media-render-two-columns";
    } else {
      return "mobile-media-render-three-columns";
    }
  };

  const renderMedia = (item, itemIndex) => {
    if (authenticated) {
      if (item.mimetype === "image") {
        return (
          <RenderImage
            key={itemIndex}
            handleClick={handleClick}
            index={itemIndex}
            authenticated={authenticated}
          />
        );
      } else if (item.mimetype === "video") {
        return (
          <RenderVideo
            index={itemIndex}
            key={itemIndex}
            authenticated={authenticated}
          />
        );
      }
    } else {
      return (
        <RenderImage
          key={itemIndex}
          handleClick={handleClick}
          index={itemIndex}
          authenticated={authenticated}
        />
      );
    }
  };

  return (
    <>
      <div className="mobile-render-media-multiple-container">
        <div className={getColumns()}>
          {media.map((item, itemIndex) => renderMedia(item, itemIndex))}
        </div>
      </div>
      <MiniAppFooter />
    </>
  );
}
