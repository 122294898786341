import { Link } from "react-router-dom";

export default function NewPasswordSuccess() {
  return (
    <div className="reset-password-page-container">
      <div className="reset-password-page-heading">
        You have successfully reset your password
      </div>
      <div className="reset-password-page-caption">
        Your password has been reset, please log in.
      </div>
      <div className="reset-password-page-caption">
        <Link
          to="/"
          className="reset-success-try-again-link"
          style={{ textDecoration: "none" }}
        >
          Continue to tkr
        </Link>
      </div>
    </div>
  );
}
