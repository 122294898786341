import { useState, useContext, useEffect } from "react";
import { RenderMediaContext } from "../../../Store";
import { useSwipeable } from "react-swipeable";
import FullScreenVideo from "./FullScreenVideo";
import FullScreenImage from './FullScreenImage';

export default function FullScreenCarousel({ index, prevSlide, nextSlide, authenticated }) {
  const [renderMedia] = useContext(RenderMediaContext);
  const [orientationChange, setOrientationChange] = useState(true);

  useEffect(() => {
    setTimeout(() => setOrientationChange(false), 500);
  }, []);

  useEffect(() => {
    window.addEventListener("orientationchange", handleOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  });

  const handleOrientationChange = () => {
    setOrientationChange(true);
    setTimeout(() => {
      setOrientationChange(false);
    }, 300);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
  });

  const initialTranslateValue = (renderMedia.media.length / 2) * 100 - 50;
  const translateValue = initialTranslateValue - index * 100;

  const getMedia = (item, itemIndex) => {
    if (item.mimetype === "image") {
      return <FullScreenImage index={itemIndex} authenticated={authenticated}/>
    } else if (item.mimetype === "video") {
      return <FullScreenVideo index={itemIndex} authenticated={authenticated}/>;
    }
  };

  const CarouselItem = ({ item, itemIndex }) => {
    return (
      <div className="mobile-fs-carousel-item-container">{getMedia(item, itemIndex)}</div>
    );
  };

  return (
    <div {...handlers}>
      <div
        className={
          orientationChange
            ? "mobile-fs-carousel-wide-container"
            : "mobile-fs-carousel-wide-container mobile-fs-carousel-wide-container-transform"
        }
        style={{ transform: `translateX(${translateValue}vw)` }}
      >
        {renderMedia.media.map((item, itemIndex) => (
          <CarouselItem item={item} itemIndex={itemIndex} key={itemIndex} />
        ))}
      </div>
    </div>
  );
}
