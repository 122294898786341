import { useContext } from "react";
import { UserContext } from "../../../Store";
import MyUploadDisplay from "./MyUploadDisplay";
import MyViewedDisplay from "./MyViewedDisplay";
import EmptyCodeList from "../EmptyCodeList/EmptyCodeList";
import arraySort from "array-sort";

export default function UserDashboardMyCodes({ sortBy, myUploads, setMyUploads }) {
  const [user] = useContext(UserContext);

  const getReverseValue = () => {
    if (sortBy === "code" || sortBy === "expires") {
      return false;
    } else return true;
  };

  arraySort(user.codes, sortBy, {
    reverse: getReverseValue(),
  });

  arraySort(user.viewedCodes, sortBy, {
    reverse: getReverseValue(),
  });

  const renderViewedCodes = () => {
    if (!user.viewedCodes.length) {
      return (
        <EmptyCodeList message={`You have no viewed code records saved`} />
      );
    } else {
      return (
        <div>
          {user.viewedCodes.map((code) => (
            <MyViewedDisplay code={code} key={code.code} />
          ))}
        </div>
      );
    }
  };

  const renderMyUploadsCodes = () => {
    if (!user.codes.length) {
      return <EmptyCodeList message={`You have no upload codes saved`} />;
    } else {
      return (
        <div>
          {user.codes.map((code) => (
            <MyUploadDisplay code={code} key={code.code} />
          ))}
        </div>
      );
    }
  };

  return (
    <>
      <div className="user-dashboard-codes-choice-container">
        <div
          className={
            myUploads
              ? "user-dashboard-codes-choice-button-active"
              : "user-dashboard-codes-choice-button"
          }
          onClick={() => setMyUploads(true)}
        >
          My uploads
        </div>
        <div
          className={
            myUploads
              ? "user-dashboard-codes-choice-button"
              : "user-dashboard-codes-choice-button-active"
          }
          onClick={() => setMyUploads(false)}
        >
          My views
        </div>
      </div>
      <div className="user-dashboard-codes-render-container">
        {myUploads && renderMyUploadsCodes()}
        {!myUploads && renderViewedCodes()}
      </div>
    </>
  );
}
