import { useState } from "react";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";

export default function PasswordOption({ usePassword, setUsePassword, password, setPassword }) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="desktop-upload-files-options">
      <div className="desktop-upload-subtitle">Add an access password:</div>
      <div className="desktop-upload-options-button-container">
        <button
          className={
            usePassword
              ? "desktop-upload-expiry-button desktop-upload-expiry-button-active"
              : "desktop-upload-expiry-button"
          }
          onClick={() => setUsePassword(true)}
        >
          Yes
        </button>
        <button
          className={
            !usePassword
              ? "desktop-upload-expiry-button desktop-upload-expiry-button-active"
              : "desktop-upload-expiry-button"
          }
          onClick={() => setUsePassword(false)}
        >
          No
        </button>
      </div>
      {usePassword && (
          <div className="upload-options-section-container">
            <div className="upload-access-password-container">
              <TextField
                variant="outlined"
                fullWidth={true}
                label="Access Password"
                onChange={(e) => setPassword(e.target.value)}
                color="primary"
                type={showPassword ? "text" : "password"}
                value={password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {showPassword ? (
                        <VisibilityOff
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <Visibility
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        )}
    </div>
  );
}
