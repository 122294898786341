import compressingIcon from "../../../../images/blueCameraIconWWDThin.png";

export default function PackagingFiles() {
  return (
    <>
      <img
          className="files-uploading-icon"
          src={compressingIcon}
          alt="packaging-files"
        />
        <div className="files-uploading-caption">Packaging media for speedy access... <br/>Please do not close this page</div>
    </>
  );
}
