import { useState, useEffect } from "react";
import Validate from "../../../utils/validation";

export default function PasswordValidationList({ password, active }) {
  const [uppercase, setUppercase] = useState(false);
  const [number, setNumber] = useState(false);
  const [length, setLength] = useState(false);

  useEffect(() => {
    validatePassword();
  });

  const validatePassword = () => {
    if (Validate.passwordLength(password)) {
      setLength(true);
    } else {
      setLength(false);
    }
    if (Validate.passwordUppercase(password)) {
      setUppercase(true);
    } else {
      setUppercase(false);
    }
    if (Validate.passwordNumber(password)) {
      setNumber(true);
    } else {
      setNumber(false);
    }
  };

  if (active) {
    return (
      <div className="password-validation-list-container">
        <ul>
          <li
            className={
              uppercase
                ? "password-validation-list-item-true"
                : "password-validation-list-item-false"
            }
          >
            Uppercase letter
          </li>
          <li
            className={
              number
                ? "password-validation-list-item-true"
                : "password-validation-list-item-false"
            }
          >
            Number
          </li>
          <li
            className={
              length
                ? "password-validation-list-item-true"
                : "password-validation-list-item-false"
            }
          >
            8 characters or more
          </li>
        </ul>
      </div>
    );
  }

  return null;
}
