import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../../Store";
import RenderErrorSmall from "../../ErrorMessages/RenderErrorSmall";
import { Modal, Box } from "@mui/material";
import logo from "../../../../images/logo.png";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";

export default function UploadOptions({ submit, error, memberCode }) {
  const [user] = useContext(UserContext);
  const [expiryOption, setExpiryOption] = useState(2);
  const [expiryError, setExpiryError] = useState();
  const [acceptTCs, setAcceptTCs] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [usePassword, setUsePassword] = useState(false);
  const [showPassword, setShowPassword] = useState();
  const [accessPassword, setAccessPassword] = useState();

  const style = {
    position: "absolute",
    top: "35%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "260px",
    width: "90%",
    maxWidth: "600px",
    bgcolor: "background.paper",
    border: "1px solid lightgrey",
    borderRadius: "4px",
    boxShadow: 24,
    p: 2,
  };

  useEffect(() => {
    if (memberCode) {
      setExpiryError();
    }
  }, [memberCode]);

  const handleSubmit = () => {
    if (usePassword && !accessPassword) {
      setExpiryError(`Please enter an access password or select 'no'`);
      return;
    }
    if (!acceptTCs) {
      setExpiryError("Please accept the terms and conditions");
      return;
    }
    if (!memberCode) {
      if (!expiryOption) {
        setExpiryError(
          "Please select an expiry option or choose a member code"
        );
      } else {
        const date = new Date();
        date.setDate(date.getDate() + expiryOption);
        submit(date, accessPassword);
      }
    } else {
      const foundMemberCode = user.memberCodes.find(
        (code) => code.code === memberCode
      );
      if (foundMemberCode.status !== "active") {
        if (!expiryOption) {
          submit(expiryOption, accessPassword);
        } else {
          const date = new Date();
          date.setDate(date.getDate() + expiryOption);
          submit(date, accessPassword);
        }
      } else {
        setShowConfirmModal(true);
      }
    }
  };

  const handleConfirmModalSubmit = () => {
    setShowConfirmModal(false);
    if (!expiryOption) {
      submit(expiryOption, accessPassword);
    } else {
      const date = new Date();
      date.setDate(date.getDate() + expiryOption);
      submit(date, accessPassword);
    }
  };

  const getUnlimitedClassName = () => {
    if (!memberCode) {
      return "expiry-option e-o-disabled";
    }
    if (expiryOption === false) {
      return "expiry-option e-o-active";
    }
    return "expiry-option";
  };

  const handleLimitedClick = (expiry) => {
    setExpiryOption(expiry);
    setExpiryError();
  };

  const handleUnlimitedClick = () => {
    if (!memberCode) {
      setExpiryError("Please choose a member code for unlimited expiry");
    } else {
      setExpiryOption(false);
      setExpiryError();
    }
  };

  const handlePasswordYesClick = () => {
    setUsePassword(true);
  };

  const handlePasswordNoClick = () => {
    setUsePassword(false);
    setAccessPassword();
  };

  const renderConfirmDeleteModal = () => {
    return (
      <>
        <Modal
          open={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="delete-modal-header-container">
              <img className="overwrite-modal-header-logo" src={logo} alt="" />
            </div>
            <div className="overwrite-upload-code-modal-title">
              Upload to {memberCode}?
            </div>
            <div className="delete-upload-code-modal-caption">
              <span className="delete-upload-modal-code">{memberCode}</span> is
              already in use. Do you wish to overwrite the media saved to this
              code? This cannot be undone.
            </div>
            <div className="overwrite-upload-code-modal-footer">
              <button
                className="overwrite-upload-code-modal-button"
                onClick={() => setShowConfirmModal(false)}
                style={{ backgroundColor: "#999" }}
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmModalSubmit}
                className="overwrite-upload-code-modal-button"
              >
                Continue
              </button>
            </div>
          </Box>
        </Modal>
      </>
    );
  };

  return (
    <>
      {showTermsModal && (
        <TermsAndConditionsModal setShowTermsModal={setShowTermsModal} />
      )}
      {renderConfirmDeleteModal()}
      <div className="upload-options-container">
        <div className="upload-options-section-container">
          <div className="options-form-subtitle">Expiry:</div>
          <div
            className={
              user && user.account !== "free"
                ? "expiry-options-select-container-user"
                : "expiry-options-select-container"
            }
          >
            <div
              className={
                expiryOption === 2
                  ? "expiry-option e-o-active"
                  : "expiry-option"
              }
              onClick={() => handleLimitedClick(2)}
            >
              2 Days
            </div>
            <div
              className={
                expiryOption === 5
                  ? "expiry-option e-o-active"
                  : "expiry-option"
              }
              onClick={() => handleLimitedClick(5)}
            >
              5 Days
            </div>
            {user && user.account !== "free" && (
              <div
                className={getUnlimitedClassName()}
                onClick={handleUnlimitedClick}
              >
                Unlimited
              </div>
            )}
          </div>
        </div>
        <div className="upload-options-section-container">
          <div className="options-form-subtitle">Add an access password?</div>
          <div className="expiry-options-select-container">
            <div
              className={
                usePassword ? "expiry-option e-o-active" : "expiry-option"
              }
              onClick={handlePasswordYesClick}
            >
              Yes
            </div>
            <div
              className={
                !usePassword ? "expiry-option e-o-active" : "expiry-option"
              }
              onClick={handlePasswordNoClick}
            >
              No
            </div>
          </div>
        </div>
        {usePassword && (
          <div className="upload-options-section-container">
            <div className="upload-access-password-container">
              <TextField
                variant="outlined"
                fullWidth={true}
                label="Access Password"
                onChange={(e) => setAccessPassword(e.target.value)}
                color="primary"
                type={showPassword ? "text" : "password"}
                value={accessPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {showPassword ? (
                        <VisibilityOff
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <Visibility
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        )}
        <div className="upload-terms-and-conditions">
          <input
            type="checkbox"
            id="ts-and-cs"
            onChange={(e) => setAcceptTCs(e.target.checked)}
            className="register-page-persist-checkbox"
          />
          <label htmlFor="ts-and-cs">I accept the&nbsp;</label>
          <span
            style={{ color: "#00157f" }}
            onClick={() => setShowTermsModal(true)}
          >
            Terms and Conditions
          </span>
        </div>
        <div className="submit-button-container">
          {expiryError && <RenderErrorSmall error={expiryError} />}
          <button className="upload-submit-button" onClick={handleSubmit}>
            Submit
          </button>
          {error && <RenderErrorSmall error={error} />}
        </div>
      </div>
    </>
  );
}
