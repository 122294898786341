import { useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import Moment from "react-moment";
import RemoveMemberCode from "./RemoveMemberCode";
import UpgradeUserModal from "./UpgradeUserModal";
import Validate from "../../../utils/validation";

export default function AdminUserDisplay({
  user,
  usedCodes,
  setUser,
  setError,
  setLoading,
  userUpdated,
  setUserUpdated,
}) {
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [newCode, setNewCode] = useState();
  const [showAddCode, setShowAddCode] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [codeIndex, setCodeIndex] = useState();
  const [addCodeError, setAddCodeError] = useState();
  const [updateError, setUpdateError] = useState();
  const [showUpgradeModal, setShowUpgradeModal] = useState();

  const updateUser = async () => {
    if (!email) {
      setUpdateError("Please enter an email address");
    } else if (!Validate.loginEmail(email)) {
      setUpdateError("Please enter a valid email address");
    } else {
      setLoading(true);
      setUpdateError();
      try {
        const response = await axios.put("/api/users/update", {
          userId: user._id,
          name: name,
          email: email.toLowerCase(),
        });
        if (!response.data.success) {
          setError("An error occurred, please try again");
          setLoading(false);
        } else {
          const response = await axios.get(`/api/users/${email}`);
          if (!response.data.success) {
            setError("An error occurred, please try again");
            setUser();
            setLoading(false);
          } else if (response.data.success) {
            const user = response.data.user;
            if (response.data.memberCodes) {
              user.memberCodes = response.data.memberCodes;
            } else {
              user.memberCodes = [];
            }
            setUser(user);
            setError();
            setUserUpdated(true);
            setLoading(false);
          } else {
            setError("An error occurred, please try again");
            setUser();
            setLoading(false);
          }
        }
      } catch (err) {
        console.log(err.message);
        setError("An error occurred, please try again.");
        setUser();
        setLoading(false);
      }
    }
  };

  const clearUser = () => {
    setUser();
    setNewCode();
    setName();
    setEmail();
    setShowAddCode(false);
  };

  const handleAddMemberCode = async () => {
    const userMemberCodes = [];
    user.memberCodes.forEach((code) => {
      userMemberCodes.push(code.code);
    });
    if (!newCode) {
      setAddCodeError("Please enter a code");
    } else if (userMemberCodes.includes(newCode.toLowerCase())) {
      setAddCodeError(
        "You already have this code reserved, please choose another"
      );
    } else if (usedCodes.includes(newCode.toLowerCase())) {
      setAddCodeError("Code already reserved, please choose another");
    } else {
      setLoading(true);
      try {
        const response = await axios.put(`/api/memberships/add-member-code`, {
          memberId: user.membershipId,
          newCode: newCode.toLowerCase(),
        });
        if (!response.data.success && response.data.msg === "code in use") {
          setAddCodeError(
            <div>
              {response.data.code.code} is currently in use. Try again after the
              code expires on{" "}
              <Moment format="Do MMM HH:mm">
                {response.data.code.expires}
              </Moment>
              .
            </div>
          );
          setLoading(false);
        } else if (
          !response.data.success &&
          response.data.msg === "server error"
        ) {
          setError("An error occurred, please try again");
          setLoading(false);
        } else {
          const userCopy = JSON.parse(JSON.stringify(user));
          userCopy.memberCodes.push({ code: newCode.toLowerCase(), status: "dormant" });
          setUser(userCopy);
          setShowAddCode(false);
          setNewCode();
          setUserUpdated(true);
          setLoading(false);
        }
      } catch (err) {
        console.log(err.message);
        setError("An error occurred, please try again");
        setLoading(false);
      }
    }
  };

  const removeMemberCodeClick = (codeIndex) => {
    setShowRemoveModal(true);
    setCodeIndex(codeIndex);
  };

  const removeMemberCode = async () => {
    setShowRemoveModal(false);
    setLoading(true);
    try {
      const response = await axios.put("/api/memberships/remove-member-code", {
        memberId: user.membershipId,
        codeToRemove: user.memberCodes[codeIndex].code,
      });
      if (!response.data.success) {
        if (response.data.msg === "authentication error") {
          setError("Authentication error, please try again");
          setLoading(false);
        } else if (response.data.msg === "server error") {
          setError("An error occurred, please try again");
          setLoading(false);
        }
      } else {
        const userCopy = JSON.parse(JSON.stringify(user));
        userCopy.memberCodes.splice(codeIndex, 1);
        setUser(userCopy);
        setLoading(false);
        setUserUpdated(true);
      }
    } catch (err) {
      console.log(err.message);
      setError("An error occurred, please try again");
      setLoading(false);
    }
  };

  const upgradeUserToMember = async () => {
    setShowUpgradeModal(false);
    setLoading(true);
    try {
      const response = await axios.put("/api/users/upgrade", {
        userId: user._id,
      });
      if (!response.data.success) {
        setError("An error occurred, please try again");
      } else {
        const response = await axios.get(`/api/users/${email}`);
        if (!response.data.success) {
          setError("An error occurred, please try again");
          setUser();
          setLoading(false);
        } else if (response.data.success) {
          const user = response.data.user;
          if (response.data.memberCodes) {
            user.memberCodes = response.data.memberCodes;
          } else {
            user.memberCodes = [];
          }
          setUser(user);
          setError();
          setUserUpdated(true);
          setLoading(false);
        } else {
          setError("An error occurred, please try again");
          setUser();
          setLoading(false);
        }
      }
    } catch (err) {
      console.log(err.message);
      setError("An error occurred, please try again");
    }
    setLoading(false);
  };

  const handleAddCodeClick = () => {
    setShowAddCode(!showAddCode);
    setAddCodeError();
  };

  const upgradeUserClick = () => {
    setShowUpgradeModal(true);
  };

  const getCodeAvailability = () => {
    if (newCode) {
      if (
        usedCodes.includes(newCode.toLowerCase()) ||
        user.memberCodes.includes(newCode.toLowerCase())
      ) {
        return (
          <div className="get-code-availability-unavailable">Unavailable</div>
        );
      } else {
        return <div className="get-code-availability-available">Available</div>;
      }
    }
    return null;
  };

  const renderMemberCode = (code, codeIndex) => {
    return (
      <div className="user-display-code-render-container" key={codeIndex}>
        <div className="user-display-member-code">{code.code}</div>
        <ClearIcon
          style={{ color: "#999" }}
          onClick={() => removeMemberCodeClick(codeIndex)}
        />
      </div>
    );
  };

  return (
    <>
      {showRemoveModal && (
        <RemoveMemberCode
          show={showRemoveModal}
          setShow={setShowRemoveModal}
          codeIndex={codeIndex}
          user={user}
          remove={removeMemberCode}
        />
      )}
      {showUpgradeModal && (
        <UpgradeUserModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          user={user}
          upgrade={upgradeUserToMember}
        />
      )}
      <div className="admin-user-display-container">
        <div className="admin-user-heading-container">
          <div className="admin-user-heading-name">{user.name}</div>
          <div className="admin-user-heading-email">{user.email}</div>
          {userUpdated && <div className="admin-user-updated-message">User updated</div>}
        </div>
        <div className="admin-user-details-container">
          <div className="admin-user-details-text-field-container">
            <TextField
              variant="outlined"
              fullWidth={true}
              label="Name"
              defaultValue={user.name}
              color="secondary"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="admin-user-details-text-field-container">
            <TextField
              variant="outlined"
              fullWidth={true}
              label="Email"
              defaultValue={user.email}
              color="secondary"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="admin-user-details-account-container">
            <div>Account: {user.account}</div>
            {user.account === "free" && (
              <button
                className="admin-user-details-upgrade-button"
                onClick={upgradeUserClick}
              >
                Upgrade
              </button>
            )}
          </div>
          {user.account !== "free" && (
            <>
              <div style={{ marginBottom: "20px" }}>
                Member Id: {user.membershipId}
              </div>
              <div className="admin-user-member-codes-container">
                <div>Member Codes:</div>
                <div className="user-display-member-codes-container">
                  {user.memberCodes.length > 0 &&
                    user.memberCodes.map((code, codeIndex) =>
                      renderMemberCode(code, codeIndex)
                    )}
                  <div className="user-display-add-code-container">
                    <div
                      className="user-display-member-code"
                      style={{ color: "#00157f" }}
                    >
                      Add code
                    </div>
                    <AddIcon
                      style={{ color: "#00157f" }}
                      onClick={handleAddCodeClick}
                    />
                  </div>
                </div>
                {showAddCode && (
                  <>
                    <div className="user-display-add-code-container">
                      <input
                        type="text"
                        onChange={(e) => setNewCode(e.target.value.toLowerCase())}
                        value={newCode}
                      />
                      <button
                        className="user-display-add-code-confirm-button"
                        onClick={handleAddMemberCode}
                      >
                        Add Code
                      </button>
                    </div>
                    {getCodeAvailability()}
                    {addCodeError && (
                      <div className="add-member-code-error">
                        {addCodeError}
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
        {updateError && <div className="admin-update-error">{updateError}</div>}
        <div className="admin-user-button-container">
          <button className="admin-user-clear-user-button" onClick={clearUser}>
            Go Back
          </button>
          <button className="admin-user-update-button" onClick={updateUser}>
            Update
          </button>
        </div>
      </div>
    </>
  );
}
