import { useState, useContext } from "react";
import { DesktopLoginModalContext } from "../../../../Store";
import ModalTemplate from "../../../Universal/ModalTemplate";

export default function UserPromptModal({ setShowUserPrompt, selectFiles }) {
  const [, setShowLoginModal] = useContext(DesktopLoginModalContext);
  const [showInput, setShowInput] = useState(false);

  const handleLoginClick = () => {
    setShowUserPrompt(false);
    setShowLoginModal(true);
  };

  return (
    <ModalTemplate closeModal={() => setShowUserPrompt(false)} fixed={true}>
      <div className="modal-template-header-container">
        <div className="modal-template-heading">Sign up or sign in?</div>
      </div>
      <div className="modal-template-caption-container">
        <div className="modal-template-caption">
          Would you like help remembering your upload details?
        </div>
        <div className="modal-template-caption">
          Create an account or sign in to store and track your upload codes and
          codes you've viewed.
        </div>
      </div>
      <div className="modal-template-footer-container">
        <div className="desktop-continue-as-guest-file-input-container">
          <label htmlFor="desktop-file-input">
            <div
              className="modal-template-footer-button background-secondary"
              style={{ width: "220px" }}
              onClick={() => setShowInput(true)}
            >
              Continue as a guest
            </div>
            {showInput && (
              <input
                id="desktop-file-input"
                onChange={selectFiles}
                type="file"
                accept="image/*, video/*"
                multiple
              />
            )}
          </label>
        </div>
        <div
          className="modal-template-footer-button"
          style={{ width: "220px" }}
          onClick={handleLoginClick}
        >
          Sign up / Sign in
        </div>
      </div>
    </ModalTemplate>
  );
}
