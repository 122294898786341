import FileListHeader from "./FileListHeader";
import FileEntry from "./FileEntry";

export default function FilesList({ files, setFiles, error, setError }) {
  return (
    <>
      <FileListHeader
        files={files}
        setFiles={setFiles}
        error={error}
        setError={setError}
      />
      <div className="desktop-upload-file-list-container">
        {files.map((file, fileIndex) => (
          <FileEntry
            file={file}
            files={files}
            setFiles={setFiles}
            error={error}
            setError={setError}
            fileIndex={fileIndex}
            key={file.name}
          />
        ))}
      </div>
    </>
  );
}
