import { useState, useEffect, useContext } from "react";
import { RenderMediaContext } from "../../../../Store";
import axios from "axios";
import { BoxLoading } from "react-loadingg";

export default function MainCarouselImage({ item, authenticated }) {
  const [itemCopy, setItemCopy] = useState(item);
  const [renderMedia] = useContext(RenderMediaContext);

  useEffect(() => {
    const updateInterval = setInterval(async () => {
      if (!itemCopy.uploaded) {
        const response = await axios.get(
          `/api/codes/single-item/?code=${renderMedia.code}&fileName=${itemCopy.key}&authenticated=${authenticated}`
        );
        if (response.data.media.uploaded) {
          setItemCopy(response.data.media);
        }
      }
    }, 5000);
    return () => clearInterval(updateInterval);
  });

  if (!itemCopy.uploaded) {
    return (
      <div className="main-media-carousel-video-loading-container">
        <BoxLoading color="#00deb7" />
        <div className="main-media-carousel-video-loading-caption">
          This {item.mimetype === "image" ? "image" : "video "} is processing
          and will be available soon...
        </div>
      </div>
    );
  }

  return (
    <img className="desktop-single-media-image" src={itemCopy.url} alt="" />
  );
}
