import { useEffect, useState } from "react";
import AddMediaBox from "../AddMediaBox/AddMediaBox";
import AddMediaUserModal from "../AddMediaBox/AddMediaUserModal";
import GetMediaBox from "../GetMediaBox/GetMediaBox";
import LandingHeader from "../LandingHeader/LandingHeader";
import WhatWeDo from "../WhatWeDo/WhatWeDo";

export default function Landing() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="landing-container">
        {/* <LandingHeader /> */}
        <GetMediaBox />
        <AddMediaBox setShowModal={setShowModal} />
        {showModal && (
          <AddMediaUserModal
            showModal={showModal}
            setShowModal={setShowModal}
          />
        )}
        <WhatWeDo />
      </div>
    </>
  );
}
