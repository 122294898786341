import ProgressBar from "react-customizable-progressbar";

export default function UploadingFiles({ uploadPercentage }) {
  return (
    <>
      <ProgressBar
        progress={uploadPercentage}
        radius={60}
        strokeWidth={18}
        strokeColor="#00157f"
      >
        <div className="percentage-indicator">
          <div>{uploadPercentage}%</div>
        </div>
      </ProgressBar>
      <div className="files-uploading-caption">Uploading media...<br/>Please do not close this page</div>
    </>
  );
}
