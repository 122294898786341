import { useContext, useState, useEffect } from "react";
import { UserContext, LoadingUserContext } from "../../../Store";
import { useHistory } from "react-router-dom";
import AdminSearchBox from "./AdminSearchBox";
import AdminUserDisplay from "./AdminUserDisplay";
import axios from "axios";

export default function AdminPage() {
  const [user] = useContext(UserContext);
  const [loadingUser] = useContext(LoadingUserContext);
  const [usedCodes, setUsedCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [foundUser, setFoundUser] = useState();
  const [userUpdated, setUserUpdated] = useState(false);

  const history = useHistory();

  useEffect(() => {
    getMemberships();
  }, []);
  
  useEffect(() => {
    getMemberships();
  }, [userUpdated]);

  const getMemberships = async () => {
    try {
      const response = await axios.get("/api/admin/memberships/all");
      if (!response.data.success) {
        setError("An error occurred, please try again.");
        setLoading(false);
      } else {
        const memberCodes = [];
        response.data.members.forEach((membership) => {
          memberCodes.push(...membership.memberCodes);
        });
        setUsedCodes(memberCodes);
        setLoading(false);
      }
    } catch (err) {
      console.log(err.message);
      setError("An error occurred, please try again.");
    }
  };

  if (loadingUser) {
    return null;
  }

  if (!loadingUser && !user) {
    history.push("/");
  }

  if (loading) {
    return (
      <div className="new-password-page-loading-container">
        <div className="loading-component"></div>
      </div>
    );
  }

  if (user && user.account !== "admin") {
    history.push("/");
  }

  if (!foundUser) {
    return (
      <>
        <AdminSearchBox
          setFoundUser={setFoundUser}
          setError={setError}
          setLoading={setLoading}
          setUserUpdated={setUserUpdated}
        />
        {error && (
          <>
            <div className="admin-page-error">{error}</div>
          </>
        )}
      </>
    );
  }

  if (foundUser) {
    return (
      <>
        <AdminUserDisplay
          user={foundUser}
          setUser={setFoundUser}
          setError={setError}
          usedCodes={usedCodes}
          setLoading={setLoading}
          userUpdated={userUpdated}
          setUserUpdated={setUserUpdated}
        />
      </>
    );
  }

  return null;
}
