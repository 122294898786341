import { useContext } from 'react';
import { RenderMediaContext } from "../../../Store";

export default function FullScreenHiddenMedia({ index }) {
  const [renderMedia] = useContext(RenderMediaContext);
  const getHiddenIndexArray = () => {
    const returnArray = [];
    if (renderMedia.media.length <= 5) {
      for (let i = 0; i < renderMedia.media.length; i++) {
        if (renderMedia.media[i]) {
          returnArray.push(i);
        }
      }
    } else {
      if (index === 0) {
        return [5, 4, 3, 2, 1, 0];
      } else {
        for (let i = index - 2; i < index; i++) {
          if (renderMedia.media[i]) {
            returnArray.push(i);
          }
        }
        for (let i = index + 1; i < index + 4; i++) {
          if (renderMedia.media[i]) {
            returnArray.push(i);
          }
        }
        returnArray.push(index);
      }
    }
    return returnArray;
  };

  const HiddenMedia = ({ item }) => {
    if (item.mimetype === "image") {
      return (
        <img
          className="desktop-full-screen-hidden-media"
          src={item.url}
          alt=""
        />
      );
    } else if (item.mimetype === "video") {
      return (
        <video className="desktop-full-screen-hidden-media" src={item.url}>
          <source src={item.url} type="video/mp4" />
          <source src={item.url} type="video/ogg" />
          <source src={item.url} type="video/webm" />
          <source src={item.url} type="video/mov" />
          Your browser does not support the video tag.
        </video>
      );
    }
  };

  const indexArray = getHiddenIndexArray();

  return (
    <>
      {indexArray.map((itemIndex) => (
        <HiddenMedia item={renderMedia.media[itemIndex]} key={itemIndex} />
      ))}
    </>
  );
}
