import WWDComponent from "../WWDComponent/WWDComponent";
import cameraIcon from "../../../images/blueCameraIconWWDThin.png";
import padlockIcon from "../../../images/bluePadlockThin.png";
import uploadIcon from "../../../images/blueUploadArrowThin.png";
import codeIcon from "../../../images/blueSpannerThin.png";
import shareIcon from "../../../images/blueShareIconThin.png";

export default function WhatWeDo() {
  return (
    <>
      <div className="what-we-do-title">What we do...</div>
      <div className="what-we-do-container">
        <WWDComponent
          caption="Package your media for speedy access"
          img={cameraIcon}
        />
        <WWDComponent
          caption="Strip meta data to protect your identity"
          img={padlockIcon}
        />
        <WWDComponent
          caption="Upload your media to the cloud until expiry"
          img={uploadIcon}
        />
        <WWDComponent
          caption="Generate a unique access code to share your media with anyone"
          img={codeIcon}
        />
        <WWDComponent
          caption="Access your media at tkr.com/"
          img={shareIcon}
          last
        />
      </div>
    </>
  );
}
