export default function UserDashboardMyCodesHeader({ setSortBy, myUploads }) {
  return (
    <>
      <div className="user-dashboard-heading-sort-container">
        <div className="user-dashboard-name-heading">My codes:</div>
        <select
          className="user-dashboard-sort-by-input"
          onChange={(e) => setSortBy(e.target.value)}
        >
          <option value="code">Sort by: Code</option>
          <option value="expires">Sort by: Expiry</option>
          {myUploads && <option value="created">Sort by: Created</option>}
          <option value="viewCount">Sort by: Views</option>
          <option value="lastViewed">Sort by: Last viewed</option>
        </select>
      </div>
    </>
  );
}
