import Slider from "react-slick";
import arrowLeft from "../../../../images/chevronLeft.png";
import arrowRight from "../../../../images/chevronRight.png";
import ThumbnailImage from "./ThumbnailImage";

export default function ThumbnailCarousel({ media, setIndex, authenticated }) {
  const CarouselNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <img
        src={arrowRight}
        className={className}
        style={{ ...style, display: "block", width: "20px" }}
        onClick={onClick}
        alt="arrow right"
      />
    );
  };

  const CarouselPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <img
        src={arrowLeft}
        className={className}
        style={{ ...style, display: "block", width: "20px" }}
        onClick={onClick}
        alt="arrow left"
      />
    );
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 8,
    initialSlide: 0,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
  };

  return (
    <div className="desktop-render-thumbnail-carousel-container">
      <Slider {...settings}>
        {media.map((item, itemIndex) => (
          <div
            key={itemIndex}
            className="desktop-render-thumbnail-image-container"
          >
            <ThumbnailImage
              item={item}
              setIndex={setIndex}
              index={itemIndex}
              authenticated={authenticated}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}
