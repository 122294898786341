import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../../Store";
import FileDropzone from "./FileDropzone";
import FilesInput from "./FilesInput";
import Validate from "../../../../utils/validation";

export default function AddFiles({ setFiles, setError }) {
  const [user] = useContext(UserContext);

  const selectFiles = (event) => {
    const filesArray = Object.values(event.target.files);
    const validFilesArray = Validate.mediaFileUpload(filesArray);
    if (validFilesArray === "invalid file type") {
      setError(<div>Sorry... Only image and video files can be uploaded.</div>);
    } else {
      setFiles(filesArray);
      const validFilesSize = Validate.uploadSize(filesArray);
      if (!validFilesSize) {
        setError("A little too much... Free up some space (1 GB limit)");
      } else {
        setError();
      }
    }
  };

  const FilesDropZone = () => {
    return <FileDropzone setFiles={setFiles} />;
  };

  return (
    <>
      <div className="desktop-upload-files-box-header">
        <FilesInput selectFiles={selectFiles} />
        <FilesDropZone />
      </div>
    </>
  );
}
