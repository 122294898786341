import { createTheme } from "@mui/material/styles";

export const themeOptions = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#00157f",
    },
    secondary: {
      main: "#00deb7",
    },
  },
  MuiTextField: {
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        fontSize: '1rem',
        border: "5px solid red",
      },
    },
  },
});
