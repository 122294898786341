import { BrowserView, MobileView } from "react-device-detect";
import MobileBrowserRouter from "./Routers/MobileBrowserRouter";
import DesktopBrowserRouter from "./Routers/DesktopBrowserRouter";

export default function DeviceViews() {
  const screenWidth = window.screen.width;

  if (screenWidth <= 1100) {
    return <MobileBrowserRouter />;
  } else {
    return (
      <>
        <BrowserView>
          <DesktopBrowserRouter />
        </BrowserView>
        <MobileView>
          <MobileBrowserRouter />
        </MobileView>
      </>
    );
  }
}
