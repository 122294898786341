import { useContext } from "react";
import { UserContext, LoadingUserContext } from "../../../Store";
import { Link, useHistory } from "react-router-dom";

export default function ConfirmContactPage() {
  const [user] = useContext(UserContext);
  const [loadingUser] = useContext(LoadingUserContext);

  const history = useHistory();

  if (loadingUser) {
    return (
      <div className="user-account-loading-component-container">
        <div className="loading-component"></div>
      </div>
    );
  }

  if (!user) {
    history.push("/");
  }

  return (
    <div className="confirm-deleted-page-container">
      <div className=" delete-account-confirmation-caption ">
        Your enquiry has been sent. 
      </div>
      <div className="delete-account-confirmation-container">
        <Link
          to="/"
          className="delete-account-confirmation-nav-button"
          style={{ textDecoration: "none" }}
        >
          Go home
        </Link>
        <Link
          to="/users"
          className="delete-account-confirmation-nav-button"
          style={{ textDecoration: "none" }}
        >
          User dashboard
        </Link>
      </div>
    </div>
  );
}
