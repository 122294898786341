import { useContext, useState, useEffect } from "react";
import axios from "axios";
import { UserContext } from "../../../Store";
import UserDashboardMyCodes from "./UserDashboardMyCodes";
import UserDashboardMyCodesHeader from "./UserDashboardMyCodesHeader";

export default function UserDashboard() {
  const [, setUser] = useContext(UserContext);
  const [sortBy, setSortBy] = useState("created");
  const [myUploads, setMyUploads] = useState(true);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      const response = await axios.get(`/api/users/get-user`);
      if (response) {
        setUser(response.data.user);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <>
      <UserDashboardMyCodesHeader setSortBy={setSortBy} myUploads={myUploads} />
      <UserDashboardMyCodes
        sortBy={sortBy}
        myUploads={myUploads}
        setMyUploads={setMyUploads}
      />
    </>
  );
}
