import { useState, useContext } from "react";
import { UserContext } from "../../../../Store";
import { useHistory } from "react-router-dom";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import LoginError from "../../LoginModal/LoginError";

export default function DeleteAccount({ setShowDelete }) {
  const [user, setUser] = useContext(UserContext);
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleDeleteAccount = async () => {
    if (!password) {
      setError("Please enter your password to confirm.");
    } else {
      try {
        setLoading(true);
        const response = await axios.post("/api/users/delete-user", {
          email: user.email,
          password: password,
          userId: user.id,
        });
        if (
          !response.data.success &&
          response.data.msg === "password incorrect"
        ) {
          setError("Your password is incorrect. Please try again");
          setLoading(false);
        } else if (
          !response.data.success &&
          response.data.msg === "server error"
        ) {
          setError("Sorry, an error occurred. Please try again");
          setLoading(false);
        } else {
          setUser();
          history.push("/users/deletedAccount");
        }
      } catch (err) {
        setError("Sorry, an error occurred. Please try again");
        setLoading(false);
        console.log(err.message);
      }
    }
  };

  if (loading) {
    return (
      <div className="desktop-login-modal-loading-container">
        <div className="loading-component"></div>
        <div className="desktop-login-modal-loading-caption">Loading...</div>
      </div>
    );
  }

  return (
    <>
      <div className="desktop-dashboard-section-container">
        <div className="desktop-dashboard-section-heading-container">
          <div className="desktop-dashboard-section-heading">
            Delete your account?
          </div>
        </div>
      </div>
      <div className="desktop-delete-account-confirm-container">
        <div className="delete-upload-code-modal-caption">
          Are you sure you wish to delete your account? All your current uploads
          will be deleted. Enter your password to confirm. This cannot be
          undone.
          <TextField
            variant="outlined"
            fullWidth={true}
            label="Password"
            onChange={(e) => setPassword(e.target.value)}
            color="secondary"
            type={showPassword ? "text" : "password"}
            value={password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {showPassword ? (
                    <VisibilityOff
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <Visibility
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )}
                </InputAdornment>
              ),
            }}
            style={{ marginTop: "20px" }}
          />
        </div>
      </div>
      {error && <LoginError error={error} closeError={() => setError()} />}
      <div className="desktop-delete-account-confirm-button-container">
        <button
          className="desktop-delete-account-cancel-button"
          onClick={() => setShowDelete(false)}
        >
          Cancel
        </button>
        <button
          className="desktop-delete-account-delete-button"
          onClick={handleDeleteAccount}
        >
          Confirm Delete
        </button>
      </div>
    </>
  );
}
