import { useContext, useEffect } from "react";
import { UserContext, LoadingUserContext } from "../../../Store";
import { useHistory } from "react-router-dom";
import UserAreaHeader from "./UserAreaHeader";
import MemberDashboard from "../MemberDashboard/MemberDashboard";
import UserDashboard from "../UserDashboard/UserDashboard";
import MiniAppFooter from "../AppFooter/MiniAppFooter";

export default function UserArea() {
  const [user] = useContext(UserContext);
  const [loadingUser] = useContext(LoadingUserContext);

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!loadingUser && !user) {
      history.push("/users/login");
    }
  });

  if (loadingUser) {
    return (
      <>
        <div className="new-password-page-loading-container">
          <div className="loading-component"></div>
        </div>
      </>
    );
  }

  return (
    <div className="user-area-top-container">
      <div className="user-area-container">
        {!loadingUser && user && (
          <>
            <UserAreaHeader />
            {user.account !== "free" && <MemberDashboard />}
            <UserDashboard />
          </>
        )}
      </div>
      {/* <MiniAppFooter /> */}
    </div>
  );
}
