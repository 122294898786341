import { useContext } from "react";
import { useHistory } from "react-router-dom";
import blueGreenPlus from "../../../images/greenPlus.png";
import { UserContext } from "../../../Store";

export default function AddMediaBox({ setShowModal }) {
  const [user] = useContext(UserContext);
  const history = useHistory();

  const handleAddMediaClick = () => {
    if (!user) {
      setShowModal(true);
    } else {
      history.push("/upload");
    }
  };

  return (
    <>
      <div className="add-media-box-container">
        <div className="add-media-box-caption">Get a share code?</div>
        <div
          className="add-media-action-container"
          style={{ paddingTop: "10px" }}
        >
          <img
            className="add-media-icon"
            src={blueGreenPlus}
            onClick={handleAddMediaClick}
            alt=""
          />
          <div className="add-media-box-instruction">Add your media</div>
        </div>
      </div>
    </>
  );
}
