import { useContext, useState } from "react";
import { UserContext } from "../../../../Store";
import axios from "axios";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import LoginError from "../../LoginModal/LoginError";
import Validate from "../../../../utils/validation";
import PasswordValidationList from "../../../Universal/LoginComponents/PasswordValidationList";
import UserAccountConfirmation from "./UserAccountConfirmation";

export default function UserAccountForm({ setShowFooter }) {
  const [user, setUser] = useContext(UserContext);
  const [editName, setEditName] = useState(false);
  const [newName, setNewName] = useState();
  const [editEmail, setEditEmail] = useState(false);
  const [newEmail, setNewEmail] = useState();
  const [confirmEmail, setConfirmEmail] = useState();
  const [editPassword, setEditPassword] = useState(false);
  const [password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleEditNameClick = () => {
    setEditName(!editName);
    setEditEmail(false);
    setNewEmail();
    setEditPassword(false);
    setNewPassword();
    setError();
    setChanged();
    setShowFooter(false);
  };

  const handleEditEmailClick = () => {
    setEditEmail(!editEmail);
    setEditName(false);
    setNewName();
    setEditPassword(false);
    setNewPassword();
    setError();
    setChanged();
    setShowFooter(false);
  };

  const handleEditPasswordClick = () => {
    setEditPassword(!editPassword);
    setEditName(false);
    setNewName();
    setEditEmail(false);
    setNewEmail();
    setError();
    setChanged();
    setShowFooter(false);
  };

  const handleCancelNameChange = () => {
    setEditName(false);
    setNewName();
    setShowFooter(true);
  };

  const handleCancelEmailChange = () => {
    setEditEmail(false);
    setNewEmail();
    setShowFooter(true);
  };

  const handleCancelPasswordChange = () => {
    setEditPassword(false);
    setNewPassword();
    setShowFooter(true);
  };

  const updateName = async () => {
    if (!newName) {
      setError("Please enter a new name");
      setPassword();
    } else {
      setLoading(true);
      try {
        const response = await axios.put(`/api/users/update-name/${user.id}`, {
          name: newName,
        });
        if (!response.data.success) {
          setError("Sorry, an error occurred.  Please try again");
          setLoading(false);
          setPassword();
        } else {
          getUser("name");
        }
      } catch (err) {
        console.log(err.message);
        setError("Sorry, an error occurred.  Please try again");
        setLoading(false);
        setPassword();
        setShowFooter(true);
      }
    }
  };

  const getUser = async (property) => {
    try {
      const userResponse = await axios.get(`/api/users/get-user`);
      if (userResponse) {
        setUser(userResponse.data.user);
        setEditName(false);
        setEditEmail(false);
        setEditPassword(false);
        setNewName();
        setNewEmail();
        setPassword();
        setNewPassword();
        setConfirmPassword();
        setChanged(property);
        setShowFooter(true);
      }
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setError("Sorry, an error occurred.  Please try again");
      setLoading(false);
      setPassword();
      setNewPassword();
      setConfirmPassword();
      setShowFooter(true);
    }
  };

  const updateEmail = async () => {
    if (!newEmail) {
      setError("Please enter a new email address");
      setPassword();
    } else if (!Validate.loginEmail(newEmail)) {
      setError("Please enter a valid email address");
    } else if (!confirmEmail) {
      setError("Please confirm your email address");
      setPassword();
    } else if (confirmEmail !== newEmail) {
      setError("Email addresses do not match.  Please try again");
      setPassword();
    } else if (!password) {
      setError("Please enter your password to confirm");
    } else {
      setLoading(true);
      try {
        const response = await axios.post(`/api/users/update-email`, {
          email: user.email.toLowerCase(),
          newEmail: newEmail.toLowerCase(),
          password: password,
          id: user.id,
        });
        if (
          !response.data.success &&
          response.data.msg === "password incorrect"
        ) {
          setError("Sorry, your password is incorrect. Please try again");
          setLoading(false);
          setPassword();
        } else if (
          !response.data.success &&
          response.data.msg === "email already registered"
        ) {
          setError("Email address is already registered");
          setLoading(false);
          setPassword();
        } else {
          getUser("email");
        }
      } catch (err) {
        console.log(err);
        setError("An error occurred please try again");
        setLoading(false);
        setPassword();
        setShowFooter(true);
      }
    }
  };

  const updatePassword = async () => {
    if (!newPassword) {
      setError("Please enter a new password");
      setPassword();
    } else if (
      !Validate.passwordLength(newPassword) ||
      !Validate.passwordUppercase(newPassword) ||
      !Validate.passwordNumber(newPassword)
    ) {
      setError("Sorry... please choose a stronger password");
    } else if (!confirmPassword) {
      setError("Please confirm your new password");
      setPassword();
    } else if (confirmPassword !== newPassword) {
      setError("Passwords do not match.  Please try again");
      setPassword();
    } else if (!password) {
      setError("Please enter your current password to confirm");
      setPassword();
    } else {
      setLoading(true);
      try {
        const response = await axios.post(`/api/users/update-password`, {
          email: user.email,
          newPassword: newPassword,
          password: password,
          id: user.id,
        });
        if (
          !response.data.success &&
          response.data.msg === "password incorrect"
        ) {
          setError("Sorry, your password is incorrect. Please try again");
          setLoading(false);
          setPassword();
          setNewPassword();
          setConfirmPassword();
        } else if (
          !response.data.success &&
          response.data.msg === "server error"
        ) {
          setError("Sorry, there was an error.  Please try again");
          setLoading(false);
          setPassword();
          setNewPassword();
          setConfirmPassword();
        } else {
          getUser("password");
        }
      } catch (err) {
        console.log(err);
        setError("Sorry, there was an error.  Please try again");
        setLoading(false);
        setPassword();
        setNewPassword();
        setConfirmPassword();
        setShowFooter(true);
      }
    }
  };

  if (!user) {
    return null;
  }

  if (loading) {
    return (
      <div className="user-account-loading-component-container">
        <div className="loading-component"></div>
      </div>
    );
  }

  return (
    <>
      <div className="desktop-user-account-form-container">
        {changed && <UserAccountConfirmation property={changed} />}
        {!editEmail && !editPassword && (
          <>
            <div className="user-account-text-field-container">
              <TextField
                variant="outlined"
                fullWidth={true}
                label="Name"
                defaultValue={user.name}
                color="secondary"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <EditIcon sx={{ cursor: "pointer" }} />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  disabled: true,
                }}
                onClick={handleEditNameClick}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    "&:hover": {
                      cursor: "pointer",
                      border: "none",
                      outline: "none",
                    },
                  },
                }}
              />
            </div>
          </>
        )}
        {editName && (
          <>
            <div className="user-account-text-field-container">
              <TextField
                variant="outlined"
                fullWidth={true}
                label="New name"
                onChange={(e) => setNewName(e.target.value)}
                autoFocus={true}
                color="secondary"
              />
            </div>
            <div className="desktop-user-account-form-update-button-container">
              {error && (
                <div className="user-account-error-container">
                  <LoginError error={error} closeError={() => setError()} />
                </div>
              )}
              <button
                className="user-account-form-cancel-button"
                onClick={handleCancelNameChange}
              >
                Cancel
              </button>
              <button
                className="user-account-form-update-button"
                onClick={updateName}
              >
                Update name
              </button>
            </div>
          </>
        )}
        {!editName && !editPassword && (
          <>
            <div className="user-account-text-field-container">
              <TextField
                fullWidth={true}
                variant="outlined"
                label="Email"
                value={user.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <EditIcon style={{ cursor: "pointer" }} />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  disabled: true,
                }}
                onClick={handleEditEmailClick}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    "&:hover": {
                      cursor: "pointer",
                      border: "none",
                      outline: "none",
                    },
                  },
                }}
              />
            </div>
          </>
        )}
        {editEmail && (
          <>
            <div className="user-account-text-field-container">
              <TextField
                variant="outlined"
                fullWidth={true}
                label="New email"
                onChange={(e) => setNewEmail(e.target.value.toLowerCase())}
                autoFocus={true}
                color="secondary"
              />
            </div>
            <div className="user-account-text-field-container">
              <TextField
                variant="outlined"
                fullWidth={true}
                label="Confirm new email"
                onChange={(e) => setConfirmEmail(e.target.value.toLowerCase())}
                color="secondary"
              />
            </div>
            <div className="user-account-text-field-container">
              <TextField
                variant="outlined"
                fullWidth={true}
                label="Password"
                onChange={(e) => setPassword(e.target.value)}
                color="secondary"
                type={showPassword ? "text" : "password"}
                value={password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {showPassword ? (
                        <VisibilityOff
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <Visibility
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="desktop-user-account-form-update-button-container">
              {error && (
                <div className="user-account-error-container">
                  <LoginError error={error} closeError={() => setError()} />
                </div>
              )}
              <button
                className="user-account-form-cancel-button"
                onClick={handleCancelEmailChange}
              >
                Cancel
              </button>
              <button
                className="user-account-form-update-button"
                onClick={updateEmail}
              >
                Update email
              </button>
            </div>
          </>
        )}
        {!editName && !editEmail && (
          <>
            <div className="user-account-text-field-container">
              <TextField
                fullWidth={true}
                variant="outlined"
                label="Password"
                value="1234567812345678"
                type="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <EditIcon style={{ cursor: "pointer" }} />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  disabled: true,
                }}
                onClick={handleEditPasswordClick}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    "&:hover": {
                      cursor: "pointer",
                      border: "none",
                      outline: "none",
                    },
                  },
                }}
              />
            </div>
          </>
        )}
        {editPassword && (
          <>
            <div className="user-account-text-field-container">
              <TextField
                variant="outlined"
                fullWidth={true}
                label="Current password"
                onChange={(e) => setPassword(e.target.value)}
                autoFocus={true}
                color="secondary"
                type={showPassword ? "text" : "password"}
                value={password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {showPassword ? (
                        <VisibilityOff
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <Visibility
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="user-account-text-field-container">
              <TextField
                variant="outlined"
                fullWidth={true}
                label="New password"
                onChange={(e) => setNewPassword(e.target.value)}
                color="secondary"
                type={showNewPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {showNewPassword ? (
                        <VisibilityOff
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <Visibility
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <PasswordValidationList password={newPassword} active />
            <div className="user-account-text-field-container">
              <TextField
                variant="outlined"
                fullWidth={true}
                label="Confirm new password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                color="secondary"
                type={showNewPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {showNewPassword ? (
                        <VisibilityOff
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <Visibility
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="desktop-user-account-form-update-button-container">
              {error && (
                <div className="user-account-error-container">
                  <LoginError error={error} closeError={() => setError()} />
                </div>
              )}
              <button
                className="user-account-form-cancel-button"
                onClick={handleCancelPasswordChange}
              >
                Cancel
              </button>
              <button
                className="user-account-form-update-button"
                onClick={updatePassword}
              >
                Update password
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
