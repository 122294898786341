import { useEffect } from "react";

export default function AboutUsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="info-page-container">
        <div className="about-us-heading">Our Mission</div>
        <div className="about-us-paragraph">
          To help people share and view media whilst protecting their personal
          data.
        </div>
        <div className="about-us-heading">About Us</div>
        <div className="about-us-paragraph" style={{ width: "100%" }}>
          tkr.com was founded in 2022 to offer users a unique, versatile and
          simple way to share media without depending on existing social network
          channels and traditional email.
        </div>
        <div className="about-us-paragraph" style={{ width: "100%" }}>
          As more and more of our life moves online, we are increasingly
          creating, sharing and consuming media, some of which we may not wish
          to see. It’s not a perfect process and often involves us parting with
          more personal data than we might like.
        </div>
        <div className="about-us-paragraph" style={{ width: "100%" }}>
          When we first discussed improving online media sharing at tkr, we
          tried to answer two simple questions:
        </div>
        <ul className="about-us-list">
          <li className="about-us-list-item" style={{ width: "100%" }}>
            How can you share media with someone without exchanging personal
            details or becoming part of the same network?
          </li>
          <li className="about-us-list-item" style={{ width: "100%" }}>
            How do organisations reach people they don’t have details for and
            who would be reluctant to visit their current media channels?
          </li>
        </ul>
        <div className="about-us-paragraph">
          At tkr, we passionately believe that by using individual media codes,
          we have created a simple and convenient solution to those two
          challenges. Not only that, we’ve also thought of many other scenarios
          where tkr would be useful in business and everyday life.
        </div>
        <div className="about-us-paragraph">
          In the future, we want to continue to innovate ways to improve
          privacy, convenience and potential reach for our users. We’d also love
          to help make the internet a safe place for all. If you have any ideas
          about how we can improve our service, please get in touch at
          enquiries@tkr.com!
        </div>
      </div>
    </>
  );
}
