import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../Store";
import { MdClose } from "react-icons/md";
import axios from "axios";
import MemberCodes from "../../Universal/DashboardComponents/MemberCodesDisplay/MemberCodes";
import MyCodes from "../../Universal/DashboardComponents/MyCodesDisplay/MyCodes";
import AccountDetails from "./AccountDetails/AccountDetails";

export default function UserDashboard({ setShowDashboard }) {
  const [showAccountDetails, setShowAccountDetails] = useState(false);
  const [user, setUser] = useContext(UserContext);
  
  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      const response = await axios.get(`/api/users/get-user`);
      if (response) {
        setUser(response.data.user);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleLogOut = async () => {
    const response = await axios.get("/api/users/logout");
    if (response.data === "logged out") {
      setUser();
    }
  };

  const Header = () => {
    return (
      <div className="desktop-dashboard-header-container">
        <div>
          <div className="desktop-dashboard-name-heading">
            Hi{` ${user.name}`}!
          </div>
          <div
            className="desktop-dashboard-account-details-link"
            onClick={() => {
              setShowAccountDetails(true);
            }}
          >
            Account details
          </div>
        </div>
        <button
          className="desktop-dashboard-logout-button"
          onClick={handleLogOut}
        >
          Log out
        </button>
      </div>
    );
  };

  const DashboardNavPanel = () => {
    return (
      <div className="desktop-user-dashboard-nav-panel-container">
        <div className="desktop-dashboard-nav-panel">
          <div
            className="desktop-dashboard-nav-item"
            onClick={() => setShowDashboard(false)}
          >
            Close
          </div>
          <MdClose
            className="desktop-dashboard-close-icon"
            onClick={() => setShowDashboard(false)}
          />
        </div>
      </div>
    );
  };

  const windowHeight = window.innerHeight;
  const pageHeight = document.documentElement.scrollHeight;

  const getContainerClassName = () => {
    if (pageHeight - windowHeight > 0) {
      return "desktop-user-dashboard-container desktop-user-dashboard-container-extra-height"
    } else {
      return "desktop-user-dashboard-container"
    }
  }

  return (
    <>
      {showAccountDetails && (
        <AccountDetails setShowModal={setShowAccountDetails} />
      )}
      <div className={getContainerClassName()}>
        <DashboardNavPanel />
        <div className="desktop-dashboard-content-container">
          <Header />
          {user.account !== "free" && (
            <MemberCodes setShowDashboard={setShowDashboard} />
          )}
          <MyCodes setShowDashboard={setShowDashboard} />
        </div>
      </div>
    </>
  );
}
