import errorIcon from "../../../images/errorIcon2.png";

export default function RenderErrorSmall({ error }) {
  return (
    <>
      <div className="mobile-error-mini-container">
        <div className="mobile-error-left-strip"></div>
        <img className="mobile-error-icon" src={errorIcon} />
        <div className="mobile-error-mini-message">{error}</div>
      </div>
    </>
  );
}