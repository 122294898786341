import AddAndClearButtons from "./AddAndClearButtons";
import UploadDetails from "./UploadDetails";
import FileEntry from "./FileEntry";

export default function FilesDisplay(props) {
  const { files, clearAllFiles, addMoreFiles, removeFile } = props;
  return (
    <>
      <div className="files-display-container">
        <UploadDetails files={files} />
        <AddAndClearButtons
          addMoreFiles={addMoreFiles}
          clearAllFiles={clearAllFiles}
        />
        <div className="files-display-file-entry-container">
          {files.map((file, fileIndex) => (
            <FileEntry
              file={file}
              fileIndex={fileIndex}
              removeFile={removeFile}
              key={file.name}
            />
          ))}
        </div>
      </div>
    </>
  );
}
