import { useEffect } from "react";

export default function ContactUsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="desktop-info-page-background">
      <div className="desktop-info-page-container">
        <div className="desktop-about-us-heading">Contact Us</div>
        <div className="desktop-about-us-paragraph desktop-about-us-mission">
          For any enquiries, please email enquiries@tkr.com.
        </div>
      </div>
    </div>
  );
}
