import fontLogo from "../../../images/fontLogo2.png";
import FooterCopyright from "./FooterCopyright";

export default function MiniAppFooter() {

  return (
    <>
      <div className="mini-app-footer-container">
        <img className="mini-app-footer-logo" src={fontLogo} alt="" />
        <FooterCopyright />
      </div>
    </>
  );
}
