import { useEffect, useState, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { UserContext, LoadingUserContext } from "../../../Store";

export default function ActivateAccount() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [user, setUser] = useContext(UserContext);
  const [loadingUser] = useContext(LoadingUserContext);
  const [showResendButton, setShowResendButton] = useState(false);

  const { token } = useParams();

  useEffect(() => {
    if (!loadingUser) {
      activateEmail();
    }
  }, [loadingUser]);

  const activateEmail = async () => {
    try {
      const response = await axios.post("/api/users/email-activation", {
        token: token,
      });
      if (response.data.success) {
        setUser(response.data.user);
      } else {
        if (response.data.msg === "jwt expired") {
          setError(
            "The activation link has expired. Please try to register again."
          );
          setShowResendButton(true);
        } else if (response.data.msg === "user exists") {
          setError(
            "The user account is already activated. Please login to continue."
          );
        } else {
          setError("Sorry, an error occurred. Please try again");
          setShowResendButton(true);
        }
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err.message);
      setError("Sorry, an error occurred. Please try again");
      setIsLoading(false);
    }
  };

  const resendActivationEmail = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post("/api/users/resend-activation-email", {
        token: token,
      });
      if (response.data.success) {
        setError(`A new activation email has been sent to ${response.data.email}. The new activation
        link will expire in twenty minutes.`);
        setShowResendButton(false);
      } else {
        setError("Sorry, an error occurred. Please try registering again");
        setShowResendButton(false);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err.message);
      setError("Sorry, an error occurred. Please try again");
      setIsLoading(false);
    }
  };

  if (isLoading || loadingUser) {
    return (
      <div className="desktop-activate-account-container">
        <div className="render-media-loading-container">
          <div className="new-password-page-loading-container">
            <div className="loading-component"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="desktop-activate-account-container">
        <div className="activate-account-caption-container">
          <div>{error}</div>
        </div>
        <div className="activate-account-button-container">
          {showResendButton && (
            <button
              className="activate-account-resend-button"
              onClick={resendActivationEmail}
            >
              Resend activation email
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="desktop-activate-account-container">
      <div className="activate-account-caption-container">
        <div>
          You have successfully registered and logged in with email {user.email}{" "}
          <br />
          <br />
          You can click on the 'my dashboard' button at the top right of this page to access
          your user area.
          <br />
          <br />
          Thank you for registering with tkr.com.
        </div>
      </div>
      <div className="desktop-activate-account-button-container">
        <Link to="/">
          <button className="desktop-activate-account-home-button">
            Homepage
          </button>
        </Link>
      </div>
    </div>
  );
}
