import { useState, useContext } from "react";
import {
  UserContext,
  DesktopRegisterCodeContext,
  DesktopLoginModalContext,
  DesktopFromRegisterContext,
  DesktopDashboardContext,
} from "../../../../Store";

export default function UserMessage({ code }) {
  const [user] = useContext(UserContext);
  const [, setRegisterCode] = useContext(DesktopRegisterCodeContext);
  const [, setShowLoginModal] = useContext(DesktopLoginModalContext);
  const [, setShowDashboard] = useContext(DesktopDashboardContext);
  const [, setFromRegister] = useContext(DesktopFromRegisterContext);

  const handleSignUpClick = () => {
    setRegisterCode(code);
    setShowLoginModal(true);
    setFromRegister(true);
  };

  const handleDashboardClick = () => {
    setShowDashboard(true);
  };

  const LoggedInMessage = () => {
    return (
      <>
        <div className="desktop-code-user-message-container">
          Manage and track uploads on your
          <br />
          <span
            className="desktop-code-message-action-link"
            onClick={handleDashboardClick}
          >
            user dashboard
          </span>
        </div>
      </>
    );
  };

  const SignUpMessage = () => {
    return (
      <>
        <div className="desktop-code-user-message-container">
          ✦ Need somewhere to store and track your code?{" "}
          <span
            className="desktop-code-message-action-link"
            onClick={handleSignUpClick}
          >
            Sign up here{" "}
          </span>
          ✦
        </div>
      </>
    );
  };

  return (
    <>
      {user && <LoggedInMessage />}
      {!user && <SignUpMessage />}
    </>
  );
}
