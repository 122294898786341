import { useContext, useState } from "react";
import { UserContext } from "../../../Store";
import { Modal, Box } from "@mui/material";
import logo from "../../../images/logo.png";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { useHistory } from "react-router-dom";
import RenderErrorSmall from "../ErrorMessages/RenderErrorSmall";
import BecomeMember from "./BecomeMember";
import ContactUsModal from "../ContactUsModal/ContactUsModal";

export default function UserAccountDelete() {
  const [user, setUser] = useContext(UserContext);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState();
  const [showBecomeMember, setShowBecomeMember] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "260px",
    width: "90%",
    maxWidth: "600px",
    bgcolor: "background.paper",
    border: "1px solid lightgrey",
    borderRadius: "4px",
    boxShadow: 24,
    p: 2,
  };

  const handleDeleteAccount = async () => {
    if (!password) {
      setError("Please enter your password to confirm.");
    } else {
      try {
        setLoading(true);
        const response = await axios.post("/api/users/delete-user", {
          email: user.email,
          password: password,
          userId: user.id,
        });
        if (
          !response.data.success &&
          response.data.msg === "password incorrect"
        ) {
          setError("Your password is incorrect. Please try again");
          setLoading(false);
        } else if (
          !response.data.success &&
          response.data.msg === "server error"
        ) {
          setError("Sorry, an error occurred. Please try again");
          setLoading(false);
        } else {
          setUser();
          history.push("/users/deletedAccount");
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const handleDeleteAccountCancel = () => {
    setShowConfirmDelete(false);
    setPassword();
    setShowPassword(false);
    setError();
    setLoading(false);
  };

  const handleBecomeMemberClick = () => {
    if (user.account !== "free") {
      setShowContactUs(true);
    } else {
      setShowBecomeMember(true);
    }
  };

  const renderConfirmDeleteModal = () => {
    return (
      <>
        <Modal
          open={showConfirmDelete}
          onClose={handleDeleteAccountCancel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="delete-modal-header-container">
              <img
                className="overwrite-modal-header-logo"
                src={logo}
                alt="logo"
              />
            </div>
            <div className="overwrite-upload-code-modal-title">
              Delete your account?
            </div>
            <div className="delete-upload-code-modal-caption">
              Are you sure you wish to delete your account? All your current
              uploads will be deleted. Enter your password to confirm. This
              cannot be undone.
              <TextField
                variant="outlined"
                fullWidth={true}
                label="Password"
                onChange={(e) => setPassword(e.target.value)}
                color="secondary"
                type={showPassword ? "text" : "password"}
                value={password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {showPassword ? (
                        <VisibilityOff
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      ) : (
                        <Visibility
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                style={{ marginTop: "20px" }}
              />
            </div>
            {loading && (
              <div className="delete-account-loading-container">
                <div className="loading-component"></div>
              </div>
            )}
            {error && !loading && (
              <div className="user-account-delete-error-container">
                <RenderErrorSmall error={error} />
              </div>
            )}
            <div className="overwrite-upload-code-modal-footer">
              {!loading && (
                <>
                  <button
                    className="user-account-delete-cancel-button"
                    onClick={handleDeleteAccountCancel}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleDeleteAccount}
                    className="user-account-confirm-delete-button"
                  >
                    Confirm Delete
                  </button>
                </>
              )}
            </div>
          </Box>
        </Modal>
      </>
    );
  };

  return (
    <>
      {renderConfirmDeleteModal()}
      {showBecomeMember && (
        <BecomeMember
          showBecomeMember={showBecomeMember}
          setShowBecomeMember={setShowBecomeMember}
        />
      )}
      {showContactUs && (
        <ContactUsModal
          showContactUs={showContactUs}
          setShowContactUs={setShowContactUs}
        />
      )}
      <div className="user-account-delete-container">
        <div
          className="user-account-become-member-button"
          onClick={handleBecomeMemberClick}
        >
          {user.account !== "free" ? "Contact us" : "Become a member"}
        </div>
        <div
          className="user-account-delete-button"
          onClick={() => setShowConfirmDelete(true)}
        >
          Delete my account
        </div>
      </div>
    </>
  );
}
