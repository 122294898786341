import { useState } from "react";
import { Link, useHistory } from "react-router-dom";

export default function GetMediaBox() {
  const [code, setCode] = useState();

  const history = useHistory();

  const handleSubmit = () => {
    history.push(`/${code}`);
  };

  return (
    <>
      <div className="get-media-box-container">
        <div className="get-media-box-caption">
          The easiest way to share your media with anyone
        </div>
        <div className="get-media-box-sub-caption">Got a code?</div>
        <form className="get-media-form-container" onSubmit={handleSubmit}>
          <input
            className="get-media-input-box"
            type="text"
            placeholder="Enter your code"
            required
            onChange={(e) => setCode(e.target.value.toLowerCase())}
          ></input>
        </form>
        <Link to={`/${code}`}>
          <button
            className={
              code
                ? "get-media-box-get-button get-media-box-get-button-active"
                : "get-media-box-get-button"
            }
            type="submit"
            disabled={code ? false : true}
          >
            Get media
          </button>
        </Link>
      </div>
    </>
  );
}
